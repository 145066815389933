import React, { Component } from 'react';
import ConfirmationModal from "../../globals/uicomps/ConfirmationModal"


export default class CaseProofList extends Component {
     constructor(props) {
          super(props);
          this.state = {
               showConfirmationModal: false
          }
          this.docName = ""
     }

     removeFileHandler = () => {
          this.setState({
               showConfirmationModal: false
          })
          this.props.removeDocument(this.docName, this.docPath, this.docType)
     }

     removeConfirmationModal = (docName, docPath, docType) => {
          this.docName = docName;
          this.docPath = docPath;
          this.docType = docType;
          this.setState({
               showConfirmationModal: true
          })
     }


     closeModal = () => {
          this.setState({
               showConfirmationModal: false
          })
     }


     render = () => {
          let { removeFileOption, docType, hideBg } = this.props;
          let previewList = this.props.documentList.map(fileObj => {
               let docLink = "", docName = "";
               if (fileObj.evidence !== undefined) {
                    docLink = fileObj.evidence
                    docName = fileObj.fileName
               } else {
                    docLink = fileObj.docLink
                    docName = fileObj.docName
               }

               if ((fileObj.docType === "pdf") || (fileObj.docType !== undefined ? fileObj.docType.toLowerCase() === "document" : null || docName.endsWith('.pdf'))) {
                    return (
                         <React.Fragment key={new Date().getTime() + '-' + Math.random()}>
                              <div className="col-sm-3 col-lg-2 mt10 mb10" key={fileObj.id} >
                                   <div className="proof-doc">
                                        <a href={docLink} download className="block" >
                                             <div className="upload-file pointer mb0" style={{ height: '145px' }}>
                                                  <img src={require("../../images/component-icons/component-pdf.svg")} alt="user" ></img>
                                                  <label>{docName}</label>
                                             </div>
                                             <div className="clr"></div>
                                        </a>
                                        <div className="overlay-card">
                                             <div className="btngroup">
                                                  <a className="btn btn_primary mb10" href={docLink} title="Download  Document" >Download</a>
                                                  {(removeFileOption) ?
                                                       <span className="btn btn_primary" title="Remove Document" onClick={() => this.removeConfirmationModal(docName, fileObj.docPath, docType)} >Remove</span>
                                                       : null}
                                             </div>
                                             <div className="clr"></div>
                                        </div>
                                        <div className="clr"></div>
                                   </div>
                                   <div className="clr"></div>
                              </div>
                         </React.Fragment>
                    )
               } else {
                    return (
                         <React.Fragment key={new Date().getTime() + '-' + Math.random()}>
                              <div className="col-md-3 col-lg-2 col-12 mt10 mb10">
                                   <div className="proof-doc">
                                        <a href={docLink} download className="block" >
                                             <div className="img-wrapper pointer">
                                                  <img src={docLink} alt="images" style={{ display: 'inherit' }} />
                                             </div>
                                             <div className="clr"></div>
                                        </a>
                                        <div className="overlay-card">
                                             <div className="btngroup">
                                                  <a className="btn btn_primary mb10" href={docLink} title="Download  Document" >Download</a>
                                                  {removeFileOption ?
                                                       <span className="btn btn_primary" title="Remove  Document" onClick={() => this.removeConfirmationModal(docName, fileObj.docPath, docType)} >Remove</span>
                                                       : null}
                                             </div>
                                             <div className="clr"></div>
                                        </div>
                                        <div className="clr"></div>
                                   </div>
                                   <div className="clr"></div>
                              </div>
                         </React.Fragment>
                    );
               }
          })
          return (
               <React.Fragment>
                    {this.props.documentList && this.props.documentList.length ?
                         <React.Fragment>
                              {
                                   this.props.hideHeader === undefined ?
                                        <div className="sub-heading1 sub_heading_mar">
                                             {this.props.headerName === undefined ? "Source Documents" : this.props.headerName}
                                        </div>
                                        : null}

                              <div className={`${hideBg ? '' : 'white-bg'}`}>
                                   <div className="row">
                                        {previewList}
                                   </div>
                              </div>
                         </React.Fragment>
                         : null}
                    {
                         <ConfirmationModal
                              show={this.state.showConfirmationModal}
                              closeCallback={this.closeModal}
                              heading="Are you sure ?"
                              subheading="Do you want to remove this document"
                              content="Do you want to recheck ? "
                              successButtonText="Remove"
                              failureButtonText="Cancel"
                              fadeBg={true}
                              successCallback={this.removeFileHandler}
                              failureCallback={this.closeModal}
                         />
                    }
               </React.Fragment>
          )

     }


}
