import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';


class ConfirmationModal extends Component {

     successHandler = () => {
          if (this.props.successCallback) {
               this.props.successCallback();
          }
     }
     failureHandler = () => {
          if (this.props.failureCallback) {
               this.props.failureCallback();
          }
     }

     render = () => {
          return (
               <Modal className={`modal fade yes-or-no-modal ${this.props.fadeBg !== undefined ? "modal_black_bg" : ""} `} id="yes-or-no-modal" show={this.props.show} onHide={() => { this.props.closeCallback() }} >
                    <div className="close" onClick={this.hide} aria-label="Close">
                         <span className="bt-icon bt-close pointer" onClick={() => { this.props.closeCallback() }} ></span>
                    </div>
                    <Modal.Body className={`${this.props.className}`}>
                         <div className="bt-modal-content">
                              <h6>{this.props.heading ? this.props.heading : 'Are you Sure ?'}</h6>
                              <div className="sub-heading2">{this.props.subheading ? this.props.subheading : ''}</div>
                              <div className="clr15"></div>
                              <div className="body2">{this.props.children}</div>
                         </div>
                    </Modal.Body>
                    <Modal.Footer className={`text-center ${this.props.hideFooter ? 'hide_content' : ""}`}>
                         < button type="button" className="btn btn_secondary" onClick={this.failureHandler} >{this.props.failureButtonText}</button>
                         <button type="button" className="btn btn_primary" onClick={this.successHandler} disabled={this.props.disabled} >{this.props.successButtonText}</button>
                    </Modal.Footer>
               </Modal >
          );
     }
}

export default ConfirmationModal;

/**
 * Usage:
 * <ConfirmationModal
 * show={this.state.showCaseDuplicateConfirmation}
 * heading="Are you sure ?"
 * subheading="Do you want to confirm"
 * successButtonText="Recheck"
 * failureButtonText="Cancel"
 * successCallback={callbackfunction}
 * failureCallback={callbackfunction}
 * closeCallback={callbackfunction}
 * >Do you want to recheck ? </ConfirmationModal>
 */
