import React, { Component } from 'react';
import ReportViewerHeader from '../ReportViewerHeader';
import { getValue } from '../../globals/utils';
import Casereportdetails from '../../clientAdmin/cases/caseDashboard/Casereportdetails';
import moment from 'moment';

class ReportViewerCases extends Component {
     constructor(props) {
          super(props);
          this.startDate = moment().subtract(365, 'days').toDate();
          this.endDate = new Date();
          this.clientId = getValue('clientId');
     }

     render = () => {
          let navList = [
               {
                    name: 'Clients',
                    route: 'clientList'
               },
               {
                    name: 'Cases',
                    route: 'clientCases'
               },
               // {
               //      name: 'Case Management',
               //      route: 'caseManagement'
               // },
               {
                    name: 'Management',
                    route: 'management'
               }
          ]
          return (
               <React.Fragment>
                    <ReportViewerHeader navList={navList} showNavTabs activeTab="Cases" cbChangeBaseTab={this.props.cbChangeBaseTab} />
                    <div className="case_initiation_accordion adminlayout">
                         <Casereportdetails caseFilters={{}} startDate={this.startDate} endDate={this.endDate} reportViewerView={true} />
                    </div>
               </React.Fragment>
          );
     }
}

export default ReportViewerCases;
