import  { Component } from 'react';


class BottomAlert extends Component {

     componentDidMount =() =>{
          let bottomAlert = document.getElementById("bottom_alert");
          console.log("bottomAlert",bottomAlert)
          let status =this.props.status?this.props.status:"default"
          bottomAlert.classList.add(("alert_"+status),"show");
          document.querySelector(".bottom_alert .msg").innerHTML = this.props.msg?this.props.msg:'Alert';
          document.querySelector(".bottom_alert .close_bottom_alert").addEventListener("click", function(){
               bottomAlert.classList.remove("show");
          });
          setTimeout(() => {
               bottomAlert.classList.remove("show");
               if(!!this.props.onHide){
                    this.props.onHide();
               }
          }, this.props.time ? this.props.time : 5000 );

     }
     render = () => {
          return (null);
     }
}

export default BottomAlert;


// Usage:
// <BottomAlert msg="Alert" status="danger" />
// <BottomAlert msg="Alert" status="success" />
// <BottomAlert msg="Alert" status="danger" />