import React, { Component } from 'react'

class CaseVerification extends Component {

     verificationList = (verificationList) => {
          let { data, statusCode, verificationStatus } = this.props;
          let verificationStatusContainer = [<li key={data + verificationStatus}>
               <h6 className="sub-heading2 inlinedisplay">{data}</h6>
               <div className={statusCode + " sub-heading2 right "}>{verificationStatus}</div>
               <div className='clr5'></div>
               <div className="border-div"></div>
               <div className='clr'></div>
          </li>];
          let verificationLists = verificationList.map((data, index) =>
               <li key={index}>
                    <h6 className="body2">{data.verificationName}</h6>
                    <div className={data.verificationStatusCode + " body2 right "}>{data.verificationStatus}</div>
                    <div className='clr5'></div>
               </li>
          )
          verificationStatusContainer = verificationStatusContainer.concat(verificationLists)
          return verificationStatusContainer
     }


     render() {
          let returnData
          let data = this.props.data

          // let titleText = "";
          if (data === "Identity") {
               returnData = <div className={this.props.statusCode + " id icon-status"}></div>
               // titleText = "Identity Check";
          }
          else if (data === "Education") {
               returnData = <div className={this.props.statusCode + " education icon-status"}></div>
               // titleText = "Education Check";
          }
          if (data === "Credit") {
               returnData = <div className={this.props.statusCode + " credit icon-status"}></div>
               // titleText = "Credit Check";
          }
          if (data === "Court") {
               returnData = <div className={this.props.statusCode + " court icon-status"}></div>
               // titleText = "Court Check";
          }
          if (data === "Employment") {
               returnData = <div className={this.props.statusCode + " employment icon-status"}></div>
               // titleText = "Employment Check";
          }
          if (data === "Drug") {
               returnData = <div className={this.props.statusCode + " drug icon-status"}></div>
               // titleText = "Drug Check"
          }
          if (data === "Social Media") {
               returnData = <div className={this.props.statusCode + " social icon-status"}></div>
               // titleText = "Social Check"
          }
          if (data === "Residence") {
               returnData = <div className={this.props.statusCode + " residence icon-status"}></div>
               // titleText = "Residence Check"

          }
          if (data === "Reference") {
               returnData = <div className={this.props.statusCode + " reference icon-status"}></div>
               // titleText = "Reference Check"
          }
          if (data === "Database") {
               returnData = <div className={this.props.statusCode + " database icon-status"}></div>
               // titleText = "Database Check"

          }
          if(data === "Doctor"){
               returnData = <div className={this.props.statusCode + " doctor icon-status"}></div>
          }
          if (data === "CV" || data === "cv" || data === "Cv") {
               returnData = <div className={this.props.statusCode + " cv icon-status"}></div>
               // titleText = "CV Check"
          }
          if(data === "Directorship") {
               returnData = <div className={this.props.statusCode + " directorship icon-status"}></div>
          }
          let { verificationList } = this.props;
          return (
               <div className="title-hover show_verify_data">
                    {returnData}
                    {/* <div className="sub-heading1">{titleText}</div>
                         <div className="body2">{this.props.statusCode}</div> */}
                    {verificationList.length > 0 ?
                         <div className="link-div">
                              <ul>
                                   {
                                        this.verificationList(verificationList)
                                   }
                              </ul>
                         </div> : null}
                    <div className="clr"></div>
               </div>
          )
     }
}

export default CaseVerification
