import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { LoadingSpinner } from '../../utils/LoadingSpinner';




export class PhotoAuthenticator extends Component {
     constructor(props) {
          super(props);
          this.state = {

          };

     }


     render = () => {
          const { t } = this.props;

          return (
               <React.Fragment>
                    {
                         this.state.loading === true ? <LoadingSpinner /> :
                              <React.Fragment>

                                   <div className="otp-set">

                                        <div className="sub-heading1 text-center">Done !</div>
                                        <div className="clr20"></div>
                                        <img src={"/images/Image8.png"} alt="user-face" />

                                        <div className="clr20"></div>

                                        <input className="btn btn_primary block mt10" type="submit"
                                             value="Continue" ></input>

                                        <div className="forgetlink body2 left mt10 pointer" onClick={() => { this.props.changeStepValue("login") }}>{t('Back to Login')}</div>
                                        <div className="forgetlink body2 right mt10 pointer" onClick={() => { this.props.changeStepValue("tryAnotherWays") }}>{t('Try Other Ways')}</div>

                                   </div>

                              </React.Fragment>
                    }


               </React.Fragment>
          );
     }

}

export default withTranslation("translations")(PhotoAuthenticator);


