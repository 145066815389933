import React, { Component } from 'react';
import imageCompression from 'browser-image-compression';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import BTSnackbar from '../../globals/uicomps/BTSnackbar';
export class SingleDocsUpload extends Component {

     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               showSnack: false,
               snackMsg: '',
               snackStatus: 'success',
               isPreview: false
          }
          this.previewList = [];
          this.onAttachmentFileChange = this.onAttachmentFileChange.bind(this);
     }

     componentDidMount = () => {
          let { uploadedFile, isPreview } = this.props;
          if (this.state.isPreview !== isPreview) {
               this.generatePreviewList(uploadedFile)
          }
     }

     componentDidUpdate = () => {
          let { uploadedFile, isPreview } = this.props;
          if (this.state.isPreview !== isPreview) {
               this.generatePreviewList(uploadedFile)
          }
     }

     async onAttachmentFileChange(e) {
          let { allowedDocsList, cbSaveDocument, maxSizeMB, setFileName } = this.props;
          let supportedCompression = ['jpg', 'jpeg', 'png']
          this.setState({ loading: true })
          let useWebWorker = 'mainThread'
          const newFile = e.target.files[0];
          let newFileObj = ''
          let allowedExtensions = allowedDocsList;
          let filename = newFile.name;
          let extension = filename.substr((filename.lastIndexOf('.') + 1));
          extension = extension.toLowerCase();
          var options = {
               maxSizeMB: maxSizeMB,
               useWebWorker,
          }
          if (allowedExtensions.includes(extension) && (filename.includes("."))) {
               if (supportedCompression.includes(extension)) {
                    let output = await imageCompression(newFile, options);
                    const newFormData = new FormData();
                    newFormData.set('blob', output, setFileName ? setFileName + `.${extension}` : filename);
                    output = newFormData.get('blob');
                    newFileObj = { file: output };
               } else {
                    newFileObj = { file: newFile };
               }
               cbSaveDocument(newFileObj)
               this.generatePreviewList(newFileObj);
          } else if ((filename.includes("."))) {
               this.setState({ loading: false, showSnack: true, snackMsg: 'File extension is not supported', snackStatus: 'danger' })
          } else {
               this.setState({ loading: false, showSnack: true, snackMsg: 'Please choose a file with extension', snackStatus: 'danger' })
          }
     }

     generatePreviewList = (newFileObj) => {
          let { removeDocument, isPreview } = this.props;
          let attachmentList = [newFileObj]
          this.previewList = [];
          this.previewList = attachmentList.map(fileObj => {
               if (fileObj.file.name.endsWith('.pdf')) {
                    return (
                         <>
                              <div className="single_doc_upload_wrap" key={fileObj}>
                                   <div className='single_doc_upload_preview'>
                                        <img src={require('../../images/icons/icon-close.svg')} alt="close" className='remove_vendor pointer' onClick={removeDocument} />
                                        <div className='text-center'>   <img src={require("../../images/component-icons/component-pdf.svg")} alt="user" ></img></div>
                                        <div className='text-center wordbreak'>{fileObj.file.name}</div>
                                   </div> </div>
                              <div className="clr"></div>
                         </>)
               } else {
                    return (<>
                         <div className="single_doc_upload_wrap" key={fileObj}>
                              <div className='single_doc_upload_preview'>
                                   <img src={require('../../images/icons/icon-close.svg')} alt="close" className='remove_vendor pointer' onClick={removeDocument} />
                                   <img src={URL.createObjectURL(attachmentList[0].file)} alt="evidence" />
                              </div>
                         </div>
                         <div className="clr"></div>
                    </>);
               }
          })
          this.setState({ loading: false, isPreview: isPreview });
     }

     hideSnack = () => {
          this.setState({ showSnack: false, snackMsg: '', snackStatus: 'success' })
     }


     render = () => {
          let { cardsPerRow, includeHeader, headerText, uploadMessage, subHeader, uploadTypeIcon, isFileUploaded, isPreview, uploadedFile } = this.props;
          let { showSnack, snackMsg, snackStatus, loading } = this.state;
          let colValue = 12 / cardsPerRow;
          uploadedFile = uploadedFile ? URL.createObjectURL(uploadedFile.file) : uploadedFile;

          return (
               <>{showSnack ? <BTSnackbar msg={snackMsg} status={snackStatus} time="2500" onHide={this.hideSnack} /> : null}
                    <>
                         {loading ? <>
                              <div className={`col-12 col-sm-${colValue}`}>
                                   <div className='pad20'>
                                        <LoadingSpinner />
                                   </div>
                              </div>
                         </> : <div className={`col-12 col-sm-${colValue}`}>
                                   {includeHeader ? <label className='body2 mb10'>{headerText}</label> : null}
                                   <div className="white-bg landmark_wrap ">
                                        {!isFileUploaded ? <div className="upload-div" >
                                             {uploadTypeIcon ? <img src={uploadTypeIcon} alt="image_icon" /> : null}
                                             <div>{uploadMessage}</div>
                                             <div className="sub_text"> {subHeader}</div>
                                             <input type="file" name="file" onChange={this.onAttachmentFileChange} ></input>
                                        </div> : isPreview ?
                                                  <>
                                                       <img src={uploadedFile} className='uploaded_img' alt={'altText'} />
                                                  </> :
                                                  <>
                                                       {this.previewList}
                                                  </>
                                        }
                                   </div>
                              </div>}
                    </>
               </>
          );
     }
}