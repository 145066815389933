import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../../../globals/constants';
import { RestDataSource } from '../../../utils/RestDataSource';
import { LoadingSpinner } from "../../../utils/LoadingSpinner"
import BTSnackbar from "../../../globals/uicomps/BTSnackbar"

class ShowVendorDetails extends Component {

     constructor(props) {
          super(props);
          this.state = {
               vendorDetails: {},
               loading: false,
               vendorSpecializationList: [],
               snackbar: false,
               snackMsg: "",
          };
     }
     componentDidMount = () => {
          this.fetchDetails()
          this.setState({
               snackbar: this.props.cbIsVendorEdited,
               snackMsg: "Updated Successfully",
          })
     }

     fetchDetails = () => {
          this.fetchDetails = new RestDataSource(`${restbaseurl}/vendor/show`);
          this.setState({ loading: true }, () => {
               this.fetchDetails.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   vendorDetails: data.data.vendorDetails,
                                   vendorSpecializationList: data.data.vendorSpecializationList,
                                   loading: false
                              })
                         }
                    },
                    { vendorId: this.props.selectedVendorId },
                    "POST"
               );
          });
     }

     hideSnackbar = () => {
          this.setState({
               snackMsg: '',
               snackbar: false
          })
     }

     render = () => {
          const { t } = this.props;
          let vendorObj = this.state.vendorDetails
          let vendorDetails = null
          vendorDetails = Object.keys(vendorObj).map((vendorObjItem, index) => {
               return (
                    <React.Fragment key={new Date().getTime() + '-' + Math.random()}>
                         <div className="col-sm-6 mb-5">
                              <div className="sub-heading2 mb-2">{vendorObjItem}</div>
                              <div>{vendorObj[vendorObjItem]}</div>
                         </div>

                    </React.Fragment>
               )
          })

          let vendorSpecializationListDrug = this.state.vendorSpecializationList.drug
          let vendorSpecializationListCibil = this.state.vendorSpecializationList.cibil
          let vendorSpecializationListResidence = this.state.vendorSpecializationList.residence

          return (

               this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                    {this.state.snackbar ?
                         <BTSnackbar msg={this.state.snackMsg} status="success" time="4000" onHide={this.hideSnackbar} /> : ""}
                    <div className="users-wrapper white-bg">
                         <div className="card-group">
                              <div className="clr">
                              </div>
                         </div>
                         <div className="clr20" ></div>
                         <div>
                              <div className="user-detail col-12">
                                   <div className="row margin0">
                                        {vendorDetails}
                                        <div className="clr5"></div>

                                        {vendorSpecializationListDrug !== undefined ?
                                             <React.Fragment>
                                                  <h6 className="sub_header_3 mb10">Drug Verification</h6>
                                                  <div className="clr15"></div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Capacity</div>
                                                       <div>{vendorSpecializationListDrug.capacity}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Quality Index</div>
                                                       <div>{vendorSpecializationListDrug.qualityIndex}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Average Process Time (in days)</div>
                                                       <div>{vendorSpecializationListDrug.averageProcessTime}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Priority</div>
                                                       <div>{vendorSpecializationListDrug.priority}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Tat (in days)</div>
                                                       <div>{vendorSpecializationListDrug.tat}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Active</div>
                                                       <div>{vendorSpecializationListDrug.active ? "True" : "False"}</div>
                                                  </div>
                                                  <div className="clr15"></div>
                                             </React.Fragment> : null}
                                        {vendorSpecializationListCibil !== undefined ?
                                             <React.Fragment>
                                                  <h6 className="sub_header_3 mb10">Cibil Verification</h6>
                                                  <div className="clr15"></div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Capacity</div>
                                                       <div>{vendorSpecializationListCibil.capacity}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Quality Index</div>
                                                       <div>{vendorSpecializationListCibil.qualityIndex}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Average Process Time (in days)</div>
                                                       <div>{vendorSpecializationListCibil.averageProcessTime}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Priority</div>
                                                       <div>{vendorSpecializationListCibil.priority}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Tat (in days)</div>
                                                       <div>{vendorSpecializationListCibil.tat}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Active</div>
                                                       <div>{vendorSpecializationListCibil.active ? "True" : "False"}</div>
                                                  </div>
                                                  <div className="clr15"></div>
                                             </React.Fragment> : null}
                                        {vendorSpecializationListResidence !== undefined ?
                                             <React.Fragment>
                                                  <h6 className="sub_header_3 mb10">Residence Verification</h6>
                                                  <div className="clr15"></div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Capacity</div>
                                                       <div>{vendorSpecializationListResidence.capacity}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Quality Index</div>
                                                       <div>{vendorSpecializationListResidence.qualityIndex}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Average Process Time (in days)</div>
                                                       <div>{vendorSpecializationListResidence.averageProcessTime}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Priority</div>
                                                       <div>{vendorSpecializationListResidence.priority}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Tat (in days)</div>
                                                       <div>{vendorSpecializationListResidence.tat}</div>
                                                  </div>
                                                  <div className="col-sm-4 mb-5">
                                                       <div className="sub-heading2 mb-2">Active</div>
                                                       <div>{vendorSpecializationListResidence.active ? "True" : "False"}</div>
                                                  </div>
                                                  <div className="clr15"></div>
                                             </React.Fragment> : null}
                                   </div>
                              </div>
                              <div className="clr10"></div>
                         </div>
                    </div>
                    <div className="clr20"></div>
                    <div className="text-center">
                         <span className="btn btn_secondary ml10" onClick={() => this.props.showManagementLayout()}>{t('Back')}</span>
                         <span className="btn btn_primary ml10" onClick={() => this.props.editVendor(this.props.selectedVendorId)}>{t('Edit')}</span>
                         <div className="clr"></div>
                    </div>
               </React.Fragment >
          );
     }
}

export default withTranslation('translations')(ShowVendorDetails);
