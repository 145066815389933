import React, {Component} from 'react';

export class BTFieldValError extends Component {

     render = () => {
          console.log(this.props);
          return(

               <label className="error right">
                    {this.props.children}
               </label>
               
          );
     }
}
