import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Navbar } from "react-bootstrap";

class VendorManagementNav extends Component {


     render = () => {
          const navList = this.props.subHeaderNavList
          const {empSubList} = this.props
          return (
               <React.Fragment>
                    <Navbar bg="transparent" className="subheader-nav">
                         <Navbar.Toggle aria-controls="responsive-navbar-nav" >
                              <div className="toggle-wrap">
                                   <span className="toggle-bar"></span>
                              </div>
                         </Navbar.Toggle>
                         <Navbar.Collapse id="responsive-navbar-nav" className="responsive-navbar-nav"   >
                                   <ul className="navbar-nav m-auto sub-header  case-navs">
                                        {
                                             navList.map((tabName) => {
                                                  let routename = tabName;
                                                  let classList = "nav-item pointer";
                                                  let isVerified = false;
                                                  if (routename === this.props.activeTab) {
                                                       classList = classList.concat(" active");
                                                  }
                                                  if(empSubList && empSubList.length > 0 && empSubList.includes(tabName)){
                                                       isVerified = true
                                                  }
                                                  return (
                                                       <li className={classList} key={routename}>
                                                            <button className={`nav-link btn-link ${isVerified ? 'verified' : ''}`} onClick={() => this.props.cbChangeBaseTab(routename)}>{tabName} </button>
                                                       </li>
                                                  )
                                             })
                                        }
                                   </ul>
                         </Navbar.Collapse>
                    </Navbar>
               </React.Fragment >
          );
     }
}

export default withTranslation('translations')(VendorManagementNav);
