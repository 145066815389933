import React, { Component } from 'react';

class CaseSearchTable extends Component {

     render = () => {
          const { caseList } = this.props;

          let tableBody = <React.Fragment><tr><td colSpan="3">No Cases Found</td></tr></React.Fragment>;
          if (caseList.length > 0) {
               tableBody = caseList.map((caseObj, idx) => {
                    let name = caseObj.firstname + (caseObj.lastname ? ' ' + caseObj.lastname : '');
                    return (
                         <React.Fragment>
                              <tr>
                                   <td>{idx + 1}</td>
                                   <td>{caseObj.caseNumber}</td>
                                   <td>{name}</td>
                                   <td>
                                        <span className="btn_primary badge view_primary_btn" onClick={()=>this.props.cbCaseSelect(caseObj.id)}>View</span>
                                   </td>
                              </tr>
                         </React.Fragment>
                    )
               })
          }
          return (
               <React.Fragment>
                    <div className="commontbl">
                         <table className="table">
                              <thead>
                                   <tr>
                                        <th>S.No.</th>
                                        <th>Case Number</th>
                                        <th>Name</th>
                                        <th>Action</th>
                                   </tr>
                              </thead>
                              <tbody>
                                   {tableBody}
                              </tbody>
                         </table>
                    </div>
               </React.Fragment>
          )
     }
}
export default CaseSearchTable;