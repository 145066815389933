import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';

class BtAccordianCard extends Component {

     click = (e) => {
          if (!!this.props.cbClick) {
               this.props.cbClick(e.target.getAttribute('keyattr'));
          }
     }

     render = () => {
          return (
               <Card key={this.props.cardKey}>
                    <div className="pack-accordion-head">
                         <Accordion.Toggle className="pointer border-bottom" as="div" variant="link" eventKey={this.props.cardIndex} onClick={this.click}>
                              <h6 keyattr={this.props.cardKey}>{this.props.label}
                                   <div className="sub-heading">{this.props.subLabel}</div>
                              </h6>

                              <img keyattr={this.props.cardKey} src="/images/icons/icon-select-arrow.svg" className="right pointer" alt=""></img>
                              <div className="clr"></div>
                         </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse eventKey={this.props.cardIndex}>
                         <Card.Body>{this.props.children}</Card.Body>
                    </Accordion.Collapse>
               </Card>

          );
     }

}

export default withTranslation('translations')(BtAccordianCard);

//<BasicInfoContainer />