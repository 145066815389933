import React, { Component } from 'react'
import Spinner  from 'react-bootstrap/Spinner';

export class LoadingSpinner extends Component {
     render = () => {
          var spinnerStyle = {
               padding: 0,
               left:'50%',
               top:'50%',
               position:'absolute',
               transform: 'translate(-50%,-50%)'
          };

          return (
               <div style={spinnerStyle}>
                    <Spinner animation="border" variant="info" />
               </div>               
          );
     }
}