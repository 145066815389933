import React, { Component } from 'react';
import VerificationStatusDetails from './VerificationStatusDetails';
import { withTranslation } from 'react-i18next';
import UserData from '../clientAdmin/socialIntelligence/UserData';
import VerificationEvidenceShow from './VerificationEvidenceShow';


class VerificationStatusDetailsHeader extends Component {
     render = () => {

          const { t } = this.props;
          let { vendorOptions } = this.props;
          let elements;
          let evidenceList = this.props.evidenceList;
          let isEvidencePresent = evidenceList.length > 0 ? true : false
          if (this.props.typeMap['VerificationType'] !== 'Social Media Check') {
               elements = Object.keys(this.props.dataObj).map(data => (
                    <VerificationStatusDetails data={data} key={data + Math.random()} obj={this.props.dataObj} caseId= {this.props.caseId} verificationType={this.props.typeMap['VerificationType']} vendorDetails={this.props.vendorDetails} addressList={this.props.dataObj['Address List']} address={this.props.dataObj['Address']} cbRender={this.props.cbRender} vendorOptions={vendorOptions} />
               ));
          }
          let evidence
          if (isEvidencePresent === true) {
               console.log("isEvidencePresent", isEvidencePresent)
               evidence = evidenceList.map(data =>
                    <VerificationEvidenceShow value={data} key={data} />
               )
          }
          return (
               <div className="deep-track">
                    <div className="title">
                         <div className="check-title">{t(this.props.typeMap['VerificationType'])}</div>
                         <svg className="close-deep-track" onClick={() => { this.props.hideDeepTrack() }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                              <path d="M9.7,104l5.128-5.128,1.058-1.058a.4.4,0,0,0,0-.566l-1.132-1.132a.4.4,0,0,0-.566,0L8,102.3,1.815,96.117a.4.4,0,0,0-.566,0L.117,97.249a.4.4,0,0,0,0,.566L6.3,104,.117,110.186a.4.4,0,0,0,0,.566l1.132,1.132a.4.4,0,0,0,.566,0L8,105.7l5.128,5.128,1.058,1.058a.4.4,0,0,0,.566,0l1.132-1.132a.4.4,0,0,0,0-.566Z" transform="translate(4 -92)"></path>
                         </svg>
                    </div>
                    <div className="content">

                         {
                              this.props.typeMap['VerificationType'] === 'Social Media Check' ?
                                   <UserData showSearchPage={false} userData={this.props.dataObj.userDataJson} /> :
                                   <React.Fragment>
                                        <div className="case-check-details">
                                             <div className="sub-heading1">
                                                  {t(this.props.typeMap['Verification'])}
                                             </div>
                                             <div className="clr20"></div>
                                             <div className="row">
                                                  {elements}
                                             </div>
                                        </div>
                                        <br />
                                        {isEvidencePresent ?
                                             <div className="container">
                                                  <div className="row">
                                                       {evidence}
                                                  </div>
                                             </div>
                                             : ''
                                        }
                                   </React.Fragment>
                         }
                    </div>
               </div>
          );
     }
}
export default withTranslation('translations')(VerificationStatusDetailsHeader);
