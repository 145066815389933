import React from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../../../globals/constants';
import { LoadingSpinner } from "../../../utils/LoadingSpinner";
import Axios from 'axios';
import ConfirmationModal from '../../../globals/uicomps/ConfirmationModal';
import { getValue } from '../../../globals/utils';
import BTSnackbar from '../../../globals/uicomps/BTSnackbar';
class CaseCard extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               loaLink: '',
               loading: false,
               optionClass: false,
               showConfirmationModal: false,
               IncludeAdditionalDocs: false,
               showSnack: false,
               snackMsg: '',
               snackStatus: 'success',
          }
          this.role = getValue('roleCode');
     }
     handleViewClick = () => {
          this.props.cbViewCase(this.props.caseObj.id);
     }

     handleDeleteClick = () => {
          this.props.cbDeleteCase(this.props.caseObj.id);
     }
     handleReportResetClick = () => {
          this.props.cbReportReset(this.props.caseObj.id);
     }

     optionOnClick = () => {
          if (!this.state.optionClass) {
               document.addEventListener('click', this.handleOutsideClick);
          } else {
               document.removeEventListener('click', this.handleOutsideClick);
          }
          this.setState(prevState => ({ optionClass: !prevState.optionClass }));
     }
     handleOutsideClick = () => {
          this.optionOnClick();
     }

     handleIncludeAdditionalDocs = () => {
          let value = this.state.IncludeAdditionalDocs
          this.setState({
               IncludeAdditionalDocs: !value
          })
     }

     handleDownloadSource = (caseNumber) => {
          let url = `${restbaseurl}/applicantCase/downloadApplicantDocsZip`;
          this.setState({ loading: true });
          const config = {
               responseType: 'blob',
               withCredentials: true
          }
          Axios.post(url,
               {
                    caseNumber: caseNumber,
                    includeInReport: this.state.IncludeAdditionalDocs
               },
               config
          ).then((response) => {
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', `${caseNumber}.zip`); //or any other extension
               document.body.appendChild(link);
               link.click();
               this.setState({ loading: false, showConfirmationModal: false });
          });
     }

     downloadLoa = (id) => {
          this.dataSource = `${restbaseurl}/applicantCase/downloadLoa/${id}`
          this.setState({ loading: true }, () => {
               let apiURL = this.dataSource
               Axios(`${apiURL}`, {
                    method: 'GET',
                    responseType: 'blob' //Force to receive data in a Blob Format
               })
                    .then(response => {
                         this.setState({
                              loading: false
                         })
                         const file = new Blob(
                              [response.data],
                              { type: 'application/pdf' });
                         const fileURL = URL.createObjectURL(file);
                         window.open(fileURL);
                    })
                    .catch(error => {
                         console.log(error);
                    });
          }
          )
     }

     showConfirmationModal = () => {
          this.setState({
               showConfirmationModal: true
          })
     }

     hideModal = () => {
          this.setState({
               showConfirmationModal: false
          })
     }

     uploadAdditionalDocs = () => {
          const formData = new FormData(this.form);
          formData.append('caseId', this.props.caseObj.id);
          const url = `${restbaseurl}/applicantCase/uploadAdditionalDocs`;
          const config = {
               headers: { 'content-type': 'multipart/form-data' },
               withCredentials: true
          };
          Axios.post(url, formData, config).then(({ data }) => {
               this.setState({
                    showSnack: true, snackMsg: 'Documents Successfully Uploaded', snackStatus: 'success',
               })
          }).catch((error) => {
               this.setState({ documentsLoading: false, bgvLoading: false });
          });
     }

     hideSnack = () => {
          this.setState({
               showSnack: false, snackMsg: '', snackStatus: 'success',
          });
     }

     render = () => {
          const { t } = this.props;
          const { isClientAccessable, caseObj } = this.props;
          const statusText = caseObj.caseStatusCode;
          const { showSnack, snackMsg, snackStatus } = this.state
          return (
               this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                    {showSnack ? <BTSnackbar msg={snackMsg} status={snackStatus} time="4000" onHide={this.hideSnack} /> : null}
                    <div className="col-12 col-sm-6 col-md-6 case_management_card">
                         <div className="padding-card box-shadow-card mb10 mt10">
                              <div className="sub-heading1 left mr10 col-6 wordbreak pad0">{caseObj.name}</div>
                              {isClientAccessable ? <div className="action-group right ml10 case_view_wrap" >
                                   <button className="action-btn" onClick={this.optionOnClick}><span className="bt-icon bt-dribble-bot-v"></span></button>
                                   <div className={this.state.optionClass ? 'active action-option ' : 'action-option '}>
                                        <ul>
                                             <li onClick={this.showConfirmationModal}>{t('Download Source Document')}</li>
                                             {caseObj.isLoaPresent ? <li onClick={() => this.downloadLoa(caseObj.id)}>{t('Download LOA')}</li> : null}
                                             <li onClick={this.handleDeleteClick}>{t('Delete Case')}</li>
                                             <li>
                                                  <form ref={(el) => (this.form = el)}>
                                                       <span className={`file-wrapper styleDisable`}>
                                                            <input id="file" type="file" multiple name="file" onChange={() => this.uploadAdditionalDocs()} />
                                                            <div className="">
                                                                 {t('Upload Additional Documents')}
                                                            </div>
                                                       </span>
                                                  </form>
                                             </li>
                                        </ul>
                                   </div>
                              </div> : null}
                              {statusText ? <span className="casemanagement_badge badge case-status-badge right">{statusText}</span> : null}
                              {/* <img src={"/images/icons/icon-refresh.svg"} alt="refresh" className="mr10 pointer right" onClick={this.handleReportResetClick} title={t('adminlayout.tabs.casemanagement.casecard.reportreset.title')}></img> */}
                              <div className="clr"></div>
                              <div className="sub-heading1 mt5">{caseObj.caseNumber}</div>
                              <div className="mt5">Client Name: {caseObj.clientName}</div>
                              <div className="mt5">Entity Name: {caseObj.entityName}</div>
                              <div className="clr20"></div>
                              {(isClientAccessable || this.role === 'reportviewer') ? <div className="text-align-right check_management_btn">
                                   <span className="btn btn_primary mr10" onClick={this.handleReportResetClick}>Recompute report</span>
                                   <span className="btn btn_primary" onClick={this.handleViewClick}>View</span>
                              </div> : null}
                         </div>
                    </div>
                    <ConfirmationModal
                         show={this.state.showConfirmationModal}
                         heading="Download Source"
                         subheading=""
                         successButtonText="Download"
                         failureButtonText="Cancel"
                         successCallback={() => { this.handleDownloadSource(caseObj.caseNumber) }}
                         failureCallback={this.hideModal}
                         closeCallback={this.hideModal}
                         className='remove_clr'
                    >
                         <div className="form-group search-filter  checkclass mb0">
                              <label className="container_Checkbox roundedcheckbox mb0"> Include Additional Document
                                        <input type="checkbox" id="additionalDocs" onChange={this.handleIncludeAdditionalDocs} />
                                   <span className="checkmark"></span>
                              </label>
                         </div>
                    </ConfirmationModal>
               </React.Fragment>
          );
     }
}

export default withTranslation('translations')(CaseCard);
