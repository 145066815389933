import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import Select from "react-select";
import ConfirmationModal from '../../../globals/uicomps/ConfirmationModal';
import UserInfo from '../../../models/UserInfo';
import { restbaseurl } from '../../../globals/constants';
import { RestDataSource } from '../../../utils/RestDataSource';

class DrugAssignmentTableRow extends Component {
     constructor(props) {
          super(props);
          this.state = {
               caseRowloading: false,
               dummy: false,
               vendorId: '',
               showConfirmationModal: false,
               subheading: '',
               unAssign: false
          }
          this.userId = UserInfo.getUserid();
     }

     onVendorChange = (event) => {
          let { checkCode } = this.props;
          this.setState({
               vendorLabel: event.label,
               vendorId: event.value,
               showConfirmationModal: true,
               subheading: `Do you want to assign  ${event.label} to this ${checkCode} check`
          })
     }

     confirmSuccessCallback = () => {
          let { checkCode } = this.props;
          let action = 'assignVendor';
          if (this.props.unAssignedObjList.id) {
               action = 'reAssignVendor';
          }
          if (this.state.unAssign) {
               this.unAssignVendor()
          } else {
               this.saveDrugVendor = new RestDataSource(
                    `${restbaseurl}/${checkCode}/${action}`
               );
               this.setState({ loading: true }, () => {
                    this.saveDrugVendor.GetData(
                         data => {
                              if (data.status === true) {
                                   this.setState({
                                        showConfirmationModal: false,
                                        cbRender: this.props.cbRender()
                                   })
                              }
                         },
                         {
                              "vendorId": this.state.vendorId,
                              'id': this.props.unAssignedObjList.id,
                              userId: this.userId
                         },
                         "POST"
                    );
               });
          }
     }

     unAssignVendorConfirmation = () => {
          this.setState({
               showConfirmationModal: true,
               unAssign: true,
               subheading: `Do you want to Unassign vendor to this case `
          })
     }

     unAssignVendor = () => {
          let { checkCode } = this.props;
          this.unAssignDrugVendor = new RestDataSource(
               `${restbaseurl}/${checkCode}/unassignVendor`
          );
          this.setState({ loading: true }, () => {
               this.unAssignDrugVendor.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   showConfirmationModal: false,
                                   unAssign: false,
                                   cbRender: this.props.cbRender()
                              })
                         }
                    },
                    {
                         "id": this.props.unAssignedObjList.id, userId: UserInfo.getUserid()
                    },
                    "POST"
               );
          });
     }


     render() {
          // let { checkCode } = this.props;
          let unAssignedObjList = this.props.unAssignedObjList;
          let vendorAvailableList = this.props.unAssignedObjList.vendorAvailableList
          let vendorOptions = vendorAvailableList.map(vendorObj => {
               return { label: vendorObj.vendorName, value: vendorObj.vendorId };
          });
          let isVendorAvailable = vendorOptions.length;
          const { isVendorPresent } = this.props;
          let vendorAssignment = '';
          if (isVendorPresent) {
               if (isVendorAvailable) {
                    vendorAssignment = <> <td>
                         <div className="straight_vendor" >
                              <Select
                                   id="vendorId"
                                   style={{ border: "2px solid red" }}
                                   options={vendorOptions}
                                   onChange={this.onVendorChange}
                              ></Select>
                         </div>
                    </td>
                         <td>
                              <img alt="icon-close" src={require('../../../images/icons/icon-close.svg')} className="remove_vendor pointer" onClick={this.unAssignVendorConfirmation} style={{ marginTop: "7px" }} />
                         </td>
                    </>
               } else {
                    vendorAssignment = <> <td>No Vendor  Available</td>
                         <td>
                              <img alt="icon-close" src={require('../../../images/icons/icon-close.svg')} className="remove_vendor pointer" onClick={this.unAssignVendorConfirmation} style={{ marginTop: "7px" }} />
                         </td>
                    </>
               }
          } else {
               if (isVendorAvailable) {
                    vendorAssignment = <> <td>
                         <div className="straight_vendor" >
                              <Select
                                   id="vendorId"
                                   style={{ border: "2px solid red" }}
                                   options={vendorOptions}
                                   onChange={this.onVendorChange}
                              ></Select>
                         </div>
                    </td>
                    </>
               } else {
                    vendorAssignment = <> <td>No Vendor  Available</td>
                    </>
               }
          }

          return (
               <React.Fragment>
                    <tr className="case-row" >
                         <td>{this.props.idx + 1}</td>
                         <td>
                              <div className="straight_td title-hover " >
                                   {unAssignedObjList.caseNumber}
                              </div>
                         </td>
                         <td>
                              <div className="straight_td" >{unAssignedObjList.assignedDate}</div>
                         </td>
                         <td>
                              <div className="straight_name" title={unAssignedObjList.name} >{unAssignedObjList.name}</div>
                         </td>
                         <td>
                              <div className="straight_td" >
                                   {unAssignedObjList.bloodgroup}
                              </div>
                         </td>
                         <td>
                              <div className="straight_td" >
                                   {unAssignedObjList.checkType}
                              </div>
                         </td>
                         <td>
                              <div className="straight_td" >
                                   {unAssignedObjList.status}
                              </div>
                         </td>
                         {vendorAssignment}
                    </tr>
                    <ConfirmationModal
                         show={this.state.showConfirmationModal}
                         heading="Are you sure ?"
                         subheading={this.state.subheading}
                         successButtonText="Yes"
                         failureButtonText="No"
                         successCallback={() => { this.confirmSuccessCallback() }}
                         failureCallback={() => { this.setState({ showConfirmationModal: false }) }}
                         closeCallback={() => { this.setState({ showConfirmationModal: false }) }}
                    ></ConfirmationModal>
               </React.Fragment>
          )
     }
}
export default withTranslation("translations")(DrugAssignmentTableRow);

