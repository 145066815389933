import React, { Component } from 'react';

export class InternalError extends Component {

     render = () => {
          return (
               <div>
                    <p><strong>Oops! An error occured!</strong></p>
               </div>
          );
     }
}