import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getValue } from '../../globals/utils';

class PackInfoModal extends Component {
     constructor(props) {
          super(props);
          this.state = {
               active: this.props.isCodeFound
          };
          this.role = getValue('roleCode')
     }

     successHandler = () => {
          if (this.props.successCallback) {
               this.props.successCallback();
          }
     }


     render = () => {
          let packDetails = this.props.packDetails;
          let packName = this.props.packName;
          const isAdmin = getValue('rolePriority') <= getValue('roleMap')['operationshead'];
          let packDetailsTable = <span className="packdetailstab"> <table className="table ">
               <thead>
                    <tr>
                         <th><b>Check</b></th>
                         <th><b>Check Details</b></th>
                         {isAdmin ? <th><b>Cost</b></th> : null}
                    </tr>
               </thead>
               <tbody>
                    {Object.keys(packDetails).map((data, index) =>
                         <tr key={data + "" + index}>
                              <td>{packDetails[data].verification}</td>
                              <td className="packdetails">
                                   {packDetails[data].verification === 'Identity' ? <React.Fragment>
                                        <div className="span_sets">
                                             {
                                                  packDetails[data].verificationDetails[0].length > 0 ?
                                                       <React.Fragment>
                                                            <p className='inline-block mr5'>Mandatory :  </p>
                                                            {packDetails[data].verificationDetails[0].map(packDetails => {
                                                                 return (
                                                                      <span>
                                                                           {packDetails}
                                                                      </span>
                                                                 )
                                                            }

                                                            )}</React.Fragment> : null}
                                        </div>
                                        <p className='clr mar0'></p>
                                        <div className="span_sets">
                                             {
                                                  packDetails[data].verificationDetails[1].length > 0 ?
                                                       <React.Fragment>
                                                            <p className='inline-block mr5'>Optional  ({packDetails[data].verificationDetails[2]}) :   </p>
                                                            {/* <div className='clr'></div> */}
                                                            {packDetails[data].verificationDetails[1].map(packDetails => {
                                                                 return (
                                                                      <span>
                                                                           {packDetails}
                                                                      </span>
                                                                 )
                                                            }

                                                            )}</React.Fragment> : null}
                                        </div></React.Fragment> : <div className="span_sets">
                                             {
                                                  packDetails[data].verificationDetails.map(packDetails => {
                                                       return (<>
                                                            <span>
                                                                 {packDetails}
                                                            </span>
                                                       </>
                                                       )
                                                  }

                                                  )}
                                        </div>}
                                   {packDetails[data].attachmentDetails.length > 0 ?
                                        <div className="col-12 mt10 pad0">
                                             <React.Fragment>
                                                  <img src={"/images/icons/icon-attach.svg"} height="15" alt="info-icon" className="mr5 mt5" />
                                                  <span className="span_sets">
                                                       {
                                                            packDetails[data].attachmentDetails.map(attachmentDetails => {
                                                                 return (
                                                                      <span>{attachmentDetails}</span>
                                                                 )
                                                            })
                                                       }
                                                  </span>
                                             </React.Fragment>
                                        </div>
                                        : null}
                              </td>
                              {isAdmin ? <td>{packDetails[data].cost}</td> : null}
                         </tr>
                    )}
               </tbody>

          </table>
          </span>
          return (
               <React.Fragment>
                    <Modal className="modal fade yes-or-no-modal  info-modal pack_info_modal" id="yes-or-no-modal" show={this.props.show} onHide={this.props.onHide} >
                         <div className="close padr10" onClick={this.hide} aria-label="Close">
                              <span className="bt-icon bt-close pointer pos_fixed" onClick={this.props.onHide}></span>
                         </div>
                         <Modal.Body className="pad20">
                              <div className="bt-modal-content">
                                   <div className="pos_fixed">
                                        <h6 className="wordbreak">Pack Details - {packName}</h6>
                                   </div>
                                   <div className="body2">{packDetailsTable}</div>
                                   <div className="clr15"></div>
                              </div>
                         </Modal.Body>

                    </Modal >
               </React.Fragment>
          );
     }
}

export default PackInfoModal;