/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
import React, { Component, Suspense } from 'react';
import {
     BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
// import ReportViewerHeader from './ReportViewerHeader';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import ClientListLayout from './client/ClientListLayout';
import ReportViewerCases from './cases/ReportViewerCases';
// import CaseManagementBaseLayout from './caseManagement/CaseManagementBaseLayout';
import DialogModal from '../globals/uicomps/DialogModal';
import ChangePassword from '../clientAdmin/administration/ChangePassword';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import ManagementLayout from './management/ManagementLayout';

class ReportViewerBaseLayout extends Component {
     constructor(props) {
          super(props);
          const { match } = this.props;
          const { path } = match;
          this.state = {
               snackbar: false,
               snackmsg: '',
               clientId: '',
               basePath: path,
               activeTab: 'clientList',
               showChangePasswordModal: false,
          };
     }

     changeBaseTab = (path) => {
          this.setState({
               activeTab: path,
          });
     }

     handleChangePassword = () => {
          this.setState({
               showChangePasswordModal: true
          })
     }

     cbChangePassword = () => {
          this.setState({
               snackbar: true,
               snackmsg: 'Updated Successfully',
               showChangePasswordModal: false
          })
     }

     hideModal = () => {
          this.setState({
               showChangePasswordModal: false
          })
     }

     render = () => {
          const {
               basePath, activeTab
          } = this.state;
          const path = basePath;
          let action = '/user/changePassword';
          return (
               <React.Fragment>
                    {/* <ReportViewerHeader handleChangePassword={this.handleChangePassword}/> */}
                    {this.state.snackbar ?
                         <BTSnackbar msg={this.state.snackmsg} status="success" time="5000" onHide={this.hideSnackbar} /> : ""}
                    <Router>
                         <Suspense fallback={<div><LoadingSpinner /></div>}>
                              <Switch>
                                   <Route exact path={`${path}/clientList`} render={(props) => <ClientListLayout cbChangeBaseTab={this.changeBaseTab} />} />
                                   <Route exact path={`${path}/clientCases`} render={(props) => <ReportViewerCases cbChangeBaseTab={this.changeBaseTab} />} />
                                   {/* <Route exact path={`${path}/caseManagement`} render={(props)=> <CaseManagementBaseLayout cbChangeBaseTab={this.changeBaseTab} />} /> */}
                                   <Route exact path={`${path}/management`} render={(props) => <ManagementLayout cbChangeBaseTab={this.changeBaseTab} />} />
                              </Switch>
                         </Suspense>
                         <Redirect to={`${path}/${activeTab}`} />
                    </Router>
                    <div className="modal-dialog ">
                         <DialogModal
                              show={this.state.showChangePasswordModal}
                              header="Change Password"
                              successButtonText="Save"
                              failureButtonText="Cancel"
                              onHide={this.hideModal}
                              className="reset-popup"
                              showFooter="false"
                         >
                              <ChangePassword
                                   cbChangePassword={this.cbChangePassword}
                                   onHide={this.hideModal}
                                   action={action}
                              />
                         </DialogModal>
                    </div>
               </React.Fragment>
          );
     }
}

export default ReportViewerBaseLayout;
