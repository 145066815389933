import React, { Component } from 'react';
import VerificationStatusDetailsHeader from './VerificationStatusDetailsHeader';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';

class DeepTrack extends Component {
     constructor(props) {
          super(props);
          this.state = {
               vendorOptions: [],
               resId: '',
               someState: '',
               creditId: '',
               drugId: ''
          };
     }

     componentDidMount = () => {
          const { typeMap } = this.props;
          if (typeMap && typeMap.VerificationType === 'Residence Check') {
               this.fetchResidenceVendorOptionList();
          }
          if (typeMap && typeMap.VerificationType === 'Credit Check') {
               this.fetchCibilVendorOptionList();
          }
          if (typeMap && typeMap.VerificationType === 'Drug Check') {
               this.fetchDrugVendorOptionList();
          }
     }

     componentDidUpdate() {
          const { typeMap } = this.props;
          if (this.state.resId !== this.props.vendorDetails.resId) {
               if (typeMap && typeMap.VerificationType === 'Residence Check') {
                    this.fetchResidenceVendorOptionList();
               }
          }
          if (this.state.creditId !== this.props.vendorDetails.creditId) {
               if (typeMap && typeMap.VerificationType === 'Credit Check') {
                    this.fetchCibilVendorOptionList();
               }
          }
          if (this.state.drugId !== this.props.vendorDetails.drugId) {
               if (typeMap && typeMap.VerificationType === 'Drug Check') {
                    this.fetchDrugVendorOptionList();
               }
          }
     }

     fetchCibilVendorOptionList = () => {
          this.fetchCibilVendor = new RestDataSource(
               `${restbaseurl}/cibil/vendorAvailabilityList`
          );
          this.fetchCibilVendor.GetData(
               data => {
                    if (data.status === true) {
                         let vendorOptions = []
                         vendorOptions = data.data.vendorList.map(vendorObj => {
                              return { label: vendorObj.vendorName, value: vendorObj.vendorId };
                         });
                         this.setState({
                              vendorOptions: vendorOptions,
                              creditId: this.props.vendorDetails.creditId,
                              drugId: '',
                              resId: ''
                         })
                    }
               },
               { "cibilVerificationId": this.props.vendorDetails.creditId },
               "POST"
          );
     }

     fetchResidenceVendorOptionList = () => {
          this.loadList = new RestDataSource(
               `${restbaseurl}/residence/vendorAvailabilityList`
          );
          this.loadList.GetData(
               data => {
                    if (data.status === true) {
                         let vendorOptions = []
                         vendorOptions = data.vendorAvailabilityList.map(vendorObj => {
                              return { label: vendorObj.vendorName, value: vendorObj.vendorId };
                         });
                         this.setState({
                              vendorOptions: vendorOptions,
                              resId: this.props.vendorDetails.resId,
                              creditId: '',
                              drugId: '',
                         })
                    }
               },
               { "residenceId": this.props.vendorDetails.resId },
               "POST"
          );
     }

     fetchDrugVendorOptionList = () => {
          this.fetchDrugVendorList = new RestDataSource(
               `${restbaseurl}/drug/vendorAvailabilityList`
          );
          this.fetchDrugVendorList.GetData(
               data => {
                    if (data.status === true) {
                         let vendorOptions = []
                         vendorOptions = data.data.vendorList.map(vendorObj => {
                              return { label: vendorObj.vendorName, value: vendorObj.vendorId };
                         });
                         this.setState({
                              vendorOptions: vendorOptions,
                              drugId: this.props.vendorDetails.drugId,
                              resId: '',
                              creditId: '',
                         })
                    }
               },
               { "drugVerificationId": this.props.vendorDetails.drugId },
               "POST"
          );
     }

     render = () => {
          let { vendorOptions } = this.state
          return (
               <div>
                    <VerificationStatusDetailsHeader dataObj={this.props.dataObj} typeMap={this.props.typeMap} evidenceList={this.props.evidenceList} hideDeepTrack={this.props.hideDeepTrack} vendorDetails={this.props.vendorDetails} cbRender={this.props.cbRender} caseId={this.props.caseId} vendorOptions={vendorOptions} />
               </div>
          );
     }

}
export default DeepTrack;


