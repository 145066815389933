import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class VerificationEvidenceShow extends Component {

     render = () => {
          return (
               <div className="col-md-6 col-12">
                    <div className="img-wrapper">
                         <img src={this.props.value} alt="No Evidence" style={{ display: 'inherit' }} />
                    </div>
               </div>
          )
     }
}
export default withTranslation('translations')(VerificationEvidenceShow);