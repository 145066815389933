import React from 'react';
import { restbaseurl } from '../globals/constants';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import { RestDataSource } from '../utils/RestDataSource';

class QcOperationDetails extends React.Component {
     constructor() {
          super();
          this.state = {
               verificationList: [],
               qcDetails: '',
               loading: false,
          }
     }

     componentDidMount() {
          this.init();
     }

     init = () => {
          this.caseDetails = new RestDataSource(`${restbaseurl}/qualityCheck/fetchQcOperationsDetails`);
          this.setState({ loading: true }, () => {
               this.caseDetails.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   verificationList: data.data.verificationList,
                                   qcDetails: data.data,
                                   loading: false,
                              })
                         }
                    },
                    { caseId: this.props.caseId },
                    "POST"
               );
          });
     }

     onActiveChange = (name) => {
          const { verificationList } = this.state
          verificationList.forEach((data) => {
               if (data.name === name) {
                    data.isCompleted = !data.isCompleted
               }
          })
          this.setState({ verificationList })
     }

     saveQcOperationDetails = () => {
          const {
               verificationList
          } = this.state;
          this.caseDetails = new RestDataSource(`${restbaseurl}/qualityCheck/saveQcOperationsDetails`);
          this.setState({ loading: true }, () => {
               this.caseDetails.GetData(
                    data => {
                         if (data.status === true) {
                              this.init();
                         }
                    },
                    { caseId: this.props.caseId, verificationList: verificationList },
                    "POST"
               );
          });
     }

     render() {
          const { verificationList, qcDetails, loading } = this.state
          const elements =
               <>
                    <tr>
                         <td>{qcDetails.dateCreated ? qcDetails.dateCreated : '-'}</td>
                         <td>{qcDetails.status ? qcDetails.status : '-'}</td>
                         <td>{qcDetails.comments ? qcDetails.comments : '-'}</td>
                         <td>
                              <div style={{display: "flex"}}>
                              {verificationList ? verificationList.map((data) => {
                                   return(
                                        <div className='col-3'>
                                             <div className="form-group mt15 cost_approval role_status" >
                                                  <div className="switch-group" style={{marginTop: "-17px"}} >
                                                       <label className="switch verification">
                                                            <input type="checkbox" style={{ display: 'none' }} name="isApproved" checked={data.isCompleted} onChange={() => this.onActiveChange(data.name)} />
                                                            <span>
                                                            <strong>{data.name}</strong>
                                                            </span>
                                                       </label>
                                                  </div>
                                             </div>
                                        </div>
                                   )
                              }) : ''}
                              </div>
                         </td>
                    </tr>
               </>
          return (
               <>
                    <div className="card-body qcDetails" >
                         <div className="all-response-tables reportpreviewcard">
                              <table className="table qcOperations">
                                   <thead>
                                        <tr>
                                             <th>
                                                  <span className="left">Date Created</span>
                                             </th>
                                             <th>
                                                  <span className="left">Status</span>
                                             </th>
                                             <th className="medium">
                                                  <span className="left">Comments</span>
                                             </th>
                                             <th>
                                                  <span className="left">Pending Components</span>
                                             </th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {loading === true ?
                                             <LoadingSpinner />
                                             :
                                             elements
                                        }
                                   </tbody>
                              </table>
                              <div className=" col-sm-12 col-md-12 col-12 text-center">
                                   <button className="btn btn_primary" onClick={this.saveQcOperationDetails}>Submit</button>
                              </div>
                              <div className='clr20'></div>
                         </div>
                    </div>
               </>
          )
     }
}
export default QcOperationDetails;