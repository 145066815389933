import React, { Component } from 'react';
// import log from 'loglevel';
// import remote from 'loglevel-plugin-remote';
// import Logger from './Logger';
// import CaseInitLayout from '../../clientAdmin/createInit/CaseInitLayout';
// import { restbaseurl } from '../constants';

// const customJSON = log => ({
//      // msg: log.message,
//      // level: log,
//      // stacktrace: log
// });




class TestRest extends Component {
     constructor(props) {
          super(props);
          this.state = { hasError: false };
     }


     render() {
      
          // log.warn("module-tastic");
          // log.trace('msg')
          // log.error('msg11')
          // log.info('msg1111')
          // log.debug('msg11qq11')
          return (
               <h1>test</h1>
               // <Logger>
               //      <CaseInitLayout componentName='CaseInitLayout' />
               // </Logger>
          )
     }
}

export default TestRest;