import React from 'react';
import { restbaseurl } from "../globals/constants";
import { RestDataSource } from "../utils/RestDataSource";

import queryString from 'query-string';


class EmploymentTemplate extends React.Component {

     constructor(props) {
          super(props);

          this.state = {
               designationCrt: true,
               empfromCrt: true,
               emptoCrt: true,
               salCrt: true,
               reptoCrt: true,
               leavereasonCrt: true,
               exitFormalCrt: true,
               finalSettleCrt: true,
               rehireCrt: true,
               docsCrt: true,

               designationCmt: '',
               empfromCmt: '',
               emptoCmt: '',
               salCmt: '',
               reptoCmt: '',
               leavereasonCmt: '',
               exitFormalCmt: '',
               finalSettleCmt: '',
               rehireCmt: '',
               docsCmt: '',

               verifiername: '',
               verifierdesig: '',
               verifiernum: ''
          }
     }

     componentWillMount = () => {
          this.init();
          this.fetchData();
     }

     init = () => {
          // const queryData = queryString.parse(this.props.location.search);
          this.queryDataDetails = queryString.parse(this.props.location.search);
          // this.verificationId = queryData.id;
          // this.candidateName = queryData.name;
          // this.organizationName = queryData.orgName;
          // this.employeeNumber = queryData.empNo;
          // this.designation = queryData.desig;
          // this.empFromPeriod = queryData.empFrom;
          // this.empToPeriod = queryData.empTo;
          // this.salary = queryData.salary;
          // this.reportingManagerName = queryData.reportingManName;
          // this.reasonForLeaving = queryData.reason;
          // this.replyToAddress = queryData.replyToAddress;
          // this.ccAddress = queryData.cc;
          // this.subject = queryData.subject;
          // this.textFile = null;
     }

     generateMailToLink = (e) => {
          document.getElementById('employmentTemplateTable').setAttribute('border', '1')
          document.getElementById('verifierDetailsTable').setAttribute('border', '1')

          let tableContent = document.getElementById('employmentTemplateTable').outerHTML;

          tableContent = 
`
####
Designation is correct:${this.state.designationCrt?'Yes':'No'}
Designation Comment:${this.state.designationCmt?this.state.designationCmt:'-'}
Employment Start Period is correct:${this.state.empfromCmt?'Yes':'No'}
Employment Start Period Comment:${this.state.empfromCmt?this.state.empfromCmt:'-'}
Employment End Period is correct:${this.state.emptoCrt?'Yes':'No'}
Employment End Period Comment:${this.state.emptoCmt?this.state.emptoCmt:'-'}
Salary is correct:${this.state.salCrt?'Yes':'No'}
Salary Comment:${this.state.salCmt?this.state.salCmt:'-'}
Reporting manager is correct:${this.state.reptoCmt?'Yes':'No'}
Reporting manager Comment:${this.state.reptoCmt?this.state.reptoCmt:'-'}
Reason for leaving is correct:${this.state.leavereasonCrt?'Yes':'No'}
Reason for leaving Comment:${this.state.leavereasonCmt?this.state.leavereasonCmt:'-'}
Exit formalities completed:${this.state.exitFormalCrt?'Yes':'No'}
Exit formalities Comment:${this.state.exitFormalCmt?this.state.exitFormalCmt:'-'}
Full and Final Settlement Completed:${this.state.finalSettleCrt?'Yes':'No'}
Full and Final Settlement Comment:${this.state.finalSettleCmt?this.state.finalSettleCmt:'-'}
Eligible for Rehire:${this.state.rehireCrt?'Yes':'No'}
Eligible for Rehire Comment:${this.state.rehireCmt?this.state.rehireCmt:'-'}
Attached documents(if any) are correct:${this.state.docsCrt?'Yes':'No'}
Attached documents Comment:${this.state.docsCmt?this.state.docsCmt:'-'}

Verifier Name:${this.state.verifiername?this.state.verifiername:'-'}
Verifier Designation:${this.state.verifierdesig?this.state.verifierdesig:'-'}
Verifier Contact No.:${this.state.verifiernum?this.state.verifiernum:'-'}
####
`
          let mailtoLink = `
          mailto:${this.replyToAddress}?cc=${this.ccAddress}&subject=${encodeURI(this.subject)}&body=${encodeURI(tableContent)}`;
          document.getElementById('employmentTemplateTable').removeAttribute('border')
          document.getElementById('verifierDetailsTable').removeAttribute('border')

          //generate eml file for download

          let fileContents =
               `To: ${this.replyToAddress}
CC:${this.ccAddress}
Subject: ${this.subject}
X-Unsent: 1
Content-Type: text/html

<html>
     <body>
          ${tableContent}
     </body>
</html>
`

          this.makeTextFile(fileContents);
          // e.target.setAttribute('href', this.textFile);

          e.target.setAttribute('href', mailtoLink);
     }
     makeTextFile = (text) => {
          var data = new Blob([text], { type: 'text/plain' });
          if (this.textFile !== null) {
               window.URL.revokeObjectURL(this.textFile);
          }
          this.textFile = window.URL.createObjectURL(data);
     };

     handleOnChange = (e) => {
          const name = e.target.getAttribute('name');
          const value = e.target.checked;

          this.setState({ [name]: value });
     }

     handleCommentChange = (e) => {
          const name = e.target.getAttribute('name');
          const value = e.target.value;
          this.setState({ [name]: value });
     }



     fetchData = (values) => {

          this.details = new RestDataSource(
               `${restbaseurl}/employment/obtainMailToDetails`
          );
          this.setState({ loading: true }, () => {
               this.details.GetData(
                    data => {

                         console.log("dataa", data)
                         if (data.status === true) {
                              this.verificationId = data.details.id===null?"-":data.details.id;
                              this.candidateName = data.details.name===null?"-":data.details.name;
                              this.organizationName = data.details.orgName===null?"-":data.details.orgName;
                              this.employeeNumber = data.details.empNo===null?"-":data.details.empNo;
                              this.designation = data.details.desig===null?"-":data.details.desig
                              this.empFromPeriod = data.details.empFrom===null?"-":data.details.empFrom;
                              this.empToPeriod = data.details.empTo===null?"-":data.details.empTo;
                              this.salary = data.details.salary===null?"-":data.details.salary;
                              this.reportingManagerName = data.details.reportingManName===null?"-":data.details.reportingManName;
                              this.reasonForLeaving = data.details.reason===null?"-":data.details.reason;
                              this.replyToAddress = data.details.replyToAddress===null?"-":data.details.replyToAddress;
                              this.ccAddress = data.details.cc===null?"-":data.details.cc;
                              this.subject = data.details.subject===null?"-":data.details.subject;
                         }
                         this.setState({ loading: false });
                    },
                    {
                         hash: this.queryDataDetails.hash,
                         id: this.queryDataDetails.id
                    },
                    "POST"
               );
          })



     }















     render = () => {

          console.log("queryData", this.queryDataDetails)
          // return (
          // 	<div>
          // 		<br /><br /><br /><br />
          // 		<h2>This Page is not ready yet. Sorry :)</h2>
          // 		<script>
          // 			window.location.href = "https://www.metapath.ai/"
          // 		</script>
          // 	</div>
          // );
          // }


          // render1 = () => {
          return (
               <React.Fragment>
                    <div className="container-fluid case-settings">
                         <div className="row" id="employmentTemplateTableWrapper">
                              <div className="col-12 mb20">
                                   <div className="sub-heading1">Verification for {this.candidateName} ( Employee code: {this.employeeNumber} )</div>
                                   <div className="clr10"></div>

                                   <div className="body2 black7">{this.organizationName}</div>
                                   <div className="clr20"></div>

                                   <div className="white-bg">
                                        <div className="clr20"></div>
                                        <div className="row">
                                             <div className="col-sm-12 commontbl response-table toggle-tbl">
                                                  <table className={`table backtrack-employment-verification btec-vid-${this.verificationId}`} id="employmentTemplateTable">
                                                       <thead>
                                                            <tr>
                                                                 <th>Details</th>
                                                                 <th>Candidate's Input</th>
                                                                 <th>Correctness</th>
                                                                 <th>Comments ( Optional )</th>
                                                            </tr>
                                                       </thead>
                                                       <tbody>
                                                            <tr>
                                                                 <td>Designation</td>
                                                                 <td>{this.designation}</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="designationCrt" checked={this.state.designationCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-designationCrt">{this.state.designationCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="designationCmt" value={this.state.designationCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-designationCmt">{this.state.designationCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Employment Period From</td>
                                                                 <td>{this.empFromPeriod}</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="empfromCrt" checked={this.state.empfromCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-empfromCrt">{this.state.empfromCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="empfromCmt" value={this.state.empfromCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-empfromCmt">{this.state.empfromCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Employment Period To</td>
                                                                 <td>{this.empToPeriod}</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="emptoCrt" checked={this.state.emptoCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-emptoCrt">{this.state.emptoCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="emptoCmt" value={this.state.emptoCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-emptoCmt">{this.state.emptoCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Salary</td>
                                                                 <td>{this.salary}</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="salCrt" checked={this.state.salCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-salCrt">{this.state.salCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="salCmt" value={this.state.salCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-salCmt">{this.state.salCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Reported to</td>
                                                                 <td>{this.reportingManagerName}</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="reptoCrt" checked={this.state.reptoCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-reptoCrt">{this.state.reptoCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="reptoCmt" value={this.state.reptoCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-reptoCmt">{this.state.reptoCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Reason for leaving</td>
                                                                 <td>{this.reasonForLeaving}</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="leavereasonCrt" checked={this.state.leavereasonCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-leavereasonCrt">{this.state.leavereasonCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="leavereasonCmt" value={this.state.leavereasonCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-leavereasonCmt">{this.state.leavereasonCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Exit Formalities Completed</td>
                                                                 <td>-</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="exitFormalCrt" checked={this.state.exitFormalCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-exitFormalCrt">{this.state.exitFormalCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="exitFormalCmt" value={this.state.exitFormalCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-exitFormalCmt">{this.state.exitFormalCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Full and Final Settlement is Completed</td>
                                                                 <td>-</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="finalSettleCrt" checked={this.state.finalSettleCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-finalSettleCrt">{this.state.finalSettleCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="finalSettleCmt" value={this.state.finalSettleCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-finalSettleCmt">{this.state.finalSettleCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Eligible to Re-hire</td>
                                                                 <td>-</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="rehireCrt" checked={this.state.rehireCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-rehireCrt">{this.state.rehireCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="rehireCmt" value={this.state.rehireCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-rehireCmt">{this.state.rehireCmt}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Attached Documents(if any) are valid</td>
                                                                 <td>-</td>
                                                                 <td>
                                                                      <div className="switch-group">
                                                                           <label className="switch">
                                                                                <input type="checkbox" style={{ display: 'none' }} name="docsCrt" checked={this.state.docsCrt} onChange={this.handleOnChange} />
                                                                                <span>
                                                                                     <strong></strong>
                                                                                </span>
                                                                           </label>
                                                                           <span className="hide btec-docsCrt">{this.state.docsCrt ? 'yes' : 'no'}</span>
                                                                      </div>

                                                                 </td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="docsCmt" value={this.state.docsCmt} placeholder="Enter Comments here" style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-docsCmt">{this.state.docsCmt}</span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>

                                   </div>

                              </div>
                              <div className="col-12">
                                   <div className="sub-heading1">Your details</div>

                                   <div className="clr20"></div>

                                   <div className="white-bg">
                                        <div className="row">
                                             <div className="col-12 col-md-6">
                                                  <table className={`table backtrack-employment-verification btec-vid-${this.verificationId}`} id="verifierDetailsTable" style={{ marginBottom: '0px' }}>
                                                       <tbody>
                                                            <tr>
                                                                 <td>Name</td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="verifiername" value={this.state.verifiername} style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-verifiername">{this.state.verifiername}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Your designation</td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="verifierdesig" value={this.state.verifierdesig} style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-verifierdesig">{this.state.verifierdesig}</span>
                                                                 </td>
                                                            </tr>
                                                            <tr>
                                                                 <td>Contact No</td>
                                                                 <td>
                                                                      <div className="form-group">
                                                                           <input type="text" className="form-control block" name="verifiernum" value={this.state.verifiernum} style={{ display: 'none' }} onChange={this.handleCommentChange} />
                                                                      </div>
                                                                      <span className="hide btec-verifiernum">{this.state.verifiernum}</span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div className="clr20"></div>

                         </div>
                         <div className="col-12">
                              <div className="text-center">
                                   <a href="mailto" className="btn btn_primary text-white" onClick={this.generateMailToLink}>SEND MAIL</a>
                              </div>
                         </div>
                         <div className="clr20"></div>
                         <div className="clr20"></div>
                    </div>
                    {/* <div id="employmentTemplateTable">
                         <table className={`backtrack-employment-verification btec-vid-${this.verificationId}`} border="1" style={{ borderCollapse: 'collapse' }}>
                              <tbody>
                                 
                                   <tr>
                                        <td style={{ padding: '5px' }} colSpan="2">Verifier Name</td><td style={{ padding: '5px' }} colSpan="3">
                                             <input style={{ display: 'none' }} type="text" name="verifiername" onChange={this.handleOnChange} />
                                             <span className="hide btec-verifiername">{this.state.verifiername}</span>
                                        </td>
                                   </tr>
                                   <tr>
                                        <td style={{ padding: '5px' }} colSpan="2">Designation</td><td style={{ padding: '5px' }} colSpan="3">
                                             <input style={{ display: 'none' }} type="text" name="verifierdesig" onChange={this.handleOnChange} />
                                             <span className="hide btec-verifierdesig">{this.state.verifierdesig}</span>
                                        </td>
                                   </tr>
                                   <tr>
                                        <td style={{ padding: '5px' }} colSpan="2">Contact No</td><td style={{ padding: '5px' }} colSpan="3">
                                             <input style={{ display: 'none' }} type="text" name="verifiernum" onChange={this.handleOnChange} />
                                             <span className="hide btec-verifiernum">{this.state.verifiernum}</span>
                                        </td>
                                   </tr>
                                   <tr>
                                        <td style={{ padding: '5px' }} colSpan="5">&nbsp;</td>
                                   </tr>
                              </tbody>
                         </table>
                    </div>
                    <a href="mailto:" className="btn btn_primary" onClick={this.generateMailToLink}>Submit</a> */}
               </React.Fragment>
          );
     }
}

export default EmploymentTemplate;
