import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { RestDataSource } from '../utils/RestDataSource';
import { restbaseurl } from '../globals/constants';
import { BTInputField } from '../globals/uicomps/BTInputField';

class AddressDetailsModal extends Component {
     constructor(props) {
          super(props)
          this.state = {
               addressLineOne: '',
               city: '',
               state: '',
               country: '',
               pincode: '',
               errorMsg: ''
          }
     }

     handleChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     saveAddressDetails = () => {
          const { addressLineOne, city, state, country, pincode } = this.state
          this.caseDetails = new RestDataSource(`${restbaseurl}/drug/saveAddressDetails`);
          if (addressLineOne && city && state && country && pincode) {
               this.setState({ loading: true }, () => {
                    this.caseDetails.GetData(
                         respData => {
                              if (respData.status === true) {
                                   this.setState({
                                        loading: false
                                   })
                                   this.props.successCallback();
                                   this.props.hideModal();
                              }
                         },
                         {
                              addressLineOne: addressLineOne, city: city, state: state, country: country, pincode: pincode, id: this.props.id
                         },
                         "POST"
                    );
               });
          } else {
               this.setState({
                    errorMsg: 'Please fill all the details'
               })
          }
     }

     render = () => {
          const { addressLineOne, city, state, country, pincode, errorMsg } = this.state
          const { t } = this.props
          return (
               <div>
                    <div className="row">
                         <div className="col-sm-6">
                              <div className="form-group">
                                   <BTInputField label={t('addressLineOne')} type="text" id="addressLineOne" value={addressLineOne} handleChange={this.handleChange} required={true} />
                              </div>
                         </div>
                         <div className="col-sm-6">
                              <div className="form-group">
                                   <BTInputField label={t('city')} type="text" id="city" value={city} handleChange={this.handleChange} required={true} />
                              </div>
                         </div>
                         <div className="col-sm-6">
                              <div className="form-group">
                                   <BTInputField label={t('state')} type="text" id="state" value={state} handleChange={this.handleChange} required={true} />
                              </div>
                         </div>
                         <div className="col-sm-6">
                              <div className="form-group">
                                   <BTInputField label={t('country')} type="text" id="country" value={country} handleChange={this.handleChange} required={true} />
                              </div>
                         </div>
                         <div className="col-sm-6">
                              <div className="form-group">
                                   <BTInputField label={t('pincode')} type="text" id="pincode" value={pincode} handleChange={this.handleChange} required={true} />
                              </div>
                         </div>
                    </div>
                    {errorMsg.length > 0 ?
                         <div className="showerrormsg">
                              <span>{errorMsg}</span>
                         </div> : ""
                    }
                    <div className="text-center">
                         <span className="btn btn_primary" onClick={this.saveAddressDetails} >Submit</span>
                         <span className="btn btn_secondary ml-2" onClick={this.props.hideModal} >Cancel</span>
                    </div>
               </div>
          )
     }
}
export default withTranslation('translations')(AddressDetailsModal);