import React, { Component } from 'react';
import DialogModal from '../globals/uicomps/DialogModal';
import ChangePassword from '../clientAdmin/administration/ChangePassword';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import { getValue } from '../globals/utils';
import ls from 'local-storage';
import DocumentViewerHeader from './DocumentViewerHeader';
import DocumentSearch from './DocumentSearch';

class DocumentViewerBaseLayout extends Component {
     constructor(props) {
          super(props);
          this.state = {
               snackbar: false,
               snackmsg: '',
               showChangePasswordModal: false,
          };
          this.searchText = '';
     }

     handleChangePassword = () => {
          this.setState({
               showChangePasswordModal: true
          })
     }

     cbChangePassword = () => {
          this.setState({
               snackbar: true,
               snackmsg: 'Updated Successfully',
               showChangePasswordModal: false
          })
     }

     hideModal = () => {
          this.setState({
               showChangePasswordModal: false
          })
     }

     checkIfLoggedIn = () => {
          const lLoggedIn = getValue('isloggedin');
          if (lLoggedIn) {
               const { history } = this.props;
               const role = getValue('roleCode');
               if (role !== 'documentviewer') {
                    history.push('/');
               }
          } else {
               ls.clear();
               window.location.href = '/home/login';
          }
     }

     render = () => {
          let action = '/user/changePassword';
          return (
               <React.Fragment>
                    <DocumentViewerHeader handleChangePassword={this.handleChangePassword} />
                    {this.state.snackbar ? <BTSnackbar msg={this.state.snackmsg} status="success" time="5000" onHide={this.hideSnackbar} /> : ""}

                    <DocumentSearch />

                    <div className="modal-dialog ">
                         <DialogModal
                              show={this.state.showChangePasswordModal}
                              header="Change Password"
                              successButtonText="Save"
                              failureButtonText="Cancel"
                              onHide={this.hideModal}
                              className="reset-popup"
                              showFooter="false"
                         >
                              <ChangePassword
                                   cbChangePassword={this.cbChangePassword}
                                   onHide={this.hideModal}
                                   action={action}
                              />
                         </DialogModal>
                    </div>
               </React.Fragment>
          );
     }
}

export default DocumentViewerBaseLayout;
