import React, { Component } from 'react';
import ShowVendorDetails from './ShowVendorDetails';
import EditVendor from './EditVendor';

export default class VendorProfileLayout extends Component {

     constructor(props) {
          super(props);
          this.state = {
               sidePane: ""
          }
     }

     render = () => {
          let sidePane;

          if (this.props.sidePanel === "create") {
               sidePane = <EditVendor onCreate={this.props.onCreate} reRender={this.props.reRender} isEdit={false} closeSidePanel={this.props.closeSidePanel}
               selectedVendorId={null}
               />
          }
          if (this.props.sidePanel === "edit") {
               sidePane = <EditVendor showDisplayPage={this.props.showDisplayPage} showVendor={this.props.showVendor}
               closeSidePanel={this.props.closeSidePanel} selectedVendorId={this.props.selectedVendorId} isEdit={true} />
          }
          if (this.props.sidePanel === "show") {
               sidePane = <ShowVendorDetails selectedVendorId={this.props.selectedVendorId} editVendor={this.props.editVendor} cbIsVendorEdited={this.props.cbIsVendorEdited}
               editUserCreate={this.props.editUserCreate} showManagementLayout={this.props.showManagementLayout} />
          }
          return (
               <div className="tb__tab-content">
                    {sidePane}
               </div>
          );
     }
}