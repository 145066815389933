import React, { Component } from 'react'
import { withTranslation } from "react-i18next";
import UserInfo from '../../../../models/UserInfo';

class VendorCostingTableRow extends Component {
     constructor(props) {
          super(props);
          this.state = {
               caseRowloading: false,
               dummy: false,
               vendorId: '',
               showConfirmationModal: false,
               subheading: '',
               unAssign: false
          }
          this.userId = UserInfo.getUserid();
     }

     render() {
          const { checkCode, checkDetailsList, selectedCaseIdList, totalCount, filterObj, togglePaid,
               handleSelectAll, handleSelectChange, openAddCostModal } = this.props;
          let tableHeaderList = [];
          let rows = [];
          let caseIdList = [];
          let showPaidCases = filterObj.isPaid;
          switch (checkCode) {
               case 'cibil':
                    tableHeaderList = ['S No', 'Reference Number', 'Completed On', 'Name', 'Client Name', 'Vendor Name', 'Cost', 'Status'];
                    rows = checkDetailsList.map((data, index) => {
                         caseIdList.push(data.id)
                         return (<tr className="case-row" >
                              {showPaidCases ? null : <td>
                                   <label className="container_Checkbox margin0">
                                        <input
                                             type="checkbox"
                                             className="checkbox"
                                             id="All"
                                             checked={selectedCaseIdList.includes(data.id)}
                                             onChange={() => handleSelectChange(data.id)}
                                        />
                                        <span className="checkmark"></span>
                                   </label>
                              </td>}
                              <td>{index + 1}</td>
                              <td>
                                   <div className="straight_td title-hover " >
                                        {data.caseNumber}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_td" >{data.completedDate}</div>
                              </td>
                              <td>
                                   <div className="straight_name" title={data.name} >{data.name}</div>
                              </td>
                              <td>
                                   <div className="straight_name" title={data.clientName} >
                                        {data.clientName}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_name" title={data.vendorName} >
                                        {data.vendorName}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_td" >
                                        {data.cost}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_td" >
                                        {data.status}
                                   </div>
                              </td>
                         </tr>)
                    })
                    break;
               case 'drug':
                    // tableHeaderList = ['S No', 'Reference Number', 'Completed On', 'Name', 'Check Type', 'Status'];
                    tableHeaderList = ['S No', 'Reference Number', 'Completed On', 'Name', 'Client Name', 'Vendor Name', 'Cost', 'Status'];
                    rows = checkDetailsList.map((data, index) => {
                         caseIdList.push(data.id)
                         return (<tr className="case-row" >
                              {showPaidCases ? null : <td>
                                   <label className="container_Checkbox margin0">
                                        <input
                                             type="checkbox"
                                             className="checkbox"
                                             id="All"
                                             checked={selectedCaseIdList.includes(data.id)}
                                             onChange={() => handleSelectChange(data.id)}
                                        />
                                        <span className="checkmark"></span>
                                   </label>
                              </td>}
                              <td>{index + 1}</td>
                              <td>
                                   <div className="straight_td title-hover " >
                                        {data.caseNumber}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_td" >{data.completedDate}</div>
                              </td>
                              <td>
                                   <div className="straight_name" title={data.name} >{data.name}</div>
                              </td>
                              <td>
                                   <div className="straight_name" title={data.clientName} >
                                        {data.clientName}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_name" title={data.vendorName} >
                                        {data.vendorName}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_td" >
                                        {data.cost}
                                   </div>
                              </td>
                              <td>
                                   <div className="straight_td" >
                                        {data.status}
                                   </div>
                              </td>
                         </tr>)
                    })
                    break;
               case 'residence':
                    tableHeaderList = ['S No', 'Reference Number', 'Completed On', 'Name', 'Client Name', 'Vendor Name', 'Stage', 'Cost', 'Status'];
                    rows = checkDetailsList.map((data, index) => {
                         if (filterObj.stage === data.stage || filterObj.stage === '') {
                              caseIdList.push(data.id);
                              return (<tr className="case-row" >
                                   {showPaidCases ? null : <td>
                                        <label className="container_Checkbox margin0">
                                             <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  id="All"
                                                  checked={selectedCaseIdList.includes(data.id)}
                                                  onChange={() => handleSelectChange(data.id)}
                                             />
                                             <span className="checkmark"></span>
                                        </label>
                                   </td>}
                                   <td>{index + 1}</td>
                                   <td>
                                        <div className="straight_td title-hover " >
                                             {data.caseNumber}
                                        </div>
                                   </td>
                                   <td>
                                        <div className="straight_td" >{data.completedDate}</div>
                                   </td>
                                   <td>
                                        <div className="straight_name" title={data.name} >{data.name}</div>
                                   </td>
                                   <td>
                                        <div className="straight_name" title={data.clientName} >
                                             {data.clientName}
                                        </div>
                                   </td>
                                   <td>
                                        <div className="straight_name" title={data.vendorName} >
                                             {data.vendorName}
                                        </div>
                                   </td>
                                   <td>
                                        <div className="straight_td" >
                                             {data.stage}
                                        </div>
                                   </td>
                                   <td>
                                        <div className="straight_td" >
                                             {data.cost}
                                        </div>
                                   </td>
                                   <td>
                                        <div className="straight_td" >
                                             {data.status}
                                        </div>
                                   </td>
                              </tr>)
                         } else {
                              return null;
                         }
                    })
                    break;
               default:
                    break;
          }
          return (
               <React.Fragment>
                    <div className={`commontbl case-tbl  `} >

                         <div className="right mb10">
                              <div className="form-group mt0 paid-status mt5 left mr20" >
                                   <div className="switch-group companysuspicious" >
                                        <label className="switch">
                                             <input type="checkbox" style={{ display: 'none' }} name="isPaid" checked={showPaidCases} onChange={() => togglePaid('isPaid', !showPaidCases)} />
                                             <span>
                                                  <strong></strong>
                                             </span>
                                        </label>
                                   </div>
                              </div>
                              <div
                                   className={`btn btn_primary mr10  ${selectedCaseIdList.length ? '' : 'disabled_btn'}`}
                                   onClick={() => openAddCostModal(true)}
                              >Add Cost</div>
                              <div
                                   className={`btn btn_primary ${selectedCaseIdList.length ? '' : 'disabled_btn'}`}
                                   onClick={() => openAddCostModal(false)}
                              >Mark as Paid</div>
                         </div>
                         <div className='clr20'></div>
                         {(rows.length > 0 ) ?
                              <>
                                   <table className="table table-hover completed_table tableheaderfont vendor_cost_table">
                                        <thead>
                                             <tr>
                                                  {showPaidCases ? null : <th>
                                                       <label className="container_Checkbox margin0">
                                                            {"Select all"}
                                                            <input
                                                                 type="checkbox"
                                                                 className="checkbox"
                                                                 id="All"
                                                                 checked={selectedCaseIdList.length === totalCount}
                                                                 onChange={() => handleSelectAll(selectedCaseIdList.length === totalCount, caseIdList)}
                                                            />
                                                            <span className="checkmark"></span>
                                                       </label>
                                                  </th>}
                                                  {tableHeaderList.map((data) => {
                                                       return <th>{data}</th>
                                                  })}
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {rows}
                                        </tbody>
                                   </table>
                              </>
                              : <h6 className="text-center">No Data Found !! </h6>}
                    </div>

               </React.Fragment>
          )
     }
}
export default withTranslation("translations")(VendorCostingTableRow);

