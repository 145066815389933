import React, { Component } from 'react';
import { setValue, getValue } from '../globals/utils';

class ChooseRole extends Component {
     reDirect = (path, code, rolePriority, roleId) => {
          setValue('rolePath', path);
          setValue('roleCode', code);
          setValue('rolePriority', rolePriority)
          setValue('roleId', roleId)
          document.location.href = `/${path}`;
     }

     checkIfLoggedIn = () => {
          let lLoggedIn = getValue('isloggedin');
          if (lLoggedIn) {
          } else {
               window.location.href = '/home';
          }
     }

     render = () => {
          this.checkIfLoggedIn();
          setValue('rolePath', '')
          setValue('rolePriority', '')
          setValue('clientId', null)
          setValue('roleId', '');
          // if (getValue('rolePriority') === getValue('roleMap')['admin']) {
          //      roleName = 'Admin'
          // } else if (getValue('rolePriority') === getValue('roleMap')['chp']) {
          //      roleName = 'CHP'
          // } else if (getValue('rolePriority') === getValue('roleMap')['operationshead']) {
          //      roleName = 'Operations Head'
          // }  else if (getValue('rolePriority') === getValue('roleMap')['de']) {
          //      roleName = 'Data Entry'
          // }
          const pathMap = {
               'admin': 'adminDashboard',
               'operationshead': 'adminDashboard',
               'chp': 'adminDashboard',
               'operations': 'operations',
               'de': 'dataEntry',
               'ca': 'dashboard',
               'hr': 'dashboard',
               'reportviewer': 'reportViewerDashboard',
               'documentviewer': 'documentViewerDashboard',
               'qc': 'qcDashboard'
          }
          const roleCodeList = getValue('roleCodeList');
          const roleList = getValue('roleList');
          const roleListDetails = roleList.map(data => {
               if (roleCodeList.includes(data.code)) {
                    return (
                         <div className='user-card' onClick={() => this.reDirect(pathMap[data.code], data.code, data.priority, data.id)} key={data.code}>
                              {data.code === 'Operations' ? <img src="/images/icons/icon-user-management.svg" alt="Operations" /> :
                                   <img src="/images/icons/icon-user-dark.svg" alt="user" />}
                              <div className='clr20' />
                              <h6>{data.name}</h6>
                              <div className='sub-heading2'>Choose</div>
                         </div>
                    )
               }
               else {
                    return null
               }
          })
          return (
               <>
                    <div className='choose_role_wrap'>
                         <div className='choose_role_container'>
                              <div className='companylogoclass'>
                                   <img src="/images/logo.svg" alt="metapath" width='200px' />
                              </div>
                              <h6 className='text-center choose_role_sub'>
                                   Choose a Role
                              </h6>
                              <div className='choose_role_wrapper'>
                                   {roleListDetails}
                              </div>
                              {/* <div className='user-card' onClick={() => this.reDirect('adminDashboard')}>
                                   <img src="/images/icons/icon-user-dark.svg" alt="user" />
                                   <div className='clr20' />
                                   <h6>{roleName}</h6>
                                   <div className='sub-heading2'>Choose</div>
                              </div>
                              <div className='user-card' onClick={() => this.reDirect('operations')}>
                                   <img src="/images/icons/icon-user-management.svg" alt="Operations" />
                                   <div className='clr20' />
                                   <h6>Operations</h6>
                                   <div className='sub-heading2'>Choose</div>
                              </div> */}
                         </div>
                    </div>
               </>
          );
     }
}

export default ChooseRole;