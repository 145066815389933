import React, { Component } from 'react';
import { ErrorMessage } from 'formik';
import { BTFieldValError } from './BTFieldValError';

export class BTInputField extends Component {
     render = () => {
          let isFieldEmpty = !!!this.props.value;
          const { className } = this.props;
          return (

               <div className={`form-group ${className ? className : ''}`}>
                    <label className="body2">{this.props.label}
                         {
                              !this.props.required ? <div className="optional">&nbsp;( optional )</div> : null
                         }
                    </label>
                    {/* {
                         this.props.required ? <div className="error right">*</div> : null
                    } */}
                    <input type={this.props.type === undefined ? 'text' : this.props.type}
                         min={this.props.min} max={this.props.max}
                         readOnly={this.props.readonly}
                         disabled={this.props.disabled}
                         name={this.props.id} id={this.props.id} className={`form-control ${isFieldEmpty ? 'input-empty' : ''}`}
                         onBlur={this.props.handleBlur}
                         placeholder={this.props.placeholder ? this.props.placeholder : 'Enter here'}
                         value={this.props.value} onChange={this.props.handleChange} />

                    <ErrorMessage name={this.props.id} component={BTFieldValError} />
               </div>


          );
     }
}