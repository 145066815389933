class UserInfo {
	constructor() {
		this.ses_username = '';
		this.ses_name = '';
		this.ses_role = '';
		this.ses_userid = '';
		this.ses_auth = false;
	}

	static getName = () => this.ses_name;

	static getUsername = () => this.ses_username;

	static getUserid = () => this.ses_userid;
	static getUserId = () => this.ses_userid;

	static isAuth = () => {
		console.log("Setting auth to true", this.ses_auth);
		return this.ses_auth;
	}

	static setUserDetail = (inp, alreadyLoggedIn) => {
		console.log("alreadyLoggedIn **", alreadyLoggedIn )
		this.ses_username = inp.username;
		this.ses_name = inp.extradata.name;
		this.ses_role = inp.extradata.role;
		this.ses_userid = inp.extradata.userid;
		this.ses_auth = alreadyLoggedIn;
		console.log('inp name', this.ses_username, this.ses_name, this.ses_role, this.ses_userid, this.ses_applicantid, this.ses_auth);
	}

	static setAuth = () =>{
		this.ses_auth = true;
	}

	static printInfo = () => `${this.ses_username},${this.ses_name},${this.ses_role},${this.ses_userid},${this.ses_auth}`;
}
export default UserInfo;
