import React, { Component } from 'react'
import * as yup from 'yup';
import { Formik } from 'formik';
import { BTInputField } from '../../globals/uicomps/BTInputField';
import { withTranslation } from 'react-i18next';

class ForgotPassword extends Component {
     constructor(props) {
          super(props)

          this.state = {
               userName: this.props.userName ? this.props.userName : '', mobileNumber: '', toLogin: false, loginDetails: ""
          }
          this.schema = yup.object().shape({
               userName: yup.string().required("User name is required"),
               mobileNumber: yup.string().required('Mobile Number is required')
          });
     }

     submitDetails = (values) => {
          this.props.forwardToOtp(values.userName, values.mobileNumber)
     }


     render() {
          const { t } = this.props;
          return (
               <React.Fragment>
                    <div className="sub-heading1 text-center">{t('Enter your details')}</div>
                    <div className="clr20"></div>

                    <Formik validationSchema={this.schema} onSubmit={this.submitDetails} initialValues={this.state}>
                         {
                              ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                   <form onSubmit={handleSubmit} noValidate >

                                        <BTInputField label={t('Username')} id="userName" handleBlur={handleBlur}
                                             handleChange={handleChange} value={values.userName} required={true} />

                                        <BTInputField type="text" label={t('Mobile number')} id="mobileNumber" handleBlur={handleBlur}
                                             handleChange={handleChange} value={values.mobileNumber} required={true} />

                                        <input className="btn btn_primary block" type="submit"
                                             value="Continue" disabled={!(values.userName && values.mobileNumber)} ></input>

                                        <div className="forgetlink body2 right mt10 pointer" onClick={() => { this.props.changeStepValue("login") }}>{t('Back to Login')}</div>

                                   </form>
                              )
                         }
                    </Formik>
               </React.Fragment>
          )
     }
}
export default withTranslation('translations')(ForgotPassword);

