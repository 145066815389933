import React from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../../../globals/constants';
import { RestDataSource } from '../../../utils/RestDataSource';
import CaseCard from './CaseCard';
import ConfirmationModal from '../../../globals/uicomps/ConfirmationModal';
import BTSnackbar from '../../../globals/uicomps/BTSnackbar';
import { BTInputField } from '../../../globals/uicomps/BTInputField';
// import AsyncSelect from "react-select/async";
import Select from "react-select";
import { LoadingSpinner } from '../../../utils/LoadingSpinner';
import UserInfo from '../../../models/UserInfo';
import { setValue, getValue } from '../../../globals/utils';

class CaseSearch extends React.Component {
     constructor(props) {
          super(props);
          const { isAdminDashboard } = this.props;
          if (isAdminDashboard) {
               setValue("clientId", null)
          }
          this.state = {
               caseList: [],
               loading: false,
               showDeleteConfirm: false,
               deleteCaseId: null,
               role: getValue('roleCode'),
               clientName: '',
               showDetails: false
          };
          this.searchText = '';
          this.comments = '';
          this.userid = UserInfo.getUserid()
          this.clientId = getValue("clientId");
          this.rolePriority = getValue("rolePriority");
          this.clientList = [];
          this.selectedClient = [];
          this.clientNameList = [];
     }


     componentDidMount() {
          const { isAdminDashboard } = this.props;
          if (isAdminDashboard) {
               this.searchClientName();
          } else {
               this.searchClientName();
               this.updateCaseList(true, '');
          }
     }

     searchClientName = () => {
          this.clientDetails = new RestDataSource(
               `${restbaseurl}/user/clientList`
          );
          this.setState({ loading: false }, () => {
               this.clientDetails.GetData(
                    data => {
                         this.updateTable = false;
                         if (data.status === true) {
                              let clientList = data.data.clientList;
                              let allClientsObj = { clientId: null, clientName: 'All Clients' }
                              clientList.unshift(allClientsObj)
                              this.clientList = clientList.map(data => { return { label: data.clientName, id: data.clientId } });
                              this.clientNameList = clientList.map(data => { return data.clientName });
                              this.setState({
                                   details: data.data, loading: false
                              });
                         }
                    },
                    { userId: this.userid, roleId: getValue('roleId') },
                    "POST"
               );
          });
     }


     handleSearchInput = (e) => {
          this.searchText = e.target.value;
          if (this.searchText.length > 2 || this.searchText.length === 0) {
               console.log('%csearchText=' + this.searchText, 'color:green');
               this.updateCaseList();
          }
     }

     handleChange = (e) => {
          this.comments = e.target.value;
     }

     updateCaseList = (loading = false, searchText = this.searchText) => {
          this.caseListReq = new RestDataSource(`${restbaseurl}/applicantCase/obtainCaseListCaseManagement`);
          this.setState({ loading: loading }, () => {
               this.caseListReq.GetData(
                    response => {
                         this.setState({ caseList: response.caseList, loading: false, showDetails: true });
                    },
                    { userId: this.userid, clientId: getValue("clientId"), searchText: searchText, roleId: getValue('roleId') },
                    "POST"
               );
          });
     }

     cbDeleteCase = (caseId) => {
          console.log("%ccbDeleteCase=" + caseId, 'color:red');
          this.setState({ showDeleteConfirm: true, deleteCaseId: caseId });
     }

     deleteApplicantCase = (caseId) => {
          this.caseListReq = new RestDataSource(`${restbaseurl}/applicantCase/caseDeleteCaseManagement`);
          this.setState({ loading: true }, () => {
               this.caseListReq.GetData(
                    response => {
                         if (response.deleteStatus === true) {
                              this.setState({ showSnackMessage: true, snackMessage: 'Deleted successfully', snackStatus: 'success', loading: false }, this.updateCaseList);
                         } else {
                              this.setState({ showSnackMessage: true, snackMessage: 'Deletion failed', snackStatus: 'danger', loading: false });
                         }
                    },
                    { id: caseId, comments: this.comments, userId: this.userid },
                    "POST"
               );
          });
     }

     generateCaseCardList = () => {
          this.caseCardList = this.state.caseList.map((caseObj) => {
               const clientName = caseObj.clientName;
               return <CaseCard caseObj={caseObj} cbViewCase={this.props.cbViewCase} cbDeleteCase={this.cbDeleteCase} cbReportReset={this.cbReportReset} key={caseObj.id} isClientAccessable={this.rolePriority === 1 ? true : this.clientNameList.includes(clientName)} />
          });
     }

     cbDeleteConfirmSuccess = () => {
          let deleteCaseId = this.state.deleteCaseId;
          this.setState({ showDeleteConfirm: false, deleteCaseId: null });
          this.deleteApplicantCase(deleteCaseId);
     }

     cbDeleteConfirmFailure = () => {
          this.setState({ showDeleteConfirm: false, deleteCaseId: null });
     }

     hideSnackbar = () => {
          this.setState({ showSnackMessage: false, snackMessage: '', snackStatus: '' });
     }

     resetApplicantCaseReport = (caseId) => {
          this.caseListReq = new RestDataSource(`${restbaseurl}/applicantCase/resetReportCaseManagement`);
          this.setState({ loading: true }, () => {
               this.caseListReq.GetData(
                    response => {
                         if (response.reportResetStatus === true) {
                              this.setState({ showSnackMessage: true, snackMessage: 'Report reset successfully', snackStatus: 'success', loading: false }, this.updateCaseList);
                         } else {
                              this.setState({ showSnackMessage: true, snackMessage: 'Report reset failed', snackStatus: 'danger', loading: false });
                         }
                    },
                    { id: caseId },
                    "POST"
               );
          });
     }

     cbReportReset = (caseId) => {
          console.log("%ccbReportResetCase=" + caseId, 'color:red');
          this.setState({ showReportResetConfirm: true, reportResetCaseId: caseId });
     }

     cbReportResetConfirmSuccess = () => {
          let reportResetCaseId = this.state.reportResetCaseId;
          this.setState({ showReportResetConfirm: false, reportResetCaseId: null });
          this.resetApplicantCaseReport(reportResetCaseId);
     }

     cbReportResetConfirmFailure = () => {
          this.setState({ showReportResetConfirm: false, reportResetCaseId: null });
     }


     handleClientChange = (event) => {
          this.clientId = event.id;
          setValue("clientId", this.clientId);
          this.selectedClient = { value: event.id, label: event.label }
          this.setState({ clientName: event.label })
     }


     render = () => {
          const { t } = this.props;
          this.generateCaseCardList();
          const { clientName, showDetails, loading } = this.state;
          let isAdminOrChp = getValue('isAdminOrChp');
          const { isAdminDashboard } = this.props;
          return (
               loading ? <LoadingSpinner /> : <React.Fragment>
                    {
                         this.state.showSnackMessage ? <BTSnackbar msg={this.state.snackMessage} status={this.state.snackStatus} time="4000" onHide={this.hideSnackbar} /> : ''
                    }
                    <ConfirmationModal show={this.state.showDeleteConfirm} heading="Are you sure?" subheading="Confirm to delete the case." successButtonText="Delete" failureButtonText="Cancel" successCallback={this.cbDeleteConfirmSuccess} failureCallback={this.cbDeleteConfirmFailure} closeCallback={this.cbDeleteConfirmFailure} >
                         <div className="row clone_pack">
                              <div className="col-12 ">
                                   <BTInputField label={t("Comments")} type="text" id="comments" name="comments" handleChange={this.handleChange} required={true} />
                              </div>
                         </div>
                    </ConfirmationModal>
                    <ConfirmationModal show={this.state.showReportResetConfirm} heading="Are you sure?" subheading="Confirm to reset the report." successButtonText="Reset" failureButtonText="Cancel" successCallback={this.cbReportResetConfirmSuccess} failureCallback={this.cbReportResetConfirmFailure} closeCallback={this.cbReportResetConfirmFailure}  ></ConfirmationModal>
                    {(isAdminOrChp && isAdminDashboard) ?
                         <> <div className='col-4 pad0'>
                              <div className="form-group mb0" style={{ zIndex: "1000" }}>
                                   <label className='body2'>Choose a Client</label>
                                   {/* <AsyncSelect
                                        id="clientId"
                                        placeholder="Type to search"
                                        style={{ border: "2px solid red" }}
                                        loadOptions={this.fetchClientList}
                                        onChange={this.handleClientChange}
                                        value={selectedClient}
                                   /> */}
                                   <Select
                                        id="clientList"
                                        placeholder="Search clients"
                                        style={{ border: "2px solid red", zIndex: "1000" }}
                                        options={this.clientList}
                                        value={this.selectedClient}
                                        onChange={this.handleClientChange}
                                   ></Select>
                              </div>
                         </div>
                              <div className="col-4 " style={{ marginTop: "28px" }}>
                                   <span
                                        className={`btn btn_primary ml20 ${clientName ? '' : 'disabled_btn'} `}
                                        onClick={() => this.updateCaseList(true, '')}
                                   > Get details</span>
                              </div>
                              <div className='clr20'></div>
                         </>
                         : null}

                    <div className="clr"></div>
                    {showDetails ?
                         <>
                              <div className='clr10'></div>
                              <div className="row">
                                   <div className="col-md-3 ml-auto">
                                        <div className="form-group search-filter mb0 ">
                                             <input type="text" className="form-control" placeholder={t('adminlayout.tabs.casemanagement.search.placeholder')}
                                                  onChange={this.handleSearchInput} />
                                             <span className="bt-icon bt-search"></span>
                                        </div>
                                   </div>
                              </div>
                              <div className='clr5'></div>
                              <div className="row">
                                   {this.caseCardList.length ? this.caseCardList : <h6 className='pad15'>No Data Found</h6>}
                              </div></> : null}

               </React.Fragment>
          )
     }
}

export default withTranslation('translations')(CaseSearch);