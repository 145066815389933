/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { Component } from 'react';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
// import AdminHeader from "./AdminHeader"
import ClientRow from './ClientRow';
import { restbaseurl } from '../../globals/constants';
import { RestDataSource } from '../../utils/RestDataSource';
import UserInfo from '../../models/UserInfo';
import ls from 'local-storage';
import { getValue } from '../../globals/utils';
import ReportViewerHeader from '../ReportViewerHeader';

export default class ClientListLayout extends Component {
     constructor(props) {
          super(props);
          this.state = {
               caseList: [],
               searchBoxValue: '',
          };
          this.userid = UserInfo.getUserid()
     }

     componentDidMount = () => {
          this.sendRequest();
     }

     sendRequest = () => {
          const searchValue = document.getElementById('search').value;
          this.setState({
               searchBoxValue: searchValue,
          });
          this.caseDetails = new RestDataSource(`${restbaseurl}/user/clientList`);
          this.setState({ loading: true }, () => {
               this.caseDetails.GetData(
                    (data) => {
                         if (data.status === true) {
                              this.setState({
                                   caseList: data.data.clientList,
                                   loading: false,
                              });
                         }
                    },
                    {
                         userId: this.userid,
                         // isCompleted:includeCompleted,
                         search: searchValue,roleId:getValue('roleId')
                    },
                    'POST',
               );
          });
     }

     searchClient = () => {
          const searchValue = document.getElementById('search').value;
          this.setState({
               searchBoxValue: searchValue,
          });
     }

     checkIfLoggedIn = () => {
          const lLoggedIn = getValue('isloggedin');
          if (lLoggedIn) {
               const { history } = this.props;
               const role = getValue('roleCode');
               if (role !== 'reportviewer') {
                    history.push('/');
               }
          } else {
               ls.clear();
               window.location.href = '/home/login';
          }
     }

     render = () => {
          let count = 0;
          let isloaded = 0;
          this.checkIfLoggedIn();
          let displayCaseList = '';
          const { searchBoxValue, loading, caseList } = this.state;
          const { history } = this.props;
          const invalid = /[°"§%()[\]*{}=\\?´`'#<>|,;.:+]+/g;
          let searchValue = searchBoxValue;
          searchValue = searchValue.replace(invalid, '');
          displayCaseList = caseList.map((caseObj) => {
               isloaded = 1;
               if (caseObj.clientName.toLowerCase().search(searchValue.toLowerCase()) !== -1) {
                    count += 1;
                    return (
                         <ClientRow
                              key={`${caseObj.clientId}`}
                              caseList={caseObj}
                              history={history}
                              index={count}
                              cbChangeBaseTab={this.props.cbChangeBaseTab}
                         />
                    );
               }
               return null;
          });
          if (count === 0 && isloaded === 1) {
               displayCaseList = (
                    <React.Fragment>
                         <div className="clr" />
                         <span className="col-sm-12 text-center ">
                              <h6 className="mt10 mb10 pad20">No such clients found !!!</h6>
                         </span>
                    </React.Fragment>
               );
          }
          return (
               <React.Fragment>
                    <ReportViewerHeader />

                    <div className="case_initiation_accordion adminlayout">
                         <div className="card">
                              <div className="top-card-set">
                                   <div className="row">
                                        <div className="col-sm-12 col-md-3 adminclientname">
                                             <div className="form-group">
                                                  <h6 className="">Clients</h6>
                                             </div>
                                        </div>

                                        <div className="col-sm-9 right ">
                                             <div className="col-sm-3 right pad0 mr10">
                                                  <div className="form-group search-filter">
                                                       <input
                                                            type="text"
                                                            className="form-control"
                                                            id="search"
                                                            placeholder="Search Clients"
                                                            value={searchBoxValue}
                                                            onChange={this.searchClient}
                                                       />
                                                       <span className="bt-icon bt-search" />
                                                  </div>
                                             </div>
                                        </div>
                                        {(count === 0 && isloaded === 1) ? displayCaseList
                                             : (
                                                  <div className="adminbg col-sm-12">
                                                       <div className="card-body">
                                                            <div className="all-response-tables">

                                                                 <table className="table">
                                                                      <thead>
                                                                           <tr>
                                                                                <th><span className="left">S No</span></th>
                                                                                <th>
                                                                                     <span className="left">Name</span>
                                                                                     <img height="20" className="right" src="/images/icons/icon-sort.svg" alt="sort" />
                                                                                </th>
                                                                                <th>
                                                                                     <span className="left">Cases Within Tat</span>
                                                                                     <img height="20" className="right" src="/images/icons/icon-sort.svg" alt="sort" />
                                                                                </th>
                                                                                <th>
                                                                                     <span className="left">Cases Beyond Tat</span>
                                                                                     <img height="20" className="right" src="/images/icons/icon-sort.svg" alt="sort" />
                                                                                </th>

                                                                                <th>
                                                                                     <span className="left">Work In Progress</span>
                                                                                     <img height="20" className="right" src="/images/icons/icon-sort.svg" alt="sort" />
                                                                                </th>

                                                                                <th>
                                                                                     <span className="left">Completed</span>
                                                                                     <img height="20" className="right" src="/images/icons/icon-sort.svg" alt="sort" />
                                                                                </th>
                                                                                <th>
                                                                                     <span className="left">Total Cases</span>
                                                                                     <img height="20" className="right" src="/images/icons/icon-sort.svg" alt="sort" />
                                                                                </th>
                                                                           </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                           {loading === true ? <LoadingSpinner /> : displayCaseList}
                                                                      </tbody>
                                                                 </table>
                                                            </div>
                                                       </div>
                                                  </div>
                                             )}
                                   </div>
                              </div>
                         </div>
                    </div>
               </React.Fragment>
          );
     }
}
