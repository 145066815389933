import React from 'react';

class MailToHeader extends React.Component {
     render = () => {
          return (
               <React.Fragment>
                    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                         <a className="navbar-brand" href="/"><img src={"/images/logo.svg"} alt="logo"/></a>
                         <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar-toggler-icon"></span>
                         </button>
                    </nav>
               </React.Fragment>
          );
     }
}

export default MailToHeader;