import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { LoginHeader } from './LoginHeader';
import LoginForm from './LoginForm';
// import { LoginFooter } from './LoginFooter';
import { VerifyOTP } from './VerifyOTP';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import ForgotPassword from './ForgotPassword';
import BottomAlert from '../globals/uicomps/BottomAlert'
import ResetPassword from './ResetPassword';
import PhotoAuthenticator from "./PhotoAuthenticator"
import AccountSuspend from './AccountSuspend';
import TryAnotherWays from "./TryAnotherWays"
import Authenticator from './Authenticator';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import md5 from 'md5';
import UserInfo from '../models/UserInfo';
import { getValue, setValue } from '../globals/utils';
import { encryptionKey } from '../globals/constants'
import ls from 'local-storage';

export class LoginLayout extends Component {

     constructor(props) {
          super(props);
          let index = this.props.location.pathname.lastIndexOf("/");
          let step = this.props.location.pathname.substr(index + 1).trim()
          console.log(index, step);
          this.state = {
               alertMsg: null,
               role: getValue('roleCode'),
               userData: null,
               forgotPassword: false,
               otpVerified: false,
               isSuspended: false,
               anotherWay: false,
               isAuthMFAEnabled: getValue('authenticatorEnabled'),
               isAuthPhotoEnabled: getValue('faceIdEnabled'),
               step: (step === '' || step === 'home') ? 'login' : step,
               fullLoading: false
          };
          this.role = getValue('roleCode')
          this.userId = UserInfo.getUserid()
          this.username = UserInfo.getUsername();
          this.userName = '';
     }

     componentDidMount = () => {
          this.redirectIfLoggedIn();
          console.log("login layout mount")
     }

     redirectIfLoggedIn = () => {
          if (getValue('isloggedin')) {
               const role = getValue('roleCode');
               if (role === 'vendor') {
                    document.location.href = "/vendorDashboard";
               } else if (role === 'operations') {
                    document.location.href = "/operations";
               } else if (role === 'de') {
                    document.location.href = "/dataEntry";
               } else if (role === 'reportviewer') {
                    document.location.href = "/reportViewerDashboard";
               } else if (role === 'documentviewer') {
                    document.location.href = "/documentViewerDashboard";
               } else if (role === 'qc') {
                    document.location.href = "/qcDashboard";
               } else {
                    if (getValue('firstTimeLogin') === true) {
                         document.location.href = "/onboarding";
                    }
                    else if (getValue('rolePriority') <= getValue('roleMap')['chp']) {
                         if (getValue('rolePriority') !== '' && getValue('rolePriority') !== undefined && (getValue('rolePath') !== '')) {
                              document.location.href = `/${getValue('rolePath')}`;
                         } else if (getValue('rolePriorityList') && getValue('rolePriorityList') !== undefined && getValue('rolePriorityList').length >= 2) {
                              document.location.href = '/chooseRole';
                         } else {
                              document.location.href = "/adminDashboard";
                         }
                    }
                    else {
                         document.location.href = "/dashboard";
                    }
               }
          }
     }

     validateLogin = (username, password) => {
          password = password.trim()
          this.dataSource = new RestDataSource(`${restbaseurl}/login/auth`)
          this.setState({ loading: true });
          this.dataSource.GetData(
               respData => {
                    if (respData.message === 'success') {
                         (getValue('authenticatorEnabled') || getValue('faceIdEnabled')) ?
                              this.setState({ loading: false, userdata: respData.data, step: 'tryAnotherWays', alertMsg: null })
                              : this.setState({ loading: false, userdata: respData.data, step: 'verifyOtp', alertMsg: null }, () => this.triggerOtp())
                    }
                    else if (respData.isBlocked === true) {
                         this.setState({ loading: false, step: 'blocked' })
                    }
                    else {
                         this.setState({ loading: false, alertMsg: respData.message });
                    }
               },
               { "username": username, "password": md5(password) },
               "POST"
          );
     }

     validateDetails = (userName, mobileNumber) => {
          this.checkDetails = new RestDataSource(`${restbaseurl}/login/forgotPassword`)
          this.setState({ loading: true });
          this.checkDetails.GetData(
               respData => {
                    const jwt = require('jsonwebtoken');
                    if (respData.message === 'success') {
                         let token = jwt.sign({
                              'isloggedin': false,
                              'isForgotPassword': true,
                              'username': respData.data.username,
                              'userId': respData.data.userId,
                              'clientId': respData.data.clientId,
                              'firstTimeLogin': respData.data.firstTimeLogin,
                              'loginname': respData.data.firstname,
                              'clientEmail': respData.data.clientEmail,
                              'otpExpiryTime': respData.data.otpExpiryTime,
                              'mobilenumber': respData.data.mobilenumber,
                              'authenticatorEnabled': respData.data.authenticatorEnabled,
                              'faceIdEnabled': false,
                              'rolePriority': '',
                              'rolePath': ''
                         }, encryptionKey
                         );
                         ls.set('token', token)
                         this.setState({ loading: false, step: 'verifyOtp', isAuthPhotoEnabled: false });
                    }
                    else {
                         this.setState({ loading: false });
                         this.showAlert(respData.message)
                    }
               },
               { "userName": userName, "mobileNumber": mobileNumber },
               "POST"
          );
     }

     resendOTP = () => {
          this.checkResend = new RestDataSource(`${restbaseurl}/login/resendOtp`)
          this.showAlert(null);
          this.setState({ loading: true }, () => {
               this.checkResend.GetData(
                    resendResponse => {
                         this.setState({ loading: false })
                         if (resendResponse.isBlocked) {
                              this.changeStepValue("blocked")
                         }
                         else {
                              setValue('remainingTries', resendResponse.remainingTries)
                         }
                    },
                    { 'userId': UserInfo.getUserid(), "username": UserInfo.getUsername() },
                    "POST"
               );
          })
     }

     triggerOtp = () => {
          this.otpRequest = new RestDataSource(`${restbaseurl}/login/triggerOtp`);
          this.setState({ loading: true });
          this.otpRequest.GetData(
               respData => {
                    if (respData.status === 'success') {
                         if (respData.isBlocked) {
                              this.changeStepValue("blocked");
                         } else {
                              setValue('remainingTries', respData.remainingTries);
                         }
                    } else {
                         this.showAlert(respData.message)
                    }
                    this.setState({ loading: false });
               },
               { "userId": UserInfo.getUserid() },
               "POST"
          );
     }

     verifyOTP = (otp) => {

          this.verifyOTPReq = new RestDataSource(`${restbaseurl}/login/otpLogin`)
          this.showAlert(null);
          this.setState({ loading: true }, () => {
               this.verifyOTPReq.GetData(
                    respData => {
                         this.setState({ loading: false })
                         if (respData.message === 'success') {
                              if (getValue('isForgotPassword')) {
                                   this.changeStepValue("resetPassword")
                              }
                              else {
                                   this.loginSuccess();
                              }
                         }
                         else {
                              if (respData.isBlocked === true) {
                                   this.changeStepValue("blocked")
                              }
                              else {
                                   console.log("alertMsg", respData.message)
                                   this.showAlert(respData.message)
                              }

                         }
                    },
                    { 'userId': UserInfo.getUserid() ? UserInfo.getUserid() : getValue('userId'), "username": UserInfo.getUsername() ? UserInfo.getUsername() : getValue('username'), "otp": otp },
                    "POST"
               );
          })
     }

     loginSuccess = () => {
          this.setState({ fullLoading: true });
          setValue('isloggedin', true);
          document.body.classList.remove('login-bg');
          this.redirectIfLoggedIn();
     }

     changeStepValue = (stepValue, userName) => {
          if (stepValue === 'verifyOtp') {
               this.triggerOtp();
          }
          this.userName = userName;
          this.setState({ step: stepValue });
     }

     showAlert = (alertMsg) => {
          this.setState({ alertMsg: alertMsg });
     }

     render = () => {
          if (ls.get('token') === "" || ls.get('token') === undefined) {
               this.redirectIfLoggedIn();
          }
          let path = this.props.match.path !== '/' ? this.props.match.path : '/home';
          return (

               <React.Fragment>

                    <Router>
                         <GoogleReCaptchaProvider reCaptchaKey="6Lf9YE0kAAAAAPv4zL_0IqT6KSzTX7P6AMTFvd1D">
                              {this.state.fullLoading ? <LoadingSpinner /> :
                                   <React.Fragment>
                                        <LoginHeader />
                                        <div className="login-block">
                                             <div className="login-set">

                                                  {document.body.classList.add('login-bg')}
                                                  {
                                                       this.state.loading === true ? <LoadingSpinner /> :
                                                            <React.Fragment>
                                                                 {
                                                                      (this.state.alertMsg) ? <BottomAlert status="danger" msg={this.state.alertMsg} /> : null
                                                                 }
                                                                 {
                                                                      <Switch>
                                                                           <Route exact path={`${path}/verifyOtp`} render={(props) => <VerifyOTP {...props} {...this.state.userdata} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} resendOTP={this.resendOTP} verifyOTP={this.verifyOTP} />} />
                                                                           <Route exact path={`${path}/tryAnotherWays`} render={(props) => <TryAnotherWays {...props} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} />} />
                                                                           <Route exact path={`${path}/photoAuthenticator`} render={(props) => <PhotoAuthenticator {...props} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} />} />
                                                                           <Route exact path={`${path}/authenticator`} render={(props) => <Authenticator {...props} {...this.state.userdata} showAlert={this.showAlert} changeStepValue={this.changeStepValue} loginSuccess={this.loginSuccess} />} />
                                                                           <Route exact path={`${path}/forgotPassword`} render={(props) => <ForgotPassword {...props} userName={this.userName} forwardToOtp={this.validateDetails} changeStepValue={this.changeStepValue} />} />
                                                                           <Route exact path={`${path}/resetPassword`} render={(props) => <ResetPassword {...props} showAlert={this.showAlert} loginSuccess={this.loginSuccess} changeStepValue={this.changeStepValue} />} />
                                                                           <Route exact path={`${path}/blocked`} render={(props) => <AccountSuspend {...props} changeStepValue={this.changeStepValue} />} />
                                                                           <Route path={path} render={(props) => <LoginForm {...props} cbValidateLogin={this.validateLogin} changeStepValue={this.changeStepValue} />} />

                                                                      </Switch>
                                                                 }
                                                                 {
                                                                      <Redirect to={`${path}/${this.state.step}`} />
                                                                 }
                                                            </React.Fragment>
                                                  }
                                             </div>
                                             <div className="clr"></div>
                                        </div>
                                   </React.Fragment>
                                   /* <LoginFooter /> */
                              }
                              <img className="loginbg" src={'/images/login-bg.svg'} alt='' />
                         </GoogleReCaptchaProvider>


                    </Router>
               </React.Fragment>
          );
     }
}

//
/*



     */