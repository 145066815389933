import React, { Component } from 'react';
import VendorManagementNav from "./VendorManagementNav"
import VendorManagementLayout from './VendorManagementLayout';
import VendorDetailsLayout from '../vendorDetails/VendorDetailsLayout';
import VendorAssignmentLayout from '../vendorAssignment/VendorAssignmentLayout';
import VendorServiceableLocations from './VendorServiceableLocations'
import VendorCostingLayout from './vendorCosting/VendorCostingLayout';

class VendorBaseLayout extends Component {

     constructor(props) {
          super(props);
          const tabs = ["Vendor Info", "Vendor Assignment", "Vendor Details", 'Costing', 'Serviceable Locations']
          this.state = {
               activeTab: tabs[0],
               tabList: [...tabs]
          }
     }


     changeBaseTab = (tabname) => {
          this.setState({ activeTab: tabname })
     }


     render = () => {
          let { activeTab } = this.state
          return (
               <React.Fragment>
                    <div className="container-fluid vendor_assignment_wrap">
                         {
                              <VendorManagementNav cbChangeBaseTab={this.changeBaseTab} activeTab={this.state.activeTab} subHeaderNavList={this.state.tabList} />
                         }

                         {
                              activeTab === "Vendor Info" ?
                                   <VendorManagementLayout cbChangeBaseTab={this.changeBaseTab} /> : ""
                         }
                         {
                              activeTab === "Vendor Assignment" ?
                                   <VendorAssignmentLayout cbChangeBaseTab={this.changeBaseTab} /> : ""
                         }
                         {
                              activeTab === "Vendor Details" ?
                                   <VendorDetailsLayout cbChangeBaseTab={this.changeBaseTab} /> : ""
                         }
                         {
                              activeTab === "Costing" ?
                                   <VendorCostingLayout cbChangeBaseTab={this.changeBaseTab} /> : ""
                         }
                         {
                              activeTab === "Serviceable Locations" ?
                                   <VendorServiceableLocations cbChangeBaseTab={this.changeBaseTab} /> : ""
                         }
                    </div>
               </React.Fragment>
          )
     }
}

export default VendorBaseLayout;
