import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { ErrorMessage } from 'formik';
import { BTFieldValError } from './BTFieldValError';
import "react-datepicker/dist/react-datepicker.css";
import '../../css/datepicker_custom.css';

export default class BTDatePicker extends Component {

     handleDateChange = (value, key) => {
          const { showTimeSelect, handleDateChange } = this.props;
          if (value === null) {
               handleDateChange(key, '');
          } else if (showTimeSelect) {
               const date = new Date(value);
               const mnth = (`0${date.getMonth() + 1}`).slice(-2);
               const day = (`0${date.getDate()}`).slice(-2);
               const hours = date.getHours();
               const minutes = date.getMinutes();
               const trimmedDate = [date.getFullYear(), mnth, day].join('-');
               const trimmedTime = [hours, minutes].join(':');
               const trimmedDateTime = `${trimmedDate} ${trimmedTime}`;
               handleDateChange(key, trimmedDateTime);
          } else {
               const date = new Date(value);
               const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
               const day = ("0" + date.getDate()).slice(-2);
               const trimmedDate = [date.getFullYear(), mnth, day].join("-");
               handleDateChange(key, trimmedDate);
          }
     }

     render = () => {
          let customClass = this.props.customClass;
          let { value, name, placeholderText, showTimeSelect, readOnly, minDate, maxDate, label, required, customClassName, dateFormat } = this.props;
          if (minDate) {
               minDate = new Date(minDate);
          }
          if (maxDate) {
               maxDate = new Date(maxDate);
          }
          placeholderText = placeholderText ? placeholderText : "yyyy-mm-dd";
          if (value === 'undefined') {
               value = ''
          }
          value = value ? value : '';
          return (
               <div className={`form-group`}>
                    {label ? <label className="body2">{label}
                         {
                              !required ? <div className="optional">&nbsp;( optional )</div> : null
                         }
                    </label> : null}
                    <DatePicker
                         selected={value ? new Date(value) : ''}
                         timeFormat="HH:mm"
                         onChange={value => this.handleDateChange(value, name)}
                         showMonthDropdown
                         showYearDropdown
                         dropdownMode="select"
                         isClearable={readOnly ? false : true}
                         showTimeSelect={showTimeSelect}
                         placeholderText={placeholderText}
                         value={value ? new Date(value) : ''}
                         minDate={minDate ? minDate : ''}
                         maxDate={maxDate ? maxDate : ''}
                         timeIntervals={15}
                         timeCaption="Time"
                         dateFormat={dateFormat || 'yyyy-MM-dd'}
                         autoComplete='off'
                         name={name}
                         readOnly={readOnly ? readOnly : false}
                         className={`form-control ${customClass ? customClassName : ''}`}
                    />
                    <ErrorMessage name={name} component={BTFieldValError} />
               </div>
          );
     }
}
