import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
class VendorList extends Component {

     render = () => {
          let vendorObj = this.props.vendorDetails
          return (
               <div className="card-list">
                    <div className="user-card" style={{ minHeight: "174px" }}>
                         <div className="bt__img-text-group">
                              <div className="text-wrapper">
                                   <div className="card-head pointer" onClick={() => this.props.showVendor(vendorObj.id,false)}>
                                        <div className="sub-heading1 left">{vendorObj.name}</div>
                                        <div className="clr"></div>
                                        <div className="body2 black7">{vendorObj.username}</div>
                                        <div className="clr5"></div>
                                        <hr />
                                        <div className="row">
                                             <div className="col-sm-6">
                                                  <div className="location">
                                                       <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                                            viewBox="0 0 246 408.75" x="0px" y="0px" clipRule="evenodd"
                                                            fillRule="evenodd">
                                                            <g>
                                                                 <path
                                                                      d="M123 0c68,0 123,55 123,123 -1,68 -77,160 -123,204 -46,-41 -122,-139 -123,-204 0,-68 55,-123 123,-123zm0 295c42,-44 99,-120 100,-172 0,-55 -45,-100 -100,-100 -55,0 -100,45 -100,100 1,50 58,129 100,172z" />
                                                                 <path
                                                                      d="M123 71c29,0 52,23 52,52 0,29 -23,52 -52,52 -29,0 -52,-23 -52,-52 0,-29 23,-52 52,-52zm0 23c16,0 29,13 29,29 0,16 -13,29 -29,29 -16,0 -29,-13 -29,-29 0,-16 13,-29 29,-29z" />
                                                            </g>
                                                       </svg>
                                                       <p className="header-three-line-text">
                                                            {vendorObj.address}
                                                       </p>
                                                  </div>
                                             </div>
                                             <div className="col-sm-6">
                                                  <div className="location">
                                                       <img src={"/images/component-icons/component-mobile.svg"} className="left mr5" alt="drug-icon" />
                                                       <p> {vendorObj.phone ? vendorObj.phone : "-"} </p>
                                                       <div className="clr10"></div>
                                                       <svg style={{ marginRight: "5px" }} className="credit-card" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.5,64H1.5A1.5,1.5,0,0,0,0,65.5v9A1.5,1.5,0,0,0,1.5,76h13A1.5,1.5,0,0,0,16,74.5v-9A1.5,1.5,0,0,0,14.5,64Zm-13,1h13a.5.5,0,0,1,.5.5v1.294c-.684.578-1.663,1.375-4.706,3.791C9.766,71,8.725,72.012,8,72c-.725.012-1.769-1-2.294-1.416C2.662,68.169,1.684,67.372,1,66.794V65.5A.5.5,0,0,1,1.5,65Zm13,10H1.5a.5.5,0,0,1-.5-.5V68.094c.712.584,1.838,1.488,4.084,3.272C5.725,71.878,6.856,73.006,8,73c1.137.009,2.259-1.109,2.916-1.634,2.247-1.784,3.372-2.687,4.084-3.272V74.5A.5.5,0,0,1,14.5,75Z" transform="translate(4 -58)" /></svg>
                                                       <p> {vendorObj.email ? vendorObj.email : "-"} </p>
                                                  </div>
                                                  <div className="clr10"></div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
export default withTranslation("translations")(VendorList);