import React, { Component } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { BTInputField } from '../globals/uicomps/BTInputField';
import { withTranslation } from 'react-i18next';



class LoginForm extends Component {

     constructor(props) {
          super(props);
          this.initialValues = { username: '', password: '' };

          this.schema = yup.object().shape({
               username: yup.string().required('Username is required'),
               password: yup.string().required('Password is required')
          });

     }

     submitLoginDetails = (values) => {
          this.props.cbValidateLogin(values.username, values.password)
     }


     render = () => {
          const { t } = this.props;

          return (
               <React.Fragment>

                    {/* <div className="sub-heading1 text-center"></div> */}
                    <div className="clr20"></div>

                    <Formik validationSchema={this.schema} onSubmit={this.submitLoginDetails} initialValues={this.initialValues}>
                         {
                              ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                   <form onSubmit={handleSubmit} noValidate >

                                        <BTInputField label={t('username')} id="username" handleBlur={handleBlur} required={true}
                                             handleChange={handleChange} value={values.username} />

                                        <BTInputField type="password" label={t('password')} id="password" handleBlur={handleBlur} required={true}
                                             handleChange={handleChange} value={values.password} />

                                        <input className="btn btn_primary block" type="submit"
                                             value="Continue" disabled={!(values.username && values.password)}></input>
                                        {
                                             this.props.hideForgotPassword ? '' : <div className="forgetlink body2 right mt10 pointer" onClick={() => { this.props.changeStepValue("forgotPassword", values.username) }} >{t('Forgot password ?')}</div>
                                        }



                                   </form>
                              )
                         }
                    </Formik>
                    <div className="clr20"></div>

               </React.Fragment>


          );
     }

}
export default withTranslation('translations')(LoginForm);