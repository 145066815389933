import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {CircularProgressbar,buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class PersonaChart extends Component {

     constructor(props) {
          super(props);
          this.state = {
               personaDetail: this.props.personaDetail,
               personaKey: this.props.personaKey
          }
     }

     render = () => {
          console.log("personadetail",this.state.personaDetail);
          return(
               (this.state.personaDetail !== undefined) ?
               <div className="bt_col persona-chart">
                    <div className="card-content" >
                         <CircularProgressbar strokeWidth="6" sqSize="80" text={`${this.state.personaDetail.score }/10`} value={this.state.personaDetail.score * 10} styles={buildStyles({
                                   textColor: '#3d3d3d',
                                   pathColor: '#58e898',
                                   trailColor: '#f5f5f5',
                              })} />
                         <p className="primary-text">{this.state.personaDetail.level}</p>
                         <p>{this.state.personaKey.replace("_", " ").replace("_", " ")}</p>
                    </div>
               </div>
               :""
          );
     }
}

export default withTranslation('translations')(PersonaChart);
