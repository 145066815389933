import React, { Component } from 'react';
import { BTInputField } from '../globals/uicomps/BTInputField';
import Select from "react-select";
import { RestDataSource } from '../utils/RestDataSource';
import { restbaseurl } from '../globals/constants';
import { withTranslation } from 'react-i18next';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import BTDatePicker from '../globals/uicomps/BTDatePicker';
import makeAnimated from 'react-select/animated';
import { LoadingSpinner } from '../utils/LoadingSpinner';

class QcDetails extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               svDetails: {},
               statusList: [],
               statusId: '',
               uploadUserId: '',
               uploadUserName: '',
               userList: [],
               comments: '',
               snackbar: false,
               snackmsg: '',
               uploadByMyself: false,
               firstLevelAssignedTo: '',
               selectedVerificationList: []
          }
          this.defObjList = ''
          this.verificationTypeList = [];
     }

     componentDidMount = () => {
          this.init();
          this.fetchVerificationList();
     }

     init = () => {
          this.caseDetails = new RestDataSource(`${restbaseurl}/qualityCheck/fetchQcDetails`);
          this.setState({ loading: true }, () => {
               this.caseDetails.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   svDetails: data.data.svDetails,
                                   statusList: data.data.statusList,
                                   userList: data.data.userDataList,
                                   statusName: data.data.status.name,
                                   statusId: data.data.status.id,
                                   uploadUserId: data.data.uploadBy ? data.data.uploadBy.id : '',
                                   uploadUserName: data.data.uploadBy ? data.data.uploadBy.name : '',
                                   comments: data.data.comments,
                                   firstLevelAssignedTo: data.data.firstQcOwner,
                                   selectedVerificationList: data.data.verificationList,
                                   loading: false,
                              })
                         }
                    },
                    { caseId: this.props.caseId, showSecondLevelQc: this.props.showSecondLevelQc },
                    "POST"
               );
          });
     }

     handleChange = (e) => {
          const list = e.target.getAttribute('name').split(".");
          const value = e.target.value;
          const svDetails = this.state.svDetails
          if (list[1]) {
               svDetails[list[1]] = value
               this.setState({
                    svDetails: svDetails
               })
          } else {
               this.setState({
                    [e.target.getAttribute('name')]: value
               })
          }
     }

     handleDateChange = (name, value) => {
          const list = name.split(".");
          const svDetails = this.state.svDetails
          svDetails[list[1]] = value
          this.setState({ svDetails: svDetails });
     }

     onSelectChange = (event) => {
          this.setState({
               statusId: event.value,
               statusName: event.label,
          })
     }

     onSelectUserChange = (event) => {
          this.setState({
               uploadUserId: event.value,
               uploadUserName: event.label,
          })
     }

     saveQcDetails = () => {
          const {
               svDetails, statusId, comments, uploadUserId, selectedVerificationList
          } = this.state
          this.caseDetails = new RestDataSource(`${restbaseurl}/qualityCheck/saveQcDetails`);
          this.setState({ loading: true }, () => {
               this.caseDetails.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   loading: false,
                                   snackmsg: 'Qc Details Saved Successfully',
                                   snackbar: true
                              });
                         }
                    },
                    { caseId: this.props.caseId, svDetails: svDetails, statusId: statusId, comments: comments, uploadUserId: uploadUserId, verificationTypeList: selectedVerificationList },
                    "POST"
               );
          });
     }

     hideSnackbar = () => {
          this.setState({ snackbar: false });
     }

     uploadByMyself = () => {
          this.setState({
               uploadByMyself: !this.state.uploadByMyself
          })
     }

     onMultiSelect = (selectedOptionList) => {
          this.setState({
               selectedVerificationList: selectedOptionList
          })
     }

     fetchVerificationList = () => {
          this.dataSource = new RestDataSource(
               `${restbaseurl}/applicantCase/obtainVerificationTypeList`
          );
          this.setState({ loading: true }, () => {
               this.dataSource.GetData(
                    data => {
                         if (data.status === true) {
                              let verificationTypeList = data.data;
                              this.verificationTypeList = verificationTypeList.map(data => { return { label: data.label, value: data.value } });
                              this.setState({
                                   loading: false
                              });
                         }
                    },
                    {},
                    "POST"
               );
          });
     }

     render = () => {
          const { t } = this.props
          const { 
               svDetails, statusList, snackbar, snackmsg, comments, firstLevelAssignedTo, statusName, statusId, uploadUserId, uploadUserName, userList, selectedVerificationList, loading
           } = this.state
          const { showSecondLevelQc } = this.props
          const statusOption = statusList.map(statusObj => {
               return { label: statusObj.name, value: statusObj.id };
          });
          const userOption = userList.map(userObj => {
               return { label: userObj.name, value: userObj.id };
          });
          console.log("selectedVerificationList --", selectedVerificationList)
          // const verificationOption = selectedVerificationList ? selectedVerificationList.map(data => {
          //      return { label: data.name, value: data.value };
          // }) : ''
          const animatedComponents = makeAnimated();
          return (
               <>
                    {snackbar ?
                         <BTSnackbar msg={snackmsg} status="success" time="4000" onHide={this.hideSnackbar} /> : ""}
                    {loading ? <LoadingSpinner /> :
                         <div className="qcDetails">
                              <div className="row reportpreviewcard">
                                   {showSecondLevelQc ?
                                        <>
                                             <div className="col-12 col-sm-12 col-md-12">
                                                  <div className="sub-heading1 left mr15">First Level Qc Owner </div>
                                                  <div className='clr10'></div>
                                                  <span style={{ fontSize: "15px" }}>{firstLevelAssignedTo}</span>
                                             </div>
                                             <div className='clr20'></div>
                                        </>
                                        : ''
                                   }
                                   <div className="col-12 col-sm-12 col-md-12">
                                        <div className="sub-heading1 left mr15">SV Tool Details</div>
                                   </div>
                                   <div className='clr20'></div>
                                   <div className="col-12 col-sm-3 col-md-3">
                                        <BTDatePicker label={t("Uploaded Date")} name='svDetails.uploadedDate' value={svDetails["uploadedDate"]} handleDateChange={this.handleDateChange} showTimeSelect={false} />
                                   </div>
                                   <div className="col-12 col-sm-3 col-md-3">
                                        <BTInputField label={t("Remarks")} type="text" id="svDetails.remarks" value={svDetails["remarks"]} handleChange={this.handleChange} required={true} />
                                   </div>
                                   <div className="col-12 col-sm-3 col-md-3">
                                        <label className="mt2 body2">Qc Status</label>
                                        <Select
                                             id="statusId"
                                             name="statusId"
                                             style={{ border: "2px solid red" }}
                                             options={statusOption}
                                             value={{ label: statusName, value: statusId }}
                                             onChange={this.onSelectChange}
                                        ></Select>
                                   </div>
                                   <div className="col-12 col-sm-3 col-md-3 ">
                                        <label className="mt2 body2">Upload By</label>
                                        <Select
                                             id="uploadUserId"
                                             name="uploadUserId"
                                             style={{ border: "2px solid red" }}
                                             options={userOption}
                                             value={{ label: uploadUserName, value: uploadUserId }}
                                             onChange={this.onSelectUserChange}
                                        ></Select>
                                   </div>
                                   <div className="col-sm-6 col-md-6" style={{ paddingLeft: '10px' }}>
                                        <div className="sub-heading1 left mr15">Comments</div>
                                        <div className="location">
                                             <div className='clr10'></div>
                                             <textarea className="form-control noresize"
                                                  id="comments"
                                                  name="comments"
                                                  rows="5"
                                                  placeholder="Enter here"
                                                  onChange={this.handleChange}
                                                  value={comments}
                                             ></textarea>
                                        </div>
                                   </div>
                                   <div className="col-sm-6 col-md-6">
                                        <div className="sub-heading1 left mr15">Components</div>
                                        <div className='clr10'></div>
                                        <Select
                                             id="statusList"
                                             components={animatedComponents}
                                             placeholder="Choose Components"
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.verificationTypeList}
                                             onChange={(event) => this.onMultiSelect(event)}
                                             value={selectedVerificationList}
                                             isClearable={false}
                                             isMulti
                                             className="basic-multi-select"
                                             classNamePrefix="select"
                                        ></Select>
                                   </div>
                                   <div className='clr20'></div>
                                   <div className=" col-sm-12 col-md-12 col-12 text-center">
                                        <button className="btn btn_primary" onClick={this.saveQcDetails}>Submit</button>
                                   </div>
                                   <div className='clr20'></div>
                              </div>
                         </div>
                    }
               </>
          )
     }
}

export default withTranslation('translations')(QcDetails);