import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CaseManagementLayout from '../../clientAdmin/administration/caseManagement/CaseManagementLayout';
import VendorBaseLayout from '../../clientAdmin/administration/vendorManagement/VendorBaseLayout';
import ReportViewerHeader from '../ReportViewerHeader';

class ManagementLayout extends Component {
     constructor(props) {
          super(props);
          this.state = {
               currentPanel: 'case-management',
          }
     }

     onPanelChange = (panelName) => {
          this.setState({ currentPanel: panelName })
     }

     render = () => {
          const { t } = this.props;
          let currentPanel = null;
          switch (this.state.currentPanel) {
               default:
               case 'case-management':
                    currentPanel = <CaseManagementLayout isAdminDashboard={false} />
                    break;
               case 'vendor-management':
                    currentPanel = <VendorBaseLayout />
                    break;
          }
          let navList = [
               {
                    name: 'Clients',
                    route: 'clientList'
               },
               {
                    name: 'Cases',
                    route: 'clientCases'
               },
               {
                    name: 'Management',
                    route: 'management'
               }
          ];

          return (
               <React.Fragment>
                    <ReportViewerHeader navList={navList} showNavTabs activeTab="Management" cbChangeBaseTab={this.props.cbChangeBaseTab} />
                    <section className="admin-main-wrapper">
                         <div className="admin-row adminstration_layout">
                              <aside className="left-side sticky-siderbar">
                                   <div className="admin-side-menu">
                                        <ul>
                                             <li><p onClick={() => this.onPanelChange('case-management')} className={`adminstration_panel ${this.state.currentPanel === 'case-management' ? 'active' : ''}`}><span className="bt-icon bt-pack-approval"></span>{t('adminlayout.tabs.casemanagement.label')}</p></li>
                                             <li><p onClick={() => this.onPanelChange('vendor-management')} className={`adminstration_panel ${this.state.currentPanel === 'vendor-management' ? 'active' : ''}`}><span className="bt-icon bt-user-management"></span>{t('Vendor Management')}</p></li>
                                        </ul>
                                   </div>
                              </aside>
                              <aside className="right-side padding-card box-shadow-card">
                                   {currentPanel}
                              </aside>
                         </div>
                    </section>
               </React.Fragment>
          );
     }
}

export default withTranslation('translations')(ManagementLayout);