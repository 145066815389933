import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import BtAccordianCard from '../bgvformnew/BtAccordianCard';
import { restbaseurl } from '../globals/constants';
import BTSnackbar from '../globals/uicomps/BTSnackbar';
import { DocumentUploadCompressor } from '../globals/uicomps/DocumentUploadCompressor';
import { getValue } from '../globals/utils';
import UserInfo from '../models/UserInfo';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import { RestDataSource } from '../utils/RestDataSource';
import CaseSearchTable from './CaseSearchTable';


class DocumentSearch extends Component {

     constructor(props) {
          super(props);

          this.state = {
               showSnackBar: false,
               snackMsg: '',
               snackStatus: '',
               docList: null,
               zipLink: '',
               caseList: [],
               basicInfo: {},
               searchText: '',
               loading: false,
               viewCaseDetails: false,
               viewSearchTable: false,
          }
          this.searchText = '';
          this.userId = UserInfo.getUserid();
          this.role = getValue('roleCode');
     }

     hideSnackbar = () => {
          this.setState({ showSnackBar: false });
     }

     handleInputChange = (e) => {
          const value = e.target.value;
          this.setState({ searchText: value });
     }

     obtainAccessibleCaseList = () => {
          let caseListDataSource = new RestDataSource(`${restbaseurl}/applicantCase/accessibleCaseList`);
          this.setState({ loading: true, viewSearchTable: false, viewCaseDetails: false }, () => {
               caseListDataSource.GetData(
                    resp => {
                         if (resp.status === true) {
                              this.setState({ loading: false, caseList: resp.caseList, viewSearchTable: true });
                         } else {
                              console.log('failed');
                              this.setState({ loading: false, showSnackBar: true, snackMsg: resp.error, snackStatus: 'danger', docList: [] });
                         }
                    },
                    { userId: this.userId, role: this.role, searchText: this.state.searchText },
                    "POST"
               )
          });
     }

     handleGetDocuments = (id) => {
          this.statusdataSource = new RestDataSource(`${restbaseurl}/applicantCase/obtainSourceDocList`)
          this.setState({ loading: true, viewSearchTable: false, viewCaseDetails: false }, () => {
               this.statusdataSource.GetData(
                    resp => {
                         if (resp.status === true) {
                              this.setState({
                                   showSnackBar: true,
                                   snackMsg: 'Success',
                                   snackStatus: 'success',
                                   docList: resp.docList,
                                   zipLink: resp.zipLink,
                                   basicInfo: resp.basicInfo,
                                   viewCaseDetails: true,
                                   loading: false,
                              });
                         } else {
                              console.log('failed');
                              this.setState({ loading: false, showSnackBar: true, snackMsg: resp.error, snackStatus: 'danger', docList: [] });
                         }
                    },
                    { userId: this.userId, role: this.role, caseId: id },
                    "POST"
               );
          })
     }

     render = () => {
          const { t } = this.props;
          const {
               showSnackBar,
               snackMsg,
               snackStatus,
               docList,
               zipLink,
               basicInfo,
               searchText,
               loading,
               caseList,
               viewCaseDetails,
               viewSearchTable,
          } = this.state;

          let caseDocsView = null

          if (!!docList && viewCaseDetails) {
               if (docList.length > 0) {
                    let categoryRows = docList.map((categoryObj, idx) => {
                         return (
                              <React.Fragment>
                                   <BtAccordianCard cardKey={categoryObj.category} label={categoryObj.category} cardIndex={idx} key={categoryObj.category}>
                                        <DocumentUploadCompressor
                                             width='100%'
                                             height='120px'
                                             showPreview={true}
                                             allowedDocs='pdf, jpg, jpeg, png'
                                             removeFileOption={false}
                                             showExistingList={true}
                                             existingList={categoryObj.docList}
                                             allowMultipleUpload={true}
                                             uploadMessage=''
                                             maxSizeMB={0.1}
                                             allowedDocsList={['pdf', 'jpg', 'jpeg', 'png', 'docx', 'doc']}
                                             attachmentList={[]}
                                             NoDocMessage={'No documents'}
                                             existingObjFileName='fileName'
                                             showUploadArea={false}
                                        />
                                   </BtAccordianCard>
                              </React.Fragment>
                         )
                    });

                    caseDocsView = <React.Fragment>
                         <div className="results-header">
                              <h6>{this.searchText}</h6>
                              <a href={zipLink} target="blank" className="ml15">
                                   <div class="file-btn download-btn bgv_download right mr15">
                                        <div class="file-name body2 smallfont" title="Source Document">
                                             <span>{t("documentViewer.documentSearch.result.downloadZip")}<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                  <path d="M15.558,42.357,8.951,48.987a.536.536,0,0,1-.759,0L1.585,42.357a.536.536,0,0,1,0-.759l.317-.317a.536.536,0,0,1,.759,0L7.813,46.46V32.536A.537.537,0,0,1,8.348,32h.446a.537.537,0,0,1,.536.536V46.46l5.152-5.174a.536.536,0,0,1,.759,0l.317.317A.531.531,0,0,1,15.558,42.357Zm1.585,9.107v-.357a.537.537,0,0,0-.536-.536H.536A.537.537,0,0,0,0,51.107v.357A.537.537,0,0,0,.536,52H16.607A.537.537,0,0,0,17.143,51.464Z" transform="translate(3 -30)"></path>
                                             </svg>
                                             </span>
                                        </div>
                                   </div>
                              </a>
                         </div>
                         <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                   <span>{t("documentViewer.documentSearch.result.basicInfo.name")} : {basicInfo.name}</span>
                              </div>
                         </div>
                         <div className="row">
                              <Accordion className="col-12">
                                   {categoryRows}
                              </Accordion>
                         </div>
                         <div className="clr10"></div>
                    </React.Fragment>
               } else {
                    caseDocsView = <React.Fragment>
                         <div className="row">
                              <div className="col-12 content-center-align">
                                   <span className="no-docs-span">{t("documentViewer.documentSearch.result.noDocumentAvailable")}</span>
                              </div>
                         </div>
                         <div className="clr20"></div>
                    </React.Fragment>;
               }
          }

          return (
               <React.Fragment>
                    {showSnackBar ? <BTSnackbar msg={snackMsg} status={snackStatus} time="5000" onHide={this.hideSnackbar} /> : ""}
                    {loading ? <LoadingSpinner /> :
                         <React.Fragment>
                              <div className="case_initiation_accordion adminlayout document-viewer">
                                   <div className="card">
                                        <div className="top-card-set">
                                             <div className="row">
                                                  <div className="col-12 col-md-6 col-lg-3 adminclientname">
                                                       <div className="form-group">
                                                            <h6>{t("documentViewer.documentSearch.heading")}</h6>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className="row">
                                                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                                       <div className="form-group search-filter">
                                                            <input
                                                                 type="text"
                                                                 className="form-control"
                                                                 id="search"
                                                                 value={searchText}
                                                                 placeholder={t("documentViewer.documentSearch.search.input.placeholder")}
                                                                 onChange={this.handleInputChange}
                                                            />
                                                       </div>
                                                  </div>
                                                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                       <span className="btn btn_primary" onClick={this.obtainAccessibleCaseList}>{t("documentViewer.caseSearch.search.button")}</span>
                                                  </div>
                                             </div>
                                             {
                                                  viewSearchTable ?
                                                       <React.Fragment>
                                                            <div className="row">
                                                                 <div className="col-12">
                                                                      <CaseSearchTable caseList={caseList} cbCaseSelect={this.handleGetDocuments} />
                                                                 </div>
                                                            </div>
                                                       </React.Fragment>
                                                       : null
                                             }

                                             {caseDocsView}
                                        </div>
                                   </div>
                              </div>
                         </React.Fragment>
                    }


               </React.Fragment>
          )
     }
}

export default withTranslation('translations')(DocumentSearch);