import React, { Component } from 'react'
import * as yup from 'yup';
import { Formik } from 'formik';
import { BTInputField } from '../globals/uicomps/BTInputField';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import { Redirect } from 'react-router-dom';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';
class ResetPassword extends Component {

     constructor(props) {
          super(props)

          this.state = {
               newPassword: '',
               rePassword: '',
               isPasswordReset: false
          }
          this.schema = yup.object().shape({
               newPassword: yup.string().required('Password is required').min(8, 'Password must contain min 8 characters')
                    .matches(/[A-Z]/, 'Password must contain one uppercase character')
                    .matches(/[@$!%*#?&]/, 'Password must contain one special Character')
                    .matches(/[0-9]/, 'Password must contain one number')
                    .matches(/[a-z]/, 'Password must Contain one alphabet'),
               rePassword: yup.string()
                    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
          });
          this.userid = UserInfo.getUserid() ? UserInfo.getUserid() : getValue('userId');
     }

     resetPassword = (values) => {
          this.props.showAlert(null);
          this.resetPass = new RestDataSource(`${restbaseurl}/login/changePassword`)
          console.log(this.props.userId)
          this.setState({ loading: true }, () => {
               this.resetPass.GetData(
                    passData => {
                         this.setState({ loading: false })
                         if (passData.message === 'success') {
                              // this.props.loginSuccess();
                              this.setState({ isPasswordReset: true })
                         }
                         else {
                              this.props.showAlert(passData.message);
                         }
                    },
                    { 'userId': this.userid, "newPassword": values.newPassword },
                    "POST"
               );
          })
     }

     render() {
          const { t } = this.props;
          const { isPasswordReset } = this.state;
          return (
               isPasswordReset ? <>
                    <div className="sub-heading1 ">Password has been changed successfully</div>
                    <div className="clr20"></div>
                    <div className="form-group">
                         <label className="body2">Click on login to continue</label>
                         <div className="clr10"></div>
                         <div className="clr"></div>
                         <div className="clr10"></div>
                         <div className="clr"></div>
                         <input type="button" className="btn btn_primary block" onClick={() => { this.props.changeStepValue("login") }} value="Login" />
                         <div className="clr10"></div>
                         <div className="clr"></div>
                    </div>
               </> :
                    <React.Fragment>
                         {this.state.isVerified ? <Redirect to="/home" /> : ''}
                         <div className="sub-heading1 text-center">Hi {getValue('loginname')},{t('Set a new password')}</div>
                         <div className="clr20"></div>

                         <Formik validationSchema={this.schema} onSubmit={this.resetPassword} initialValues={this.state}>
                              {
                                   ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                        <form onSubmit={handleSubmit} noValidate >
                                             <BTInputField label={t('New Password')} id="newPassword" type="password" handleBlur={handleBlur}
                                                  handleChange={handleChange} value={values.newPassword} required={true} />

                                             <BTInputField type="password" label={t('Re enter new password')} id="rePassword" handleBlur={handleBlur}
                                                  handleChange={handleChange} value={values.rePassword} required={true} />

                                             <input className="btn btn_primary block" type="submit"
                                                  value="Reset" disabled={!(values.newPassword && values.rePassword)} ></input>
                                        </form>
                                   )
                              }
                         </Formik>
                    </React.Fragment>
          )
     }
}
export default withTranslation('translations')(ResetPassword);

