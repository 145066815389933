import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';


class DialogModal extends Component {

     successHandler = () => {
          if (this.props.successCallback) {
               this.props.successCallback();
          }
     }
     failureHandler = () => {
          if (this.props.failureCallback) {
               this.props.failureCallback();
          }
     }

     render = () => {
          let {
               disableStaticBackdrop,
               onHide,
               header,
               subheading,
               showFooter,
               footerClassName,
               failureButtonText,
               successButtonText
          } = this.props;
          const footerClass = footerClassName ? footerClassName : '';
          return (
               <Modal centered className={"modal fade add-document-modal " + this.props.className} id="add-document" show={this.props.show} onHide={this.props.onHide} backdrop={disableStaticBackdrop ? true : 'static'} >
                    <Modal.Header className="modal-header">
                         <Modal.Title>
                              <h6>{header ? header : ''}</h6>
                         </Modal.Title>
                         <div className="close" onClick={this.hide} aria-label="Close">
                              <span className="bt-icon bt-close pointer" onClick={onHide}></span>
                         </div>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                         <div className="upload-content1">
                              {subheading ? <div className="sub-heading2"> {subheading}</div> : null}
                              <div className="clr5"></div>
                              {this.props.children}
                              <div className="clr20"></div>
                         </div>
                    </Modal.Body>
                    {
                         showFooter === true ?
                              <Modal.Footer className={`text-center ${footerClass}`}>
                                   <button type="button" className="btn btn_secondary" onClick={this.failureHandler} >{failureButtonText}</button>
                                   <button type="button" className="btn btn_primary" onClick={this.successHandler}>{successButtonText}</button>
                              </Modal.Footer>
                              :
                              <div></div>
                    }
               </Modal >

          );
     }
}

export default DialogModal;

/**
  * Usage:
  * <DialogModal
  *      show={this.state.showCaseDuplicateConfirmation}
  *      heading="Are you sure ?"
  *      subheading="Do you want to confirm"
  *      content="Do you want to recheck ? "
  *      successButtonText="Recheck"
  *      failureButtonText="Cancel"
  *      successCallback={callbackfunction}
  *      failureCallback={callbackfunction}
  *      showFooter={true}
  * />
  */
