import { Component } from 'react';


class BTSnackbar extends Component {

     componentDidMount = () => {
          let status = this.props.status ? this.props.status : "success";
          let time = this.props.time ? this.props.time : 3000;
          let snackbarWrapper = document.getElementById("bt-snackbar");
          snackbarWrapper.classList.add("bt-snackbar", status);
          let btSnackbarWrapper = document.getElementById("bt-snackbar-wrapper");
          btSnackbarWrapper.classList.add("snackActive");
          snackbarWrapper.innerHTML = `<div class="icon-with-text">
          <span class="bt-icon"></span>
          ${this.props.msg ? this.props.msg : 'Saved Successfully'}
     </div>`
          setTimeout(() => {
               document.getElementById("bt-snackbar-wrapper").classList.remove('snackActive');
               snackbarWrapper.classList.remove(status);
               if (!!this.props.onHide) {
                    this.props.onHide();
               }
          }, time);
     }
     render = () => {
          return (null);
     }
}

export default BTSnackbar;
const status = { DANGER: 'danger', SUCCESS: 'success' };
const SnackStatus = status;
export { status, SnackStatus };

// Usage:
// <BTSnackbar msg="Alert" status="danger" />
// <BTSnackbar msg="Success" status="success" />
// <BTSnackbar msg="Invites sent" status="success" time="5000" onHide={...}/>
// <BTSnackbar msg={snackMessage} status={snackStatus} time="3000" onHide={...}/>