/* eslint-disable react/jsx-indent-props */
import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoginLayout } from './login/LoginLayout';
import { PageNotFound } from './globals/PageNotFound';
import { InternalError } from './globals/InternalError';
import { CandidateLoginLayout } from './candidate/login/CandidateLoginLayout';
import MailToBaseLayout from './mailTo/MailToBaseLayout';
import VendorLoginLayout from './vendor/login/LoginLayout';
import VerificationLayout from './verification/VerificationBaseLayout';
import { LoadingSpinner } from './utils/LoadingSpinner';
import EmploymentFormContainer from './operations/operationCases/employment/employmentVerificationOnline/EmploymentFormContainer';
import ChooseRole from './Admin/ChooseRole';
import { updateUserInfo, PrivateRoute, importGoogleMapsJS } from './globals/utils';
import ReportViewerBaseLayout from './reportViewer/ReportViewerBaseLayout';
import TestRest from './globals/samples/TestRest';
import DocumentViewerBaseLayout from './documentViewer/DocumentViewerBaseLayout';
// import { Logger } from './globals/samples/Logger';
//billingcommented
// import BillingBaseLayout from './Admin/billing/BillingBaseLayout';

const ClientAdminLayout = lazy(() => import('./clientAdmin/ClientAdminLayout'));
const ClientAdminInitLayout = lazy(() => import('./clientAdmin/clientinit/ClientAdminInitLayout'));
const SocialIntelligenceLayout = lazy(() => import('./clientAdmin/socialIntelligence/SocialIntelligenceLayout'));
const CandidateLayout = lazy(() => import('./candidate/CandidateLayout'));
const OperationsBaseLayout = lazy(() => import('./operations/OperationsBaseLayout'));
const AdminBaseLayout = lazy(() => import('./Admin/AdminBaseLayout'));
const ReportPreview = lazy(() => import('./caseReport/ReportPreview'));
const DataentryLayout = lazy(() => import('./dataEntry/DataEntryLayout'));
const QcLayout = lazy(() => import('./qualityCheck/QcLayout'));
const BgvLayout = lazy(() => import('./clientAdmin/createInit/caseCreation/manualCreate/BgvCaseCreation'));
const CreateCaseLayout = lazy(() => import('./clientAdmin/createInit/caseCreation/CreateCase'));

export default class App extends Component {
     constructor(props) {
          super(props);
          updateUserInfo();
          importGoogleMapsJS();
     }
     render = () => (
          <>
               <Suspense fallback={<div><LoadingSpinner /></div>}>
                    <Switch>
                         <Route path="/home" component={LoginLayout} />
                         <PrivateRoute exact path="/chooseRole" component={ChooseRole} />
                         <PrivateRoute exact path="/onboarding" component={ClientAdminInitLayout} />
                         <PrivateRoute path="/dashboard" component={ClientAdminLayout} />
                         <PrivateRoute exact path="/socialIntelligence" component={SocialIntelligenceLayout} />
                         <Route exact path="/residenceVerification" component={VerificationLayout} />
                         <PrivateRoute path="/adminDashboard" component={AdminBaseLayout} />
                         <PrivateRoute path="/candidate" component={CandidateLayout} />
                         <Route path="/cdHome" component={CandidateLoginLayout} />
                         <Route path="/dataEntry" component={DataentryLayout} />
                         <Route path="/qcDashboard" component={QcLayout} />
                         <Route path="/caseCreation" component={CreateCaseLayout} />
                         <Route path="/bgvCaseCreation" component={BgvLayout} />
                         {/* billingcommented */}
                         {/* <Route path="/billing" component={BillingBaseLayout} /> */}
                         <Route path="/testRest" component={TestRest} />
                         <Route path="/mailto" component={MailToBaseLayout} />
                         <Route path="/vendorLogin" component={VendorLoginLayout} />
                         <PrivateRoute path="/operations" component={OperationsBaseLayout} />
                         <PrivateRoute path="/vendorDashboard" component={OperationsBaseLayout} />
                         <PrivateRoute path="/reportViewerDashboard" component={ReportViewerBaseLayout} />
                         <PrivateRoute path="/documentViewerDashboard" component={DocumentViewerBaseLayout} />
                         <Route path="/empVerification/" component={EmploymentFormContainer} />
                         <Route path="/500" component={InternalError} />
                         <Route exact path="/" component={LoginLayout} />
                         <Route path="/reportPreview" component={ReportPreview} />
                         <Route path="*" component={PageNotFound} />

                    </Switch>
               </Suspense>

               <div id="bt-snackbar-wrapper" className="bt-snackbar-wrapper" >
                    <div id="bt-snackbar" className="bt-snackbar" />
               </div>
               <div id="bottom_alert" className="bottom_alert ">
                    <p className="msg" />
                    <svg
                         className="close_bottom_alert" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                         viewBox="0 0 24 24"
                    >
                         <path d="M9.7,104l5.128-5.128,1.058-1.058a.4.4,0,0,0,0-.566l-1.132-1.132a.4.4,0,0,0-.566,0L8,102.3,1.815,96.117a.4.4,0,0,0-.566,0L.117,97.249a.4.4,0,0,0,0,.566L6.3,104,.117,110.186a.4.4,0,0,0,0,.566l1.132,1.132a.4.4,0,0,0,.566,0L8,105.7l5.128,5.128,1.058,1.058a.4.4,0,0,0,.566,0l1.132-1.132a.4.4,0,0,0,0-.566Z" transform="translate(4 -92)" />
                    </svg>
               </div>
          </>

     )
}
