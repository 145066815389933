import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {Radar} from 'react-chartjs-2';

class DiscChart extends Component {

     constructor(props)  {
          super(props);
          let defaultDiscJson = {
               calculativeness: {score: 0},
               influence: {score: 0},
               dominance: {score: 0},
               steadiness: {score: 0}
          };
          let discJson = (this.props.userData.results.personality_analysis? (this.props.userData.results.personality_analysis.disc_assessment? this.props.userData.results.personality_analysis.disc_assessment : defaultDiscJson) : defaultDiscJson);
          const data = {
               labels: ['Calculativeness', 'Influence', 'Dominance', 'Steadiness'],
               datasets: [
                    {
                         label: 'DISC Assessment',
                         backgroundColor: '#a4ffcb',
                         borderColor: '#64e89c',
                         pointBackgroundColor: '#64e89c',
                         pointBorderColor: '#fff',
                         pointHoverBackgroundColor: '#f5f5f5',
                         pointHoverBorderColor: 'rgba(179,181,198,1)',
                         data: [discJson.calculativeness.score, discJson.influence.score, discJson.dominance.score, discJson.steadiness.score]
                    }
               ]
          }
          this.state = {
               data: data
          };
     }

     render = () => {
          const { t } = this.props;
          return(
               <div className="col-12 disc-chart">
                    <div className="card-content">
                         <p className="big-text">{t('MoodChartDiscChartHeader')}</p>
                         <Radar  data={this.state.data} width={500} height={500} options={{
                                   responsive: false,
                                   maintainAspectRatio: false,
                                   legend:{
                                        display:false
                                   }
                              }}/>
                    </div>
               </div>
          );
     }

}

export default withTranslation('translations')(DiscChart);