import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import DeepTrack from './DeepTrack';
import TopPanel from './TopPanel';
import VerificationStatusContainer from './VerificationStatusContainer';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import UserInfoLayout from './UserInfoLayout';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';

class CaseReport extends Component {
     constructor(props) {
          super(props);
          this.userid = UserInfo.getUserid();

          this.state = {
               loading: false,
               code: '',
               dataObj: null,
               check: '',
               userId: this.userid,
               deepTrack: false,
               deepTrackObj: '',
               typeMap: '',
               evidenceList: [],
               activePanel: -1,
               caseVerifiDetailId: '',
               isReportShared: '',
               vendorDetails: {},
               activeTab: 1,
               isFIR: false,
          }
          this.code = ''
          this.caseVerifiDetailId = ''
          this.index = '';
          this.role = getValue('roleCode');
     }
     componentDidMount = () => {
          this.fetchData();
     }
     fetchData = () => {
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/caseOverviewData`)
          this.setState({ loading: false }, () => {
               this.dataSource.GetData(
                    data => {
                         this.setState({ dataObj: data, deepTrack: false, loading: false });
                         this.setState({ isReportShared: data['data']['isReportShared'] });
                    },
                    { "userId": this.state.userId, "caseId": this.props.caseId, roleId: getValue('roleId') },
                    "POST"
               );
          });
     }

     hideDeepTrack = () => {
          this.setState({ 'deepTrack': false })
     }

     showDeepTrack = (code, caseVerifiDetailId, deepTrackState) => {
          this.setState({ 'code': code, 'caseVerifiDetailId': caseVerifiDetailId })
          if (!deepTrackState) {
               this.fetchData()
          } else {
               this.loadDeepTrack(code, caseVerifiDetailId);
          }
     }

     cbRender = () => {
          this.showDeepTrack(this.code, this.caseVerifiDetailId, true)
     }

     reRenderSidePanel = () => {
          this.fetchData()
     }

     loadDeepTrack = (code, caseVerifiDetailId) => {
          this.code = code
          this.caseVerifiDetailId = caseVerifiDetailId
          this.dataSource = new RestDataSource(`${restbaseurl}/applicantCase/deepTrackAVerification`)
          this.setState({ loading: false }, () => {
               this.dataSource.GetData(
                    data => {
                         this.setState({ 'deepTrack': true, 'code': code, 'caseVerifiDetailId': caseVerifiDetailId, loading: false, deepTrackObj: data.detailObjMap, typeMap: data.typeMap, evidenceList: data.evidenceList, userSocialData: data.userSocialData, vendorDetails: data.vendor });
                    },
                    { "userId": this.state.userId, "caseId": this.props.caseId, code: this.state.code, caseVerifiDetailId: this.state.caseVerifiDetailId },
                    "POST"
               );
          });
     }
     setActivePanel = (panelNum) => {
          if (this.state.activePanel === panelNum) {
               this.setState({
                    activePanel: -1
               })
          }
          else {
               this.setState({
                    activePanel: panelNum
               })
          }

     }

     handleReportShare = () => {
          this.setState({
               isReportShared: !this.state.isReportShared
          })
     }

     handleFirstInterimReportShared = () => {
          this.setState({
               isFirstInterimReportShared: !this.state.isFirstInterimReportShared
          })
     }

     onTabChange = (e) => {
          let currentState = this.state;
          currentState.activeTab = +e.target.getAttribute("target-value");
          this.setState(currentState);
     }

     refetchData = () => {
          this.fetchData();
     }

     handleFIRCheckbox = (e) => {
          const isChecked = e.target.checked;
          this.setState({ isFIR: isChecked });
     }

     render = () => {
          const { t } = this.props;
          const { caseObj, fetchFIR } = this.props;
          const { isFIR } = this.state;
          let { isInterimReport, interimReportDate } = this.state.dataObj ? this.state.dataObj['data'] : {};
          let { isFirstInterimReportShared, firstInterimReportSharedDate } = this.state.dataObj ? this.state.dataObj['data'] : {};
          let caseRefNumber = this.state.dataObj ? this.state.dataObj['data']['caseRefNumber'] : ''
          let firstname = this.state.dataObj ? this.state.dataObj['data']['fullName'] : ''
          let dateCreated = this.state.dataObj ? this.state.dataObj['data']['dateCreated'] : ''
          let userInfo = this.state.dataObj ? this.state.dataObj['data']['userInfo'] : ''
          let verificationDetailMap = this.state.dataObj ? this.state.dataObj['data']['verificationTypeList'] : ''
          let tat = this.state.dataObj ? this.state.dataObj['data']['tat'] : ''
          let evidenceZipLink = this.state.dataObj ? this.state.dataObj['data']['evidenceZipLink'] : ''
          let documentZipLink = this.state.dataObj ? this.state.dataObj['data']['documentZipLink'] : ''
          let isReportShared = this.state.dataObj ? this.state.dataObj['data']['isReportShared'] : ''
          let reportDate = this.state.dataObj ? this.state.dataObj['data']['reportDate'] : ''
          let comments = this.state.dataObj ? this.state.dataObj['data']['comments'] : ''
          let packId = this.state.dataObj ? this.state.dataObj['data']['packId'] : ''
          let packName = this.state.dataObj ? this.state.dataObj['data']['packName'] : '';
          let showCaseType = this.state.dataObj ? this.state.dataObj['data']['showCaseType'] : '';
          let bgvDocumentLink = this.state.dataObj ? this.state.dataObj['data']['bgvDocumentLink'] : '';
          let disablePostCreation = this.state.dataObj ? this.state.dataObj['data']['disablePostCreation'] : '';
          let caseType = this.state.dataObj ? this.state.dataObj['data']['caseType'] : '';
          let isTopPriority = this.state.dataObj ? this.state.dataObj['data']['isTopPriority'] : false;
          let clientCode = this.state.dataObj ? this.state.dataObj['data']['clientCode'] : '';
          let showCaseInfo = true
          switch (this.state.activeTab) {
               default:
               case 1:
                    showCaseInfo = true
                    break;
               case 2:
                    showCaseInfo = false
                    break;
          }
          let showReport = this.props.showReport;
          let { emailForMailerTemplate } = this.props
          return (
               <div className="tb__tab-content">
                    <div className="create-new-pack-overlay" >
                    </div>
                    {this.state.deepTrack ? <DeepTrack hideDeepTrack={this.hideDeepTrack} keys={this.state.code} code={this.state.code} caseId={this.props.caseId}
                         dataObj={this.state.deepTrackObj} typeMap={this.state.typeMap} evidenceList={this.state.evidenceList} vendorDetails={this.state.vendorDetails} cbRender={this.cbRender}
                    /> : ''}
                    {this.state.loading === true ? <LoadingSpinner /> :
                         <div id="case-tbl-user" className="right-side__pop tb__side-pop" >
                              {this.state.dataObj ?
                                   <TopPanel userInfo={userInfo} caseId={this.props.caseId} caseRefNumber={caseRefNumber} firstname={firstname} dateCreated={dateCreated} {...this.props} isReportShared={isReportShared} reRender={this.handleReportShare} comments={comments} packId={packId} packName={packName}
                                        caseObj={caseObj} isMailerTemplateAvailable={this.props.isMailerTemplateAvailable} emailForMailerTemplate={emailForMailerTemplate} isInterimReport={isInterimReport} interimReportDate={interimReportDate} cbInterimReportChange={this.refetchData}
                                        isFirstInterimReportShared={isFirstInterimReportShared} firstInterimReportSharedDate={firstInterimReportSharedDate} cbFirstInterimReportShared={this.handleFirstInterimReportShared} caseType={caseType} />
                                   : null}
                              {/* <Use */}
                              {this.state.isReportShared ?
                                   <div className="report-text">Report has been shared on {reportDate}</div> :
                                   <div className="report-text">{this.props.reportName === 'Final Report' ? 'Report Ready' : ('Report will be ready in ' + (tat > 0 ? tat + ' days' : 1 + ' day'))}</div>}
                              <div className="clr15"></div>
                              <div className="row userinfo-caseinfo-toggle ">
                                   <label className="toggle-label toggle-label-new m-auto  ">
                                        <span className={this.state.activeTab === 1 ? "label active " : "label"} target-value="1" onClick={this.onTabChange}>{t('Check Info')}</span>
                                        <span className={this.state.activeTab === 2 ? "label active " : "label"} target-value="2" onClick={this.onTabChange}>{t('User Info')}</span>
                                   </label>
                              </div>
                              {!showCaseInfo ?
                                   <UserInfoLayout userInfo={userInfo} caseId={this.props.caseId} {...this.props} comments={comments} isTopPriority={isTopPriority} /> :
                                   <VerificationStatusContainer documentZipLink={documentZipLink}
                                        evidenceZipLink={evidenceZipLink}
                                        verificationDetailMap={verificationDetailMap}
                                        tat={tat}
                                        showDeepTrack={this.showDeepTrack}
                                        setActivePanel={this.setActivePanel}
                                        activePanel={this.state.activePanel}
                                        {...this.props}
                                        isReportShared={this.state.isReportShared}
                                        completeState={this.state}
                                        hideSidePanel={this.props.onStopCaseHide}
                                        showCaseType={showCaseType}
                                        bgvDocumentLink={bgvDocumentLink}
                                        caseRefNumber={caseRefNumber}
                                        disablePostCreation={disablePostCreation}
                                        reRenderSidePanel={this.reRenderSidePanel} />}
                              <div className="sidepop_footer">
                                   {clientCode.includes("HCL") ?
                                        <div className="row floating-checkbox-height">
                                             <div className="first-ir-download-checkbox">
                                                  <div className="form-group mb0">
                                                       <label className="container_Checkbox roundedcheckbox mb0">
                                                            <span>Download 1st IR</span>
                                                            <input type="checkbox" id="isFIR" onChange={this.handleFIRCheckbox} checked={isFIR} />
                                                            <span className="checkmark"></span>
                                                       </label>
                                                  </div>
                                             </div>
                                        </div>
                                        : null}
                                   <div className="row">
                                        <div className={`col-12 col-sm-6 ${this.role === "de" ? 'text-center' : ''}`}>
                                             <div className="form-group">
                                                  <button className="btn btn_secondary block " onClick={this.props.onHide}>{t('Close')}</button>
                                             </div>
                                        </div>
                                        {
                                             this.role === "de"
                                                  ? null
                                                  :
                                                  <div className="col-12 col-sm-6">
                                                       {this.props.reportName === "Pending" ?
                                                            <span className="btn btn_primary tb__pop-close disabled">{t('Download Report')}</span>
                                                            : showReport ?
                                                                 <span
                                                                      className={`btn btn_primary tb__pop-close`}
                                                                      onClick={() => {
                                                                           if(isFIR){
                                                                                fetchFIR(caseObj.id);
                                                                           }else{
                                                                                this.props.fetchReport(this.props.reportUrl)
                                                                           }
                                                                           
                                                                      }}
                                                                 >
                                                                      {t(this.props.reportName)}
                                                                 </span>
                                                                 : <span className={`btn btn_primary tb__pop-close disable_report_download`} onClick={(e) => e.stopPropagation()}>{t(this.props.reportName)}</span>
                                                       }
                                                  </div>
                                        }
                                   </div>
                                   <div className="clr"></div>
                              </div>
                         </div>
                    }
               </div>
          );
     }
}
export default withTranslation('translations')(CaseReport);
