import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import ProfileImage from './../../images/user.png'
import SentimentChart from './SentimentChart';
import MoodChart from './MoodChart';
import DiscChart from './DiscChart';
import PersonaChart from './PersonaChart';

class UserData extends Component {

     constructor(props) {
          super(props);
          console.log(this.props.userData);
          // let defaultPersona = {
          //      "learning_ability": {
          //           "score": 0,
          //           "level": "Low"
          //      },
          //      "need_for_autonomy": {
          //           "score": 0,
          //           "level": "Low"
          //      },
          //      "stability_potential": {
          //           "score": 0,
          //           "level": "Low"
          //      },
          //      "teamwork_skills": {
          //           "score": 0,
          //           "level": "Low"
          //      },
          //      "role_fit": {
          //           "technical": {
          //                "score": 0,
          //                "level": "Low"
          //           },
          //           "managerial": {
          //                "score": 0,
          //                "level": "Low"
          //           }
          //      },
          //      "attitude_and_outlook": {
          //           "score": 0,
          //           "level": "Low"
          //      },
          //      "general_behavior": {
          //           "score": 0,
          //           "level": "Low"
          //      },
          //      "action_orientedness": {
          //           "score": 0,
          //           "level": "Low"
          //      }
          // };
          if (this.props.userData.metadata.status === "FOUND" || this.props.userData.metadata.status === "PARTIALLY_FOUND") {
               this.state = {
                    found: true,
                    metadata: this.props.userData.metadata,
                    userData: this.props.userData,
                    userProfilePic: this.props.userData.results.user_profile_image,
                    firstName: this.props.userData.results.first_name,
                    lastName: this.props.userData.results.last_name,
                    displayName: this.props.userData.results.display_name,
                    userName: this.props.userData.results.user_name,
                    userDescription: this.props.userData.results.user_description,
                    country: (this.props.userData.results.demographics ? (this.props.userData.results.demographics.location ? this.props.userData.results.demographics.location.country : 'No Country') : 'No Country'),
                    devices: (this.props.userData.results.tech_usage ? (this.props.userData.results.tech_usage.devices.length ? this.props.userData.results.tech_usage.devices[0].device : 'No Devices') : 'No Devices'),
                    languages: (this.props.userData.results.languages.length ? this.props.userData.results.languages[0].language : 'No Languages'),
                    linLink: (this.props.userData.results.social_profiles ? (this.props.userData.results.social_profiles.linkedin ? this.props.userData.results.social_profiles.linkedin.url : '#') : '#'),
                    pinLink: (this.props.userData.results.social_profiles ? (this.props.userData.results.social_profiles.pinterest ? this.props.userData.results.social_profiles.pinterest.url : '#') : '#'),
                    twLink: (this.props.userData.results.social_profiles ? (this.props.userData.results.social_profiles.twitter ? this.props.userData.results.social_profiles.twitter.url : '#') : '#'),
                    fbLink: (this.props.userData.results.social_profiles ? (this.props.userData.results.social_profiles.facebook ? this.props.userData.results.social_profiles.facebook.url : '#') : '#'),
                    extraversion: (this.props.userData.results.personality_analysis ? (this.props.userData.results.personality_analysis.psychometrics ? this.props.userData.results.personality_analysis.psychometrics.extraversion : 'No Data') : 'No Data'),
                    emotionalSability: (this.props.userData.results.personality_analysis ? (this.props.userData.results.personality_analysis.psychometrics ? this.props.userData.results.personality_analysis.psychometrics.emotional_stability : 'No Data') : 'No Data'),
                    agreeableness: (this.props.userData.results.personality_analysis ? (this.props.userData.results.personality_analysis.psychometrics ? this.props.userData.results.personality_analysis.psychometrics.agreeableness : 'No Data') : 'No Data'),
                    conscientiousness: (this.props.userData.results.personality_analysis ? (this.props.userData.results.personality_analysis.psychometrics ? this.props.userData.results.personality_analysis.psychometrics.conscientiousness : 'No Data') : 'No Data'),
                    persona: (this.props.userData.results.persona ? (this.props.userData.results.persona.employee ? this.props.userData.results.persona.employee : this.defaultPersona) : this.defaultPersona),
               }
          }
          else {
               this.state = {
                    found: false,
                    metadata: this.props.userData.metadata
               }
          }
     }




     render = () => {
          // let metadata = {
          //      "status": "FOUND",
          //      "status_code": 1,
          //      "confidence": { "score": 100, "level": "High Confidence" },
          //      "analysis_status": "COMPLETE"
          // }
          const { t } = this.props;
          return (
               <section className="admin-main-wrapper">
                    <div className="social-intelligence-wrapper">
                         {this.props.showSearchPage !== false ? <div className="btn-section">
                              <div className="btn btn_secondary" onClick={this.props.showSearchPage}>{t('SocialIntelligenceUserPageBackButton')}</div>
                         </div> : ''
                         }
                         <div className="personality-detail">
                              <h6 className="text-center">{t('Analysis Summary')}</h6>
                              <div className="row mb-4">
                                   <div className="col-4 col-sm-6 col-md-4 ">
                                        <div className="card-content">
                                             <p className="big-text">{this.state.metadata.confidence.score}</p>
                                             <p className="small-text">{t('Confidence Score')}</p>
                                        </div>
                                   </div>
                                   <div className="col-4 col-sm-6 col-md-4 ">
                                        <div className="card-content">
                                             <p className="big-text">{this.state.metadata.confidence.score}</p>
                                             <p className="small-text">{t('Confidence level')}</p>
                                        </div>
                                   </div>
                                   <div className="col-4 col-sm-6 col-md-4 ">
                                        <div className="card-content">
                                             <p className="big-text">{this.state.metadata.analysis_status}</p>
                                             <p className="small-text">{t('Analysis Status')}</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="search-data">
                              {this.state.found ? <React.Fragment>

                                   <div className="text-center search-top">
                                        <div className="profile-image">
                                             <img src={this.state.userProfilePic} alt={'ProfileImage'} />
                                        </div>
                                        <h4>{this.state.displayName}</h4>
                                        <p className="small-text">@{this.state.userName}</p>
                                        <span className="small-line"></span>
                                        <p>{this.state.userDescription}</p>
                                        <div className="row top-detail">
                                             <div className="col-sm-4 col-xs-12">
                                                  <div className="bt__img-text-group">
                                                       <div className="img-wrapper">
                                                            <span className="bt-icon bt-location"></span>
                                                       </div>
                                                       <div className="text-wrapper">
                                                            <p>{t('SocialIntelligenceUserPageLocation')}</p>
                                                            <p className="label-value">{this.state.country}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="col-sm-4 col-xs-12">
                                                  <div className="bt__img-text-group">
                                                       <div className="img-wrapper">
                                                            <span className="bt-icon bt-device"></span>
                                                       </div>
                                                       <div className="text-wrapper">
                                                            <p>{t('SocialIntelligenceUserPageDevicesOwned')}</p>
                                                            <p className="label-value">{this.state.devices}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="col-sm-4 col-xs-12">
                                                  <div className="bt__img-text-group">
                                                       <div className="img-wrapper">
                                                            <span className="bt-icon bt-language"></span>
                                                       </div>
                                                       <div className="text-wrapper">
                                                            <p>{t('SocialIntelligenceUserPageLanguages')}</p>
                                                            <p className="label-value">{this.state.languages}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="social-action">
                                        <ul>
                                             <li><a href={this.state.linLink}><span className="bt-icon bt-linkedin"></span></a></li>
                                             <li><a href={this.state.pinLink}><span className="bt-icon bt-pinterest"></span></a></li>
                                             <li><a href={this.state.twLink}><span className="bt-icon bt-twitter"></span></a></li>
                                             <li><a href={this.state.fbLink}><span className="bt-icon bt-facebook"></span></a></li>
                                        </ul>
                                   </div>
                                   <div className="personality-detail">
                                        <h5 className="text-center">{t('SocialIntelligenceUserPagePersonality')}</h5>
                                        <div className="row mb-4">
                                             <div className="col-3 col-sm-6 col-md-3 ">
                                                  <div className="card-content">
                                                       <p className="big-text">{this.state.extraversion}</p>
                                                       <p className="small-text">{t('SocialIntelligenceUserPageExtraversion')}</p>
                                                  </div>
                                             </div>
                                             <div className="col-3 col-sm-6 col-md-3 ">
                                                  <div className="card-content">
                                                       <p className="big-text">{this.state.agreeableness}</p>
                                                       <p className="small-text">{t('SocialIntelligenceUserPageAgreeableness')}</p>
                                                  </div>
                                             </div>
                                             <div className="col-3 col-sm-6 col-md-3 ">
                                                  <div className="card-content">
                                                       <p className="big-text">{this.state.emotionalSability}</p>
                                                       <p className="small-text">{t('SocialIntelligenceUserPageEmotionalStability')}</p>
                                                  </div>
                                             </div>
                                             <div className="col-3 col-sm-6 col-md-3 ">
                                                  <div className="card-content">
                                                       <p className="big-text">{this.state.conscientiousness}</p>
                                                       <p className="small-text">{t('SocialIntelligenceUserPageConscientiousness')}</p>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="row mb-4">
                                             <SentimentChart userData={this.props.userData} />
                                             <MoodChart userData={this.props.userData} />
                                        </div>
                                        <div className="row mb-4">
                                             <DiscChart userData={this.props.userData} />
                                        </div>
                                   </div>
                                   <div className="persona-detail">
                                        <h5 className="text-center">{t('SocialIntelligenceUserPagePersona')}</h5>
                                        <div classNameName="container">
                                             <div className="bt_row">
                                                  <PersonaChart userData={this.props.userData} />
                                                  {Object.keys(this.state.persona).map((key, index) => {
                                                       let personaDetail = this.state.persona[key];
                                                       console.log("personaDetail");
                                                       console.log(personaDetail);
                                                       console.log(key);
                                                       if (personaDetail !== undefined && personaDetail.score !== undefined && index < 4) {
                                                            return <PersonaChart personaKey={key} personaDetail={personaDetail} />
                                                       } else {
                                                            return null
                                                       }
                                                  })
                                                  }
                                             </div>
                                             <div className="bt_row">
                                                  <PersonaChart userData={this.props.userData} />
                                                  {Object.keys(this.state.persona).map((key, index) => {
                                                       let personaDetail = this.state.persona[key];
                                                       console.log("personaDetail");
                                                       console.log(personaDetail);
                                                       console.log(key);
                                                       if (personaDetail !== undefined && personaDetail.score !== undefined && index >= 4) {
                                                            return <PersonaChart personaKey={key} personaDetail={personaDetail} />
                                                       } else {
                                                            return null
                                                       }
                                                  })
                                                  }
                                             </div>
                                        </div>
                                   </div>
                              </React.Fragment>
                                   : <h5>No Data Available</h5>}
                         </div>
                    </div>
               </section>
          );
     }
}

export default withTranslation('translations')(UserData);