import React, { Component } from 'react';
import VendorList from './VendorList';
import VendorProfileLayout from './VendorProfileLayout';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from '../../../globals/constants';
import BTSnackbar from "../../../globals/uicomps/BTSnackbar"
import { RestDataSource } from '../../../utils/RestDataSource';
import { LoadingSpinner } from '../../../utils/LoadingSpinner';
class VendorManagementLayout extends Component {
     constructor(props) {
          super(props);

          this.state = {
               vendorList: [],
               searchValue: '',
               selectedVendorId: '',
               showSidePanel: false,
               sidePanel: '',
               snackbar: false,
               snackMsg: '',
               cbIsVendorEdited: false
          };
     }
     
     componentDidMount = () => {
          this.fetchVendorList();
     }

     fetchVendorList = () => {
          this.fetchVendorLists = new RestDataSource(`${restbaseurl}/vendor/list`);
          this.setState({ loading: true }, () => {
               this.fetchVendorLists.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              this.setState({
                                   vendorList: data.data
                              })
                         }
                    },
                    "POST"
               );
          });
     }

     showManagementLayout = () => {
          this.setState({
               showVendorList: true,
               showSidePanel: false
          })
     }


     createNewVendor = () => {
          this.setState({ showSidePanel: true, sidePanel: "create" });
     }

     closeSidePanel = (cbType) => {
          let snackbar = false, snackMsg = ''
          if (cbType === "Create") {
               snackbar = true
               snackMsg = "Vendor Created Successfully"
          }
          this.fetchVendorList()
          this.setState({ showSidePanel: false, snackbar: snackbar, snackMsg: snackMsg })
     }

     editVendor = (id) => {
          this.setState({ sidePanel: "edit", showSidePanel: true, selectedVendorId: id });
     }

     showVendor = (id, cbIsVendorEdited) => {
          this.setState({ sidePanel: "show", showSidePanel: true, selectedVendorId: id, cbIsVendorEdited: cbIsVendorEdited });
     }

     onValueChange = () => {
          this.setState({
               searchValue: document.getElementById('search').value
          });
     }

     hideSnackbar = () => {
          this.setState({
               snackMsg: '',
               snackbar: false
          })
     }

     render = () => {
          const { t } = this.props;
          let showVendorList = !this.state.showSidePanel
          let vendorCount = 0, isloaded = 0;
          let vendorsList = this.state.vendorList.map((key, index) => {
               let detail = this.state.vendorList[index];
               let name = detail.name
               isloaded = 1;
               let userName = detail.username
               let searchValue = this.state.searchValue
               var invalid = /[°"§%()[\]*{}=\\?´`'#<>|,;:+]+/g;
               searchValue = searchValue.replace(invalid, "");
               if (showVendorList) {
                    if (name.toLowerCase().search(searchValue.toLowerCase()) !== -1 || userName.toLowerCase().search(searchValue.toLowerCase()) !== -1) {
                         vendorCount = vendorCount + 1
                         return <VendorList key={detail.id + "VendorList"} vendorDetails={detail} showVendor={this.showVendor} onCreate={this.reRender} />
                    } else {
                         return null
                    }
               } else {
                    return null
               }
          }
          );
          if (vendorCount === 0 && isloaded === 1) {
               vendorsList = <h6 className="text-center ml10">No Data Found</h6>
          }
          return (
               this.state.loading === true ? <LoadingSpinner /> :
                    <aside >
                         <div className="clr20"></div>
                         {this.state.snackbar ?
                              <BTSnackbar msg={this.state.snackMsg} status="success" time="4000" onHide={this.hideSnackbar} /> : ""}
                         {showVendorList ?
                              <React.Fragment>
                                   <div className="top-bar">
                                        <div className="form-group search-filter left search_box_width">
                                             <input type="text" className="form-control" id="search" placeholder={t('Name / User name')} value={this.state.searchValue} onChange={this.onValueChange} />
                                             <span className="bt-icon bt-search"></span>
                                        </div>
                                        <div className="btn-field right">
                                             <span className="btn btn_primary" tag="create-new-user" onClick={this.createNewVendor}>{t('CREATE')}</span>
                                        </div>
                                   </div>
                                   <div className="users-wrapper">
                                        <div className="card-group">
                                             {vendorsList}
                                        </div>
                                   </div></React.Fragment>
                              : <VendorProfileLayout
                                   sidePanel={this.state.sidePanel}
                                   reRender={this.reRenderList}
                                   editVendor={this.editVendor}
                                   showVendor={this.showVendor}
                                   closeSidePanel={this.closeSidePanel}
                                   showManagementLayout={this.showManagementLayout}
                                   cbIsVendorEdited={this.state.cbIsVendorEdited}
                                   selectedVendorId={this.state.selectedVendorId} />
                         }
                    </aside>
          );
     }
}

export default withTranslation('translations')(VendorManagementLayout);