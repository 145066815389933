import React, { Component } from 'react';
import { ErrorMessage } from 'formik';
import { BTFieldValError } from './BTFieldValError';

export class BTSelectField extends Component {
     render = () => {
          let isFieldEmpty = !!!this.props.value;
          const { selectClassName } = this.props;
          return (

               <div className={`form-group ${selectClassName ? selectClassName : ''}`}>
                    <label className="body2">{this.props.label}
                         {
                              !this.props.required ? <div className="optional">&nbsp;( optional )</div> : null
                         }
                    </label>
                    {/* {
                         this.props.required?<div className="error right">*</div>:''
                    } */}
                    <select id={this.props.id} className={`form-control ${isFieldEmpty ? 'input-empty' : ''}`}
                         name={this.props.id}
                         onBlur={this.props.onBlur}
                         onChange={this.props.onChange}
                         value={this.props.value}
                         readOnly={this.props.readonly}
                         disabled={this.props.isDisabled}
                    >
                         {this.props.options}
                    </select>
                    <ErrorMessage name={this.props.id} component={BTFieldValError} />
               </div>


          );
     }
}