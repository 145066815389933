/* eslint-disable react/jsx-props-no-spreading */
import { encryptionKey, restbaseurl } from '../constants'
import Cookies from 'js-cookie';
import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import ls from 'local-storage';
import UserInfo from '../../models/UserInfo';
import { RestDataSource } from '../../utils/RestDataSource';

function parseJwt(token) {
     const base64Url = token.split('.')[1];
     const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
     const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
     console.log('Payload', jsonPayload);
     return JSON.parse(jsonPayload);
}

function updateC1() {
     const expmin = new Date(new Date().getTime() + 20 * 60 * 1000);
     const val1 = Cookies.get('_c1');
     if (val1 !== undefined && val1 !== null) {
          console.log('updateC1  val1', val1);
          Cookies.remove('_c1');
          Cookies.set('_c1', val1, { path: '/', expires: expmin });
          const p = parseJwt(val1);
          console.log('update - C1 Payload', p);
     }
}

function updateUserInfo(c1 = null) {
     let val1 = c1;
     const ck = Cookies.get('_c1');
     if (ck != null) {
          val1 = ck;
          console.log('Thru cookie');
     } else if (c1 !== undefined && c1 !== null) {
          ls.set('_c1', val1);
          console.log('Thru Params - post login');
     }
     if (val1 === undefined || val1 === null) {
          val1 = ls.get('_c1');
          console.log('Thru Local Store');
     }
     console.log('Final Val1', val1);

     if (val1 !== undefined && val1 !== null) {
          const data = parseJwt(val1);
          UserInfo.setUserDetail(data);
     } else {
          processLogout();
          console.log('updateUserInfo - Cookie / LS / Params not found');
     }
}

function getValue(data) {
     const jwt = require('jsonwebtoken')
     const token = ls.get('token')
     console.log(token);
     if (token !== null && token !== undefined) {
          const tokenmap = jwt.verify(token, encryptionKey)
          return tokenmap[data]
     }
     return null    
}

function setValue(data, value) {
     const jwt = require('jsonwebtoken')
     let token = ls.get('token')
     if (token !== null && token !== undefined) {
          token = jwt.verify(token, encryptionKey)
          token[data] = value
          token = jwt.sign({
               ...token
          }, encryptionKey);
          ls.set('token', token)
     }
}

function PrivateRoute({ component: Component, cprops, ...rest }) {
     const valid = (getValue('isloggedin') !== undefined
          && getValue('isloggedin') !== null
          && getValue('isloggedin') === true);
     const path = rest.path;
     // 0: {id: 1, name: "Admin", code: "admin", priority: 1}
     // 6: {id: 7, name: "Operations Head", code: "operationshead", priority: 2}
     // 3: {id: 4, name: "Happiness Partner", code: "chp", priority: 3}
     // 1: {id: 2, name: "Client Admin", code: "ca", priority: 4}
     // 2: {id: 3, name: "HR", code: "hr", priority: 5}
     // 5: {id: 6, name: "Operations", code: "operations", priority: 6}
     // 7: {id: 8, name: "Data Entry", code: "de", priority: 7}
     // 4: {id: 5, name: "Accounts", code: "accounts", priority: 8}
     // 8: {id: 9, name: "Report Viewer", code: "reportviewer", priority: 9}
     // 9: {id: 9, name: "Document Viewer", code: "documentviewer", priority: 9}

     let isAccessable = false;
     const role = getValue('roleCode');
     // let accessableUrls = {
     //      admin: ['/chooseRole', '/onboarding', '/dashboard', '/adminDashboard', '/operations'],
     //      operationshead: ['/onboarding', '/dashboard', '/adminDashboard'],
     //      chp: ['/onboarding', '/dashboard', '/adminDashboard'],
     //      ca: ['/onboarding', '/dashboard'],
     //      hr: ['/onboarding', '/dashboard'],
     //      operations: ['/operations'],
     //      vendor: ['/vendorDashboard'],
     //      candidate: ['/candidate'],
     // }
     // if (role) {
     //      if( (role==='chp' || role==='operationshead' ) &&  getValue('additionalRole')){
     //           accessableUrls[role].push('/chooseRole')
     //           accessableUrls[role].push('/operations')
     //      }
     // }
     if (role) {
          console.log('loggedRole---', role)
          console.log('loggedpath---', path)
          switch (path) {

               case '/chooseRole':
                    // if (((getValue('rolePriority') === getValue('roleMap')['admin'] || (getValue('rolePriority') === getValue('roleMap')['de'])) ||
                    //      ((role === 'chp' || role === 'operationshead') && getValue('additionalRole') && getValue('additionalRole').length))) {
                    if ((getValue('rolePriorityList').length >= 2)) {
                         isAccessable = true;
                    }
                    break
               case '/operations':
                    // if ((role === 'operations') ||
                    // ((getValue('rolePriority') <= getValue('roleMap')['chp']) && getValue('additionalRole') && getValue('additionalRole').length)) {
                    isAccessable = true;
                    // }
                    break;
               case '/adminDashboard':
                    if ((getValue('rolePriority') <= getValue('roleMap')['chp'])) {
                         isAccessable = true;
                    }
                    break;
               case '/dashboard':
               case '/onboarding':
                    if ((getValue('rolePriority') <= getValue('roleMap')['hr'])) {
                         isAccessable = true;
                    }
                    break;
               case '/vendorDashboard':
                    if (role === 'vendor') {
                         isAccessable = true;
                    }
                    break;
               case '/candidate':
                    if (role === 'candidate') {
                         isAccessable = true;
                    }
                    break;
               case '/reportViewerDashboard':
                    if (role === 'reportviewer') {
                         isAccessable = true;
                    }
                    break;
               case '/documentViewerDashboard':
                    if (role === 'documentviewer') {
                         isAccessable = true;
                    }
                    break;
               default:
                    document.location.href = '/';
          }
     } else {
          if ((getValue('rolePriorityList') && getValue('rolePriorityList').length >= 2)) {
               isAccessable = true;
          }
     }

     return (
          <Route
               {...rest}
               render={(props) => ((valid === true && isAccessable)
                    ? <Component {...cprops} {...props} />
                    :
                    (
                         <Redirect
                              to={{
                                   pathname: '/home',
                                   state: { from: props.location },
                              }}
                         />
                    )
               )}
          />
     );
}


function processLogout() {
     ls.clear();
}

function importGoogleMapsJS() {
     console.log("Google maps")
     let keyRequest = new RestDataSource(`${restbaseurl}/login/obtainGoogleMapsApiKey`)
     
     keyRequest.GetData(
          response => {
               if (response.status === true && response.key.length > 0) {
                    const script = document.createElement("script");
                    script.src = `https://maps.googleapis.com/maps/api/js?key=${response.key}&libraries=places`;
                    script.async = true;
                    document.body.appendChild(script);
                    console.log("loaded Google Maps JS");
               }
               else{
                    console.error("Error loading Google Maps JS", response);
               }
          },
          {},
          "POST"
     );
     
}

export {
     parseJwt, updateC1, updateUserInfo, processLogout, getValue, setValue, PrivateRoute, importGoogleMapsJS
};
