import React, { Component } from 'react'
import UserInfo from '../../models/UserInfo';
import { getValue } from '../../globals/utils';


class AccountSuspend extends Component {
     render() {
          return (
               <React.Fragment>
                    <div className="sub-heading1 text-center">Sorry {UserInfo.getUsername()} !!<br/> Account Temporarily blocked</div>
                    <div className="clr20"></div>
                    <div className="form-group">
                         <label className="body2">Your account has been blocked, please contact {getValue('clientEmail')} to reactivate your account</label>
                         <div className="clr10"></div>
                         <div className="clr"></div>
                         <div className="clr10"></div>
                         <div className="clr"></div>
                         <input type="button" className="btn btn_primary block" onClick={()=>{this.props.changeStepValue("login")}} value="Login" />
                         <div className="clr10"></div>
                         <div className="clr"></div>
                    </div>
               </React.Fragment>
          )
     }
}

export default AccountSuspend
