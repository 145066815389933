import React, { Component } from 'react'
// import CaseCompletedTableRow from './CaseCompletedTableRow'
import { withTranslation } from "react-i18next";
import ResidenceAssignmentTableRow from './ResidenceAssignmentTableRow'
import Pagination from "react-js-pagination";
// import { restbaseurl } from "../../../globals/constants";
// import Axios from 'axios';

class VendorResidenceAssignment extends Component {

     constructor(props) {
          super(props);
          this.state = {
               searchBoxValue: "",
               totalCount: this.props.totalCount,
               activePage: 1,
               offset: 0,
               max: 30,
               isSearchEnabled: false,
               unAssignedList: this.props.unAssignedList.slice(0, 30),
               completeUnAssignedList: this.props.unAssignedList
          }
          this.max = 30;
          this.offset = 0;
     }
     onValueChange = () => {
          let isSearchEnabled = false
          let searchedElements = []
          let unAssignedList = this.state.completeUnAssignedList
          let searchValue = document.getElementById('search').value;
          this.state.completeUnAssignedList.forEach((unAssignedObj, index) => {
               let name = unAssignedList[index].name
               let refNumber = unAssignedList[index].caseNumber
               let caseStatus = unAssignedList[index].status
               var invalid = /[°"§%()[\]*{}=\\?´`'#<>|,;.:+]+/g;
               searchValue = searchValue.replace(invalid, "");
               if (name.toLowerCase().search(searchValue.toLowerCase()) !== -1 || refNumber.toLowerCase().search(searchValue.toLowerCase()) !== -1 || caseStatus.toLowerCase().search(searchValue.toLowerCase()) !== -1) {
                    searchedElements.push(unAssignedObj)
               }
               if (searchValue !== '') {
                    isSearchEnabled = true
                    this.offset = 0
               }
               this.setState({
                    searchBoxValue: searchValue,
                    activePage: 1,
                    unAssignedList: searchedElements.slice(0, 30),
                    searchedList: searchedElements,
                    isSearchEnabled: isSearchEnabled,
                    totalCount: searchedElements.length
               })
          })
     }

     handlePageChange = (pageNumber) => {
          this.offset = (pageNumber - 1) * this.max;
          this.setState({ activePage: pageNumber });
          this.updateCaseList();
     }

     updateCaseList = () => {
          let nextPageSize = this.offset + this.max
          if (this.state.isSearchEnabled) {
               this.setState({
                    unAssignedList: this.state.searchedList.slice(this.offset, nextPageSize)
               })
          } else {
               this.setState({
                    unAssignedList: this.state.completeUnAssignedList.slice(this.offset, nextPageSize)
               })
          }
     }


     render() {
          const { t } = this.props;
          let unAssignedList = this.state.unAssignedList;
          let { checkCode } = this.props;
          let caseCount = 0, isloaded = 0;
          let rows = (unAssignedList ? unAssignedList : []).map((unAssignedObj, index) => {
               isloaded = 1;
               let name = unAssignedList[index].name
               let refNumber = unAssignedList[index].caseNumber
               let caseStatus = unAssignedList[index].status
               let searchValue = this.state.searchBoxValue
               var invalid = /[°"§%()[\]*{}=\\?´`'#<>|,;.:+]+/g;
               searchValue = searchValue.replace(invalid, "");
               if (name.toLowerCase().search(searchValue.toLowerCase()) !== -1 || refNumber.toLowerCase().search(searchValue.toLowerCase()) !== -1 || caseStatus.toLowerCase().search(searchValue.toLowerCase()) !== -1) {
                    caseCount = caseCount + 1
                    return <ResidenceAssignmentTableRow
                         key={index}
                         cbRender={this.props.cbRender}
                         unAssignedObjList={unAssignedObj}
                         chooseVendor={this.props.chooseVendor}
                         // idx={this.props.offset + index}
                         idx={this.offset + index}
                         completeState={this.props.completeState}
                         checkCode={checkCode}
                    />
               } else {
                    return null
               }
          });
          if (caseCount === 0 && isloaded === 1) {
               rows = <h6 className="text-center">No Data Found</h6>
          }

          return (
               <div className="newcompletetable">
                    <div className="col-sm-3 left pad0">
                         {this.props.unAssignedList.length > 0 ? <div className="form-group search-filter">
                              <input type="text"
                                   className="form-control"
                                   id="search"
                                   placeholder="Name / Reference / Status "
                                   value={this.state.searchBoxValue}
                                   onChange={this.onValueChange} />
                              <span className="bt-icon bt-search"></span>
                         </div> : null}
                    </div>
                    <div className="clr"></div>
                    <div className={`commontbl case-tbl  `} >
                         {rows.length > 0 ?
                              <table className="table table-hover completed_table tableheaderfont">
                                   <thead>
                                        <tr>
                                             <th>{t('SNo')}</th>
                                             {/* <th >{t('Bgv Id')} </th> */}
                                             <th >{t('Reference Number')} </th>
                                             {!this.props.chooseVendor ? <th >{t('Assigned Date')}</th> : null}
                                             <th >{t('Name')}</th>
                                             <th >{t('Address')}</th>
                                             <th >{t('Status')}</th>
                                             {this.props.chooseVendor ? <th >{t('Choose Vendor')}</th> : null}
                                             {!this.props.chooseVendor ? <th >{t('Reassign/Unassign Vendor')}</th> : null}
                                        </tr>
                                   </thead>
                                   <tbody>
                                        {rows}
                                   </tbody>

                              </table>
                              : <h6 className="text-center">No Data Found !! </h6>}
                    </div>
                    {
                         this.state.totalCount === 0 ?
                              <div className="clr"></div>
                              :
                              <Pagination
                                   activePage={this.state.activePage}
                                   itemsCountPerPage={this.max}
                                   totalItemsCount={this.state.totalCount}
                                   pageRangeDisplayed={5}
                                   itemClass="page-item"
                                   linkClass="page-link"
                                   onChange={this.handlePageChange}
                              />
                    }
               </div>
          )
     }
}
export default withTranslation("translations")(VendorResidenceAssignment);

