import React, { Component } from 'react'
import Tablerow from './Tablerow'
import { withTranslation } from "react-i18next";
import Pagination from "react-js-pagination";

class CaseReporttable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            completeList: this.props.caseList,
            tempCaseList: this.props.caseList.slice(0, 50),
            activePage: this.props.activePage,
            totalCount: this.props.totalCount
        }
        this.max = 50;
        this.offset = (this.props.activePage -1) * this.max;
        // console.log(this.props.caseList,"applyconstruct")
    }

    componentDidMount = () => {
        this.setState({
            activePage: this.props.activePage,
            completeList: this.props.caseList,
            tempCaseList: this.props.caseList.slice(0, 50),
            totalCount: this.props.totalCount
        });

    }

    componentWillReceiveProps = (nextProps) => {
        // console.log(this.props.caseList,"applyupdate")
        this.offset = (this.props.activePage - 1) * this.max;
        this.setState({
            completeList: nextProps.caseList,
            tempCaseList: nextProps.caseList.slice(0, 50),
            totalCount: nextProps.totalCount
        });
    }

    handlePageChange = (pageNumber) => {
        this.offset = (pageNumber - 1) * this.max;
        this.props.setActivePage(pageNumber);
        let { paramsObj } = this.props;
        paramsObj.offset = this.offset;
        paramsObj.max = this.offset + this.max;
        this.props.fetchCompleteData(paramsObj);
        this.updateCaseList();
    }

    updateCaseList = () => {
        let nextPageSize = this.offset + this.max
        this.setState({
            tempCaseList: this.state.completeList.slice(this.offset, nextPageSize)
        })
        this.props.scrollTop()
    }

    render() {
        const { t } = this.props;
        let caseList = this.state.tempCaseList
        let rows = (caseList ? caseList : []).map((caseItem, index) => {
            return <Tablerow
                key={caseItem.id}
                caseItem={caseItem}
                idx={index + this.offset}
                dataEntryView= {this.props.dataEntryView}
                qcView= {this.props.qcView}
                qcOperationsView= {this.props.qcOperationsView}
                showChildTable= {this.props.showChildTable}
                activeRow= {this.props.activeRow}
                showSecondLevelQc= {this.props.showSecondLevelQc}
                priorityCase= {this.props.priorityQcData ? this.props.priorityQcData[caseItem.id] : ''}
            />
        })

        return (
            <div>
                <div className="commontbl case-tbl" id="dash_board_main_table">
                    {rows.length > 0 ?
                        <table className="table table-hover tableheaderfont">
                            <thead>
                                <tr>
                                    {/* <th colSpan="2">
                                        <label className="container_Checkbox margin0">{t('Select All')}
                                            <input type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </th> */}
                                    <th>{t('SNo')}</th>
                                    <th>{t('Case Number')}</th>
                                    {   
                                        this.props.dataEntryView 
                                            ?
                                            <th>{t('Client')}</th>
                                            : ''
                                    }
                                    <th>{t('Name')}</th>
                                    <th>{t('Initiated on')}</th>
                                    <th>{t('Due date')}</th>
                                    <th>{t('TAT')}</th>
                                    <th>{t('Checks')}</th>
                                    <th>{t('Status')}</th>
                                    {   
                                        this.props.dataEntryView 
                                            ? 
                                            ''
                                            :
                                            <th>{t('Report')}</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>

                        </table>
                        : <h6 className="text-center">No Case found with the selected filters !! </h6>}
                    {/* {
                                    this.props.load!=0?<div className="loadmore"><span className="ldmore pointer" onClick={this.props.clicked}>{t('More')}</span></div>:null
                                } */}
                    {this.state.totalCount > 50 ?
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.max}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange}
                        /> : null}
                </div>
            </div>
        )
    }
}
export default withTranslation("translations")(CaseReporttable);

