import React, { Component } from 'react';
import Select from "react-select";
import { restbaseurl } from '../../../globals/constants';
import { RestDataSource } from '../../../utils/RestDataSource';
import BTSnackbar from "../../../globals/uicomps/BTSnackbar";
import ConfirmationModal from '../../../globals/uicomps/ConfirmationModal';
import { BTInputField } from '../../../globals/uicomps/BTInputField';
class VendorServiceableLocations extends Component {

     constructor(props) {
          super(props);

          this.stageList = [
               { label: 'Stage One', value: '1' },
               { label: 'Stage Two', value: '2' },
               { label: 'Stage Three', value: '3' },
          ];

          this.state = {
               pincode: '',
               selectedVendor: {},
               loading: false,
               snackbar: false,
               snackmsg: '',
               isErrorExist: false,
               errorMsg: '',
               showConfirmationModal: false,
               subheading: '',
               vendorCost: '',
               selectedStage: this.stageList[0],
          }
          this.vendorList = [];
          
          this.buttonName = ''
     }

     componentDidMount = () => {
          this.fetchVendorList()
     }

     fetchVendorList = () => {
          this.fetchData = new RestDataSource(`${restbaseurl}/vendor/obtainResidenceVendorSpecList`);
          this.setState({ loading: true }, () => {
               this.fetchData.GetData(
                    data => {
                         if (data.status === true) {
                              this.vendorList = data.data.vendorSpecList.map((data) => { return { label: data.vendorName, value: data.vendorSpecId, key: data.vendorSpecId } });
                              this.setState({
                                   loading: false
                              })
                         }
                    },
                    {
                    },
                    "POST"
               );
          });
     }


     addVendor = () => {
          let { selectedVendor, pincode, vendorCost, selectedStage } = this.state
          console.log(selectedVendor.value, 'selectedVendor')
          this.addVendorData = new RestDataSource(`${restbaseurl}/vendor/addVendorServiceableLocation`);
          this.setState({ loading: true }, () => {
               this.addVendorData.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   loading: false,
                                   snackmsg: 'Sucess',
                                   snackbar: true,
                                   pincode: '',
                                   selectedVendor: {},
                                   showConfirmationModal: false,
                                   subheading: '',
                                   action: '',
                                   vendorCost: ''
                              })
                         }
                    },
                    {
                         vendorSpecId: selectedVendor.value,
                         pincodes: pincode,
                         cost: vendorCost,
                         stage: selectedStage.value,
                    },
                    "POST"
               );
          });
     }

     removeVendor = () => {
          let { selectedVendor, pincode } = this.state
          this.removeVendorData = new RestDataSource(`${restbaseurl}/vendor/removeVendorServiceableLocation`);
          this.setState({ loading: true }, () => {
               this.removeVendorData.GetData(
                    data => {
                         if (data.status === true) {
                              this.setState({
                                   loading: false,
                                   snackmsg: 'Sucess',
                                   snackbar: true,
                                   pincode: '',
                                   selectedVendor: {},
                                   showConfirmationModal: false,
                                   subheading: '',
                                   action: ''
                              })
                         }
                    },
                    {
                         vendorSpecId: selectedVendor.value,
                         pincodes: pincode
                    },
                    "POST"
               );
          });
     }

     showConfirmationModal = (action) => {
          let msg = ''
          if (action === 'add') {
               msg = `Do you want to add  `
               this.buttonName = 'Add'
          } else {
               msg = `Do you want to remove`
               this.buttonName = 'Remove'
          }
          this.setState({
               showConfirmationModal: true,
               subheading: msg,
               action
          })
     }


     handleChange = (event) => {
          let value = event.target.value;
          let isErrorExist = false;
          let errorMsg = ''
          if (value !== value.replace(/[^\d,]/g, '')) {
               isErrorExist = true;
               errorMsg = 'Provide only numbers and comma'
          }
          this.setState({
               pincode: value, isErrorExist, errorMsg
          })
     }

     onVendorChange = (event) => {
          this.setState({
               selectedVendor: event
          })
     }

     hideSnack = () => {
          this.setState({
               snackmsg: '',
               snackbar: false
          })
     }

     onConfirmationSuccess = () => {
          if (this.state.action === 'add') {
               this.addVendor()
          } else {
               this.removeVendor()
          }
     }

     hideModal = () => {
          this.setState({
               showConfirmationModal: false
          })
     }

     handleCostChange = (e) => {
          const name = e.target.name;
          const value = e.target.value;
          if (isNaN(value)) {
          } else {
               this.setState({
                    [name]: value
               });
          }
     }

     onStageChange = (e) => {
          this.setState({selectedStage: e});
     }

     render = () => {
          let { snackbar, snackmsg, isErrorExist, selectedVendor, pincode, errorMsg, showConfirmationModal, subheading, vendorCost, selectedStage } = this.state
          if (Object.keys(selectedVendor).length === 0 || pincode === '') {
               isErrorExist = true
          }
          return (
               <React.Fragment>
                    {snackbar ? <BTSnackbar
                         status="success"
                         msg={snackmsg}
                         onHide={this.hideSnack}
                    ></BTSnackbar> : null}

                    <div className='white-bg  pad20'>
                         <div className='clr5'></div>
                         <div className='sub-heading1'>Vendor Serviceable Locations</div>
                         <div className='clr20'></div>
                         <div className='row'>
                              <div className="col-sm-12">
                                   <div className="form-group">
                                        <label>{('Pincode')}</label>
                                        <div className='sub-heading4 mb10'> You can provide multiple pincodes by comma separated</div>
                                        <textarea rows="5" className="form-control noresize" id="pincode" name="pincode"
                                             onChange={this.handleChange} value={pincode} />
                                        {isErrorExist ? <label className="error right">{errorMsg}</label> : ''}
                                   </div>
                              </div>
                              <div className="col-sm-6">
                                   <div className="form-group">
                                        <label>{('Choose a Vendor')}</label>
                                        <Select
                                             id="vendorId"
                                             placeholder="Choose a vendor"
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.vendorList}
                                             onChange={this.onVendorChange}
                                             value={selectedVendor}
                                        ></Select>
                                   </div>
                              </div>
                              <div className="col-sm-6">
                                   <div className="form-group">
                                        <label>{('Choose a Stage')}</label>
                                        <Select
                                             name="stage"
                                             placeholder="Choose a stage"
                                             style={{ border: "2px solid red", zIndex: "1000" }}
                                             options={this.stageList}
                                             value={selectedStage}
                                             onChange={this.onStageChange}
                                        ></Select>
                                   </div>
                              </div>
                              <div className="col-sm-6">
                                   <div className="form-group">
                                        <BTInputField
                                             type="text"
                                             label={("Cost ( in rupees )")}
                                             id="vendorCost"
                                             required={true}
                                             name="vendorCost"
                                             handleChange={this.handleCostChange}
                                             value={vendorCost}
                                        />
                                   </div>
                              </div>
                              <div className='clr20'></div>
                         </div>
                         <div className="text-center mb20 mt20 ">
                              <span className={`btn btn_primary ml10 ${isErrorExist ? 'disable_check' : ''} `} onClick={() => this.showConfirmationModal('add')}> Add Vendor</span>
                              <span className={`btn btn_secondary ml10 ${isErrorExist ? 'disable_check' : ''} `} onClick={() => this.showConfirmationModal('remove')}>Remove Vendor</span>
                         </div>
                    </div>
                    <ConfirmationModal
                         show={showConfirmationModal}
                         heading="Are you sure ?"
                         subheading={subheading}
                         successButtonText={this.buttonName}
                         failureButtonText="Cancel"
                         successCallback={this.onConfirmationSuccess}
                         failureCallback={this.hideModal}
                         closeCallback={this.hideModal}
                    ></ConfirmationModal>
               </React.Fragment>
          )
     }
}

export default VendorServiceableLocations;
