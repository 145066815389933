import React, { Component } from "react";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { BTInputField } from "../../../globals/uicomps/BTInputField";
import { withTranslation } from "react-i18next";
import { restbaseurl } from "../../../globals/constants";
import { RestDataSource } from "../../../utils/RestDataSource";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { BTFieldValError } from '../../../globals/uicomps/BTFieldValError';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { LoadingSpinner } from "../../../utils/LoadingSpinner"
import UserInfo from "../../../models/UserInfo";

class EditVendor extends Component {
     constructor(props) {
          super(props);
          this.state = {
               showError: false,
               errorMsg: '',
               isUserExist: false,
               vendorDetails: {},
               userName: '',
               phoneNumber: '',
               userNameError: 'User name already exists',
               isAvailable: false,
               dummy: false,
               cibilDetails: {},
               defObjList: [],
               selectedVerificationName: [],
               verificationDetails: {
                    cibilDetails: null,
                    residenceDetails: null,
                    drugDetails: null
               },
               vendorSpecializationList: []
          };
          this.schema = yup.object().shape({
               name: yup.string().required("Name is required"),
               email: yup.string().required('Email address is required').email('Enter a valid email address'),
               contactNumber: yup.string().required("Mobile Number is required").min(10, 'Enter a valid mobile number'),
               address: yup.string().required("Address is required"),
          });
          this.userName = ''
          this.verificationList = []
          this.selectedVerificationLabel = []
          this.selectedVerificationId = []
          this.defObjList = []
          this.deletedIdList = []
          this.userid = UserInfo.getUserid()
     }


     componentDidMount = () => {
          this.setState({
               userName: ''
          })
          if (this.props.isEdit) {
               this.fetchDetails()
               this.fetchAccessibleTabs()
          } else {
               this.fetchAccessibleTabs()
          }

     }

     fetchDetails = () => {
          this.fetchDetails = new RestDataSource(`${restbaseurl}/vendor/show`);
          this.setState({ loading: true }, () => {
               this.fetchDetails.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              data.data.vendorSpecializationList.multiselectList.forEach((data) => {
                                   this.selectedVerificationId.push(data.value)
                                   this.selectedVerificationLabel.push(data.label)
                                   this.defObjList.push({ value: data.value, label: data.label })
                              }
                              );
                              this.setState({
                                   vendorDetails: data.data.vendorDetails,
                                   phoneNumber: data.data,
                                   defObjList: data.data.vendorSpecializationList.multiselectList,
                                   userName: data.data.vendorDetails.Username,
                                   vendorSpecializationList: data.data.vendorSpecializationList,
                                   verificationDetails: {
                                        cibilDetails: data.data.vendorSpecializationList.cibil ? data.data.vendorSpecializationList.cibil : null,
                                        residenceDetails: data.data.vendorSpecializationList.residence ? data.data.vendorSpecializationList.residence : null,
                                        drugDetails: data.data.vendorSpecializationList.drug ? data.data.vendorSpecializationList.drug : null,
                                   },
                                   selectedVerificationName: data.data.vendorSpecializationList.selectedVerificationList
                              })
                         }
                    },
                    { vendorId: this.props.selectedVendorId },
                    "POST"
               );
          });
     }

     onVerificationChange = (event) => {
          let verificationDetails = this.state.verificationDetails
          if (this.props.isEdit) {
               var selectedList = []
               event.forEach((item) => {
                    selectedList.push(item.label)
               })
               let selectedValue = selectedList.slice(-1)[0]
               if (selectedValue === "Drug check") {
                    verificationDetails["drugDetails"] = { active: true };
               }
               else if (selectedValue === "Residence") {
                    verificationDetails["residenceDetails"] = { active: true };
               }
               else if (selectedValue === "Cibil") {
                    verificationDetails["cibilDetails"] = { active: true };
               }
          } else {
               if (event !== null) {
                    if (event.length > this.selectedVerificationId.length) {
                         var added = ''
                         added = event.filter((item) => {
                              return this.selectedVerificationId.filter((item2) => {
                                   return item2 === item.value;
                              }).length === 0;
                         });
                         let addedLabel = added[0].label
                         if (addedLabel === "Drug check") {
                              verificationDetails["drugDetails"] = { active: true };
                         }
                         else if (addedLabel === "Residence") {
                              verificationDetails["residenceDetails"] = { active: true };
                         }
                         else if (addedLabel === "Cibil") {
                              verificationDetails["cibilDetails"] = { active: true };
                         }
                    } else {
                         let removed = ''
                         removed = this.selectedVerificationLabel.filter((item) => {
                              return event.filter((item2) => {
                                   return item2.label === item;
                              }).length === 0;
                         });
                         let removedLabel = removed.slice(-1)[0]
                         if (removedLabel === "Drug check") {
                              verificationDetails["drugDetails"] = null;
                         }
                         else if (removedLabel === "Residence") {
                              verificationDetails["residenceDetails"] = null;
                         }
                         else if (removedLabel === "Cibil") {
                              verificationDetails["cibilDetails"] = null;
                         }
                    }

               } else {
                    verificationDetails["drugDetails"] = null;
                    verificationDetails["residenceDetails"] = null;
                    verificationDetails["cibilDetails"] = null;
               }
          }


          this.selectedVerificationId = []
          if (event !== null) {
               event.forEach(data => {
                    this.selectedVerificationId.push(data.value)
                    this.selectedVerificationLabel.push(data.label)
               })
          }
          this.setState({
               showError: false,
               verificationDetails: verificationDetails
          })
     }

     init = () => {

          return {
               name: this.state.vendorDetails.Name ? this.state.vendorDetails.Name : '',
               userName: this.state.vendorDetails.Username ? this.state.vendorDetails.Username : '',
               password: '',
               cpassword: '',
               email: this.state.vendorDetails.Email ? this.state.vendorDetails.Email : '',
               address: this.state.vendorDetails.Address ? this.state.vendorDetails.Address : '',
               contactNumber: this.state.phoneNumber.phoneNumber ? this.state.phoneNumber.phoneNumber : '',
          }
     }
     closeSideNav = () => {
          this.props.closeSidePanel("Close");
     };

     handlePasswordChange = () => {
          this.setState({
               showError: false
          })
     }


     handleUserNameChange = (e) => {
          this.userName = e.target.value
          this.checkUserNameAvail = new RestDataSource(`${restbaseurl}/vendor/checkUsernameAvailability`);
          this.checkUserNameAvail.GetData(
               data => {
                    if (data.status === true) {
                         let isUserExists = true
                         if (data.isAvailable) {
                              isUserExists = false
                         }
                         this.setState({
                              isUserExist: isUserExists,
                              userName: this.userName,
                              userNameError: data.message
                         })
                    }
               },
               { vendorId: this.props.selectedVendorId, username: this.userName },
               "POST"
          );

     }

     createNewVendor = (values) => {
          if (values.password === '') {
               this.setState({
                    showError: true,
                    errorMsg: "Please provide a password"
               })
          } else if (values.password !== values.cpassword) {
               this.setState({
                    showError: true,
                    errorMsg: "Password and confirm password doesn't match"
               })
          } else if (this.selectedVerificationId.length === 0) {
               this.setState({
                    showError: true,
                    errorMsg: "Please choose atleast one verification type"
               })
          }
          else {
               this.fetchVendorLists = new RestDataSource(`${restbaseurl}/vendor/create`);
               this.setState({ loading: true }, () => {
                    this.fetchVendorLists.GetData(
                         data => {
                              this.setState({ loading: false });
                              if (data.status === true) {
                                   this.props.closeSidePanel("Create");
                              }
                         },
                         {
                              username: this.state.userName,
                              name: values.name,
                              email: values.email,
                              password: values.password,
                              address: values.address,
                              createdById: this.userid,
                              phone: values.contactNumber,
                              verificationIds: this.selectedVerificationId,
                              verificationDetails: this.state.verificationDetails

                         },
                         "POST"
                    );
               });
          }
     }

     saveEditVendor = (values) => {
          this.fetchVendorLists = new RestDataSource(`${restbaseurl}/vendor/edit`);
          this.setState({ loading: true }, () => {
               this.fetchVendorLists.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              this.props.showVendor(this.props.selectedVendorId, true)
                         }
                    },
                    {
                         username: this.state.userName,
                         name: values.name,
                         email: values.email,
                         address: values.address,
                         phone: values.contactNumber,
                         vendorId: this.props.selectedVendorId,
                         verificationDetails: this.state.verificationDetails
                    },
                    "POST"
               );
          });
     }

     fetchAccessibleTabs = () => {
          this.fetchAccessibleTabList = new RestDataSource(
               `${restbaseurl}/vendor/obtainVendorVerificationList`
          );
          this.setState({ loading: true }, () => {
               this.fetchAccessibleTabList.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              let verificationList = data.verificationList
                              this.verificationList = verificationList.map(data => { return { label: data.name, value: data.id, key: data.id } });
                         }
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },

                    "POST"
               );
          });
     }

     handleChange = (e) => {
          let verificationDetails = this.state.verificationDetails
          let list = e.target.getAttribute('id').split(".");
          if (list[1] === "active") {
               let value = e.target.value;
               if (value === "true" || value === true || value === "True") {
                    value = false
               }
               else if (value === "false" || value === false || value === "False") {
                    value = true
               }
               verificationDetails[list[0]][list[1]] = value;
               this.setState({
                    verificationDetails: verificationDetails
               })
          } else {
               let value = e.target.value;
               verificationDetails[list[0]][list[1]] = value;
               this.setState({
                    verificationDetails: verificationDetails
               })
          }
     }

     render() {
          const animatedComponents = makeAnimated();
          const { t } = this.props;
          let isEdit = this.props.isEdit
          let drugDetails = this.state.verificationDetails.drugDetails ? this.state.verificationDetails.drugDetails : null
          let cibilDetails = this.state.verificationDetails.cibilDetails ? this.state.verificationDetails.cibilDetails : null
          let residenceDetails = this.state.verificationDetails.residenceDetails ? this.state.verificationDetails.residenceDetails : null
          if (isEdit) {
               this.verificationList = this.verificationList.filter((item) => {
                    return this.selectedVerificationId.filter((item2) => {
                         return item2 === item.value;
                    }).length === 0;
               });
          }
          return (
               this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                    <div className="users-wrapper">
                         <div className="card-group">
                              <div className="clr"></div>
                         </div>
                         <Formik
                              enableReinitialize={true}
                              validationSchema={this.schema}
                              onSubmit={isEdit ? this.saveEditVendor : this.createNewVendor}
                              initialValues={this.init()}
                         >
                              {({
                                   handleSubmit,
                                   handleChange,
                                   handleBlur,
                                   values,
                                   dirty,
                                   touched,
                                   setFieldValue,
                                   isValid,
                                   errors
                              }) => {
                                   const onMobileChange = (key, value) => {
                                        let newValue = value.replace('-', '').replace(' ', '');
                                        setFieldValue(key, newValue);
                                   }
                                   return (<form onSubmit={handleSubmit} noValidate>
                                        <div className="white-bg">
                                             <div className="col-12 form-col">
                                                  <div className="row margin0">
                                                       <div className="col-4">
                                                            <div className="form-group">
                                                                 <BTInputField
                                                                      type="text"
                                                                      label={t("Name")}
                                                                      required={true}
                                                                      id="name"
                                                                      name="name"
                                                                      handleBlur={handleBlur}
                                                                      handleChange={handleChange}
                                                                      value={values.name}
                                                                 />
                                                            </div>
                                                       </div>
                                                       <div className="col-4">
                                                            <div className={`form-group  ${this.state.isUserExist ? "border_error error" : ""}`}>
                                                                 <label className="body2">User Name</label>
                                                                 <input type="text" id="userName" name="userName" className="form-control"
                                                                      onChange={(e) => this.handleUserNameChange(e)}
                                                                      value={this.state.userName}
                                                                 />
                                                                 {this.state.isUserExist ? <label className="error right">{this.state.userNameError}</label> : ''}
                                                            </div>
                                                       </div>
                                                       <div className="col-4">
                                                            <div className="form-group">
                                                                 <BTInputField
                                                                      type="text"
                                                                      label={"Email"}
                                                                      required={true}
                                                                      id="email"
                                                                      name="email"
                                                                      handleBlur={handleBlur}
                                                                      handleChange={handleChange}
                                                                      value={values.email}
                                                                 />
                                                            </div>
                                                       </div>
                                                       {!isEdit ?
                                                            <React.Fragment>
                                                                 <div className="col-4">
                                                                      <div className="form-group">
                                                                           <BTInputField
                                                                                type="password"
                                                                                label={"Password"}
                                                                                required={true}
                                                                                id="password"
                                                                                name="password"
                                                                                handleBlur={handleBlur}
                                                                                handleChange={handleChange}
                                                                                value={values.password}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                                 <div className="col-4">
                                                                      <div className="form-group">
                                                                           <BTInputField
                                                                                type="password"
                                                                                label={"Confirm Password"}
                                                                                required={true}
                                                                                id="cpassword"
                                                                                name="cpassword"
                                                                                handleBlur={handleBlur}
                                                                                handleChange={handleChange}
                                                                                value={values.cpassword}
                                                                           />
                                                                      </div>
                                                                 </div>
                                                            </React.Fragment> : ''}

                                                       <div className="col-12 col-sm-4">
                                                            <div className={`form-group ${!!values.contactNumber ? '' : 'phone-input-empty'}`}>
                                                                 <label className="body2">{t("Contact Number ")}</label>
                                                                 <PhoneInput defaultCountry={'in'} value={values.contactNumber} name="contactNumber" onChange={(value) => onMobileChange('contactNumber', value)} disableAreaCodes={true} />
                                                                 <ErrorMessage name="contactNumber" component={BTFieldValError} />
                                                            </div>
                                                       </div>
                                                       <div className="col-4">
                                                            <div className="form-group">
                                                                 <BTInputField
                                                                      type="text"
                                                                      label={t("Address")}
                                                                      required={true}
                                                                      id="address"
                                                                      name="address"
                                                                      handleBlur={handleBlur}
                                                                      handleChange={handleChange}
                                                                      value={values.address}
                                                                 />
                                                            </div>
                                                       </div>
                                                       {this.state.selectedVerificationName.length === 3 ? null :
                                                            <div className={`col-8  ${isEdit ? "react_select_remove_hidden" : "react_select_removeall_hide"}`}>
                                                                 <div className="form-group" style={{ zIndex: "1000" }}>
                                                                      <div className="sub-heading1 mb10">Choose Verification Type</div>
                                                                      <Select
                                                                           id="clientList"
                                                                           components={animatedComponents}
                                                                           placeholder="Choose Verification"
                                                                           style={{ border: "2px solid red", zIndex: "1000" }}
                                                                           options={this.verificationList}
                                                                           onChange={this.onVerificationChange}
                                                                           // defaultValue={this.defObjList}
                                                                           // defaultOptions={this.defObjList}
                                                                           isMulti
                                                                           className="basic-multi-select"
                                                                           classNamePrefix="select"
                                                                      ></Select>
                                                                 </div>
                                                            </div>
                                                       }
                                                       <div className="clr20"></div>
                                                       {cibilDetails !== null ?
                                                            <React.Fragment>
                                                                 <div className="full_width_container">
                                                                      <div className="left">
                                                                           <h6 className="sub_header_3 mb10 ">Cibil Verification</h6>
                                                                      </div>
                                                                      <div className="mr20 right">
                                                                           <div className="switch-group">
                                                                                <label className="switch">
                                                                                     <input type="checkbox" style={{ display: 'none' }} id={`cibilDetails.active`} checked={cibilDetails.active} value={cibilDetails.active} onChange={this.handleChange} />
                                                                                     <span>
                                                                                          <p></p>
                                                                                     </span>
                                                                                </label>
                                                                           </div>

                                                                      </div>
                                                                 </div>
                                                                 <div className="clr20"></div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Capacity")} type="number" min={0} id={`cibilDetails.capacity`} value={cibilDetails.capacity} handleChange={this.handleChange} required={true} disabled={!cibilDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Quality Index")} type="number" id={`cibilDetails.qualityIndex`} value={cibilDetails.qualityIndex} handleChange={this.handleChange} required={true} disabled={!cibilDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Average Process Time (in days)")} type="number" id={`cibilDetails.averageProcessTime`} value={cibilDetails.averageProcessTime} handleChange={this.handleChange} required={true} disabled={!cibilDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Priority")} type="number" id={`cibilDetails.priority`} value={cibilDetails.priority} handleChange={this.handleChange} required={true} disabled={!cibilDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Tat (in days)")} type="number" id={`cibilDetails.tat`} value={cibilDetails.tat} handleChange={this.handleChange} required={true} disabled={!cibilDetails.active} />
                                                                 </div>

                                                                 <div className="clr15"></div>
                                                            </React.Fragment> : null}
                                                       {drugDetails !== null ?
                                                            <React.Fragment>
                                                                 <div className="full_width_container">
                                                                      <div className="left">
                                                                           <h6 className="sub_header_3 mb10">Drug Verification</h6>
                                                                      </div>
                                                                      <div className="mr20 right">
                                                                           <div className="switch-group">
                                                                                <label className="switch">
                                                                                     <input type="checkbox" style={{ display: 'none' }} id={`drugDetails.active`} checked={drugDetails.active} value={drugDetails.active} onChange={this.handleChange} />
                                                                                     <span>
                                                                                          <p></p>
                                                                                     </span>
                                                                                </label>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="clr20"></div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Capacity")} type="number" id={`drugDetails.capacity`} value={drugDetails.capacity} handleChange={this.handleChange} required={true} disabled={!drugDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Quality Index")} type="number" id={`drugDetails.qualityIndex`} value={drugDetails.qualityIndex} handleChange={this.handleChange} required={true} disabled={!drugDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Average Process Time (in days)")} type="number" id={`drugDetails.averageProcessTime`} value={drugDetails.averageProcessTime} handleChange={this.handleChange} required={true} disabled={!drugDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Priority")} type="number" id={`drugDetails.priority`} value={drugDetails.priority} handleChange={this.handleChange} required={true} disabled={!drugDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Tat (in days)")} type="number" id={`drugDetails.tat`} value={drugDetails.tat} handleChange={this.handleChange} required={true} disabled={!drugDetails.active} />
                                                                 </div>
                                                                 <div className="clr15"></div>
                                                            </React.Fragment> : null}
                                                       {residenceDetails !== null ?
                                                            <React.Fragment>
                                                                 <div className="full_width_container">
                                                                      <div className="left">
                                                                           <h6 className="sub_header_3 mb10">Residence Verification</h6>
                                                                      </div>
                                                                      <div className="mr20 right">
                                                                           <div className="switch-group">
                                                                                <label className="switch">
                                                                                     <input type="checkbox" style={{ display: 'none' }} id={`residenceDetails.active`} checked={residenceDetails.active} value={residenceDetails.active} onChange={this.handleChange} />
                                                                                     <span>
                                                                                          <p></p>
                                                                                     </span>
                                                                                </label>
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="clr20"></div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Capacity")} type="number" id={`residenceDetails.capacity`} value={residenceDetails.capacity} handleChange={this.handleChange} required={true} disabled={!residenceDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Quality Index")} type="number" id={`residenceDetails.qualityIndex`} value={residenceDetails.qualityIndex} handleChange={this.handleChange} required={true} disabled={!residenceDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Average Process Time (in days)")} type="number" id={`residenceDetails.averageProcessTime`} value={residenceDetails.averageProcessTime} handleChange={this.handleChange} required={true} disabled={!residenceDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Priority")} type="number" id={`residenceDetails.priority`} value={residenceDetails.priority} handleChange={this.handleChange} required={true} disabled={!residenceDetails.active} />
                                                                 </div>
                                                                 <div className="col-sm-4 mb-5">
                                                                      <BTInputField label={t("Tat (in days)")} type="number" id={`residenceDetails.tat`} value={residenceDetails.tat} handleChange={this.handleChange} required={true} disabled={!residenceDetails.active} />
                                                                 </div>
                                                                 <div className="clr15"></div>
                                                            </React.Fragment> : null}
                                                  </div>
                                                  {
                                                       this.state.showError ?
                                                            <div className="showerrormsg">
                                                                 <span>{this.state.errorMsg}</span>  </div> : ""
                                                  }
                                                  <div className="text-center mb20 mt20">
                                                       <button className="btn btn_secondary ml10" onClick={this.closeSideNav}>{t('cancel')}</button>
                                                       {isEdit ? <button className="btn btn_primary ml10" disabled={this.state.isUserExist} onClick={this.handleSubmit}>{t('UPDATE')}</button>
                                                            : <button className="btn btn_primary ml10" disabled={this.state.isUserExist} onClick={this.handleSubmit}>{t('Create')}</button>}
                                                       <div className="clr"></div></div>
                                             </div>

                                        </div>

                                   </form>
                                   )
                              }}
                         </Formik>
                    </div>
               </React.Fragment >
          );
     }
}
export default withTranslation("translations")(EditVendor);
