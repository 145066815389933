import React, { Component } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { BTInputField } from "../../globals/uicomps/BTInputField";
import { withTranslation } from "react-i18next";
// import BTTextHead from "../../globals/uicomps/BTTextHead";
// import BTLabelText from "../../globals/uicomps/BTLabelText";
import { restbaseurl } from "../../globals/constants";
import { RestDataSource } from "../../utils/RestDataSource";
import BottomAlert from "../../globals/uicomps/BottomAlert";
import UserInfo from "../../models/UserInfo";
import Select from "react-select";
import { getValue } from "../../globals/utils";
class ChangePassword extends Component {
     constructor(props) {
          super(props);
          const { formType } = this.props;
          this.state = {
               currentpassword: "",
               newpassword: "",
               userid: UserInfo.getUserid(),
               renewpassword: "",
               showError: false,
               passwordChangeError: '',
               formType: formType ? formType : 'changePassword',
               userNameList: [],
               userObj: '',
          };

          this.role = getValue('roleCode');
          this.userId = UserInfo.getUserid();

          // this.schema = yup.object().shape({
          //      currentpassword: yup.string().required("Current password is required"),
          //      newpassword: yup
          //           .string()
          //           .required("Password is required")
          //           .min(8, "Password must contain min 8 characters")
          //           .matches(/[A-Z]/, "Password must contain one uppercase character")
          //           .matches(/[@$!%*#?&]/, "Password must contain one special Character")
          //           .matches(/[0-9]/, "Password must contain one number")
          //           .matches(/[a-z]/, "Password must Contain one alphabet"),
          //      renewpassword: yup
          //           .string()
          //           .required("Please confirm your new password")
          //           .oneOf([yup.ref("newpassword"), null], "Passwords must match")
          // });
     }

     componentDidMount = () => {
          const { formType } = this.props;
          if (formType === 'resetPassword')
               this.fetchUserListData();
     }

     fetchUserListData = () => {
          this.fetchUserList = new RestDataSource(
               `${restbaseurl}/user/obtainUsernameList`,
          );
          this.fetchUserList.GetData(
               (data) => {
                    if (data.status === true) {
                         this.setState({
                              userList: data.data.userList,
                         });
                    }
               },
               {userId: this.userId},
               'POST',
          );
     }
   
     submitLoginDetails = values => {
          const { formType } = this.state;
          if (formType === 'resetPassword') {
               let newpass = values.newpassword;
               let action = this.props.action
               this.dataSource = new RestDataSource(
                    `${restbaseurl}${action}`
               );
               this.setState({ loading: true }, () => {
                    this.dataSource.GetData(
                         data => {
                              if (data.status === true) {
                                   this.setState({ loading: false });
                                   this.props.cbChangePassword('success');
                              } else {
                                   this.setState({ loading: false });
                              }
                         },
                         {
                              userId: values.userId,
                              password: newpass
                         },
                         "POST"
                    );
               });
          } else {
               let current = values.currentpassword;
               let newpass = values.newpassword;
               let action = this.props.action
               if (newpass !== current) {
                    this.dataSource = new RestDataSource(
                         `${restbaseurl}${action}`
                    );
                    this.setState({ loading: true }, () => {
                         this.dataSource.GetData(
                              data => {
                                   if (data.passwordChanged === true) {
                                        this.setState({ loading: false });
                                        this.props.cbChangePassword('success');
                                   } else {
                                        this.initialValues = values;
                                        this.setState({ loading: false, showError: true, passwordChangeError: 'Current password is incorrect' });
                                   }
                              },
                              {
                                   userId: this.state.userid,
                                   currentPassword: current,
                                   newPassword: newpass
                              },
                              "POST"
                         );
                    });
               } else {
                    this.initialValues = values;
                    this.setState({ loading: false, showError: true, passwordChangeError: 'Your new password cannot be same as  your current password' });
               }
          }
     };

     constructSchema = () => {
          const formType = this.props.formType ? this.props.formType : 'changePassword';
          let shapeObject = {};
          shapeObject.renewpassword = yup
               .string()
               .required("Please confirm your new password")
               .oneOf([yup.ref("newpassword"), null], "Passwords must match");
          if (formType === 'resetPassword') {
               shapeObject.userObj = yup.string().required("User name is required");
               shapeObject.newpassword = yup.string().required("Password is required")
          } else {
               shapeObject.currentpassword = yup.string().required("Current password is required");
               shapeObject.newpassword = yup.string()
                    .required("Password is required")
                    .min(8, "Password must contain min 8 characters")
                    .matches(/[A-Z]/, "Password must contain one uppercase character")
                    .matches(/[@$!%*#?&]/, "Password must contain one special Character")
                    .matches(/[0-9]/, "Password must contain one number")
                    .matches(/[a-z]/, "Password must Contain one alphabet");
          }
          return shapeObject;
     }


     render() {
          const { t } = this.props;
          let passwordChangeError = this.state.passwordChangeError;
          const { formType, userList } = this.state;
          let constructedSchema = this.constructSchema();
          this.schema = yup.object().shape(constructedSchema);

          console.log(this.schema, 'this.schemathis.schema');
          return (
               <React.Fragment>
                    {this.state.loggedinMessage !== undefined &&
                         this.state.loggedinMessage !== "" ? (
                              <BottomAlert status="danger" msg={this.state.loggedinMessage} />
                         ) : null}

                    <section className="password-section">
                         <div className="container-fluid">
                              <div className="white-bg pt0">
                                   <div className="password-block">
                                        <div className="clr"></div>
                                        <Formik
                                             validationSchema={this.schema}
                                             onSubmit={this.submitLoginDetails}
                                             initialValues={this.state}
                                        >
                                             {({
                                                  handleSubmit,
                                                  handleChange,
                                                  handleBlur,
                                                  values,
                                                  touched,
                                                  isValid,
                                                  errors,
                                                  setFieldValue
                                             }) => {
                                                  const handleUserChange = (data) => {
                                                       setFieldValue('userObj', data)
                                                       setFieldValue('userId', data.value)
                                                  }
                                                  return (<form
                                                       onSubmit={handleSubmit}
                                                       noValidate
                                                       className="blockform mar0"
                                                  >
                                                       <div className=" blockform mar0">
                                                            {formType === 'resetPassword' ? <>
                                                                 <div className='body2 mb10'>User Name</div>
                                                                 <Select
                                                                      id="userObj"
                                                                      placeholder="Type to search a user"
                                                                      style={{ border: "2px solid red" }}
                                                                      options={userList}
                                                                      value={values.userObj}
                                                                      onChange={handleUserChange}
                                                                 /></> : <BTInputField
                                                                      type="password"
                                                                      label={t("CurrentPassword")}
                                                                      name="currentpassword"
                                                                      id="currentpassword"
                                                                      required={true}
                                                                      handleBlur={handleBlur}
                                                                      handleChange={handleChange}
                                                                      value={values.currentpassword}
                                                                 />}
                                                            <BTInputField
                                                                 type="password"
                                                                 label={t("NewPassword")}
                                                                 id="newpassword"
                                                                 name="newpassword"
                                                                 handleBlur={handleBlur}
                                                                 required={true}
                                                                 handleChange={handleChange}
                                                                 value={values.newpassword}
                                                            />
                                                            <BTInputField
                                                                 type="password"
                                                                 label={t("Confirm New Password")}
                                                                 id="renewpassword"
                                                                 name="renewpassword"
                                                                 handleBlur={handleBlur}
                                                                 required={true}
                                                                 handleChange={handleChange}
                                                                 value={values.renewpassword}
                                                            />
                                                       </div>
                                                       <div className='clr5'></div>
                                                       <div >
                                                            {
                                                                 this.state.showError ?
                                                                      <div className="showerrormsg">
                                                                           <span>{passwordChangeError}</span>  </div> : ""
                                                            }
                                                       </div>
                                                       <div className='clr5'></div>
                                                       <span className="text-center clr5">
                                                            <span
                                                                 className="btn btn_secondary"
                                                                 onClick={this.props.onHide}
                                                            >{t("Cancel")}</span>
                                                            <span
                                                                 className="btn btn_primary ml10"
                                                                 onClick={handleSubmit}
                                                            >{t("Save")}</span>
                                                       </span>
                                                  </form>
                                                  )
                                             }}
                                        </Formik>
                                   </div>
                              </div>
                         </div>
                         <div className="clr"></div>
                    </section>
               </React.Fragment>
          );
     }
}

export default withTranslation("translations")(ChangePassword);
