import React from 'react';
import { withTranslation } from 'react-i18next';
// import { restbaseurl } from '../../../globals/constants';
// import { RestDataSource } from '../../../utils/RestDataSource';
import CaseView from './CaseView';
import CaseSearch from './CaseSearch';

class CaseManagementLayout extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               activeCaseId: null,
               loading: false
          };
     }

     cbViewCase = (caseId) => {
          // console.log('%ccaseId'+caseId, 'color:green');
          this.setState({ activeCaseId: caseId });
     }

     cbBackToSearch = () => {
          this.setState({ activeCaseId: null });
     }

     render = () => {
          // const { t } = this.props;
          const { isAdminDashboard } = this.props;
          return (
               <React.Fragment>
                    <h6 className='border_btm'>Case Management</h6>
                    {
                         this.state.activeCaseId ?
                              <CaseView caseId={this.state.activeCaseId} cbBackToSearch={this.cbBackToSearch} />
                              : <CaseSearch cbViewCase={this.cbViewCase} isAdminDashboard={isAdminDashboard} />
                    }
               </React.Fragment>
          );
     }
}

export default withTranslation('translations')(CaseManagementLayout);