import React from 'react';
import { withTranslation } from 'react-i18next';
import { restbaseurl } from "../../../globals/constants";
import { RestDataSource } from "../../../utils/RestDataSource";
import Select from "react-select";
import { LoadingSpinner } from '../../../utils/LoadingSpinner';
import VendorResidenceAssignment from '../vendorAssignment/VendorResidenceAssignment';
import Axios from 'axios';
import UserInfo from '../../../models/UserInfo';
import { getValue } from '../../../globals/utils';
import VendorDrugAssignment from '../vendorAssignment/VendorDrugAssignment';
class VendorDetailsLayout extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               clientName: "",
               clientId: '',
               showError: false,
               dummy: false,
               selectedCheck: '',
               checkName: "",
               checkId: '',
               showDetails: false,
               details: '',
               selectedCheckLabel: '',
               showNoData: false,
               unAssignedList: [],
               totalCount: -1,
               vendorLabel: '',
               vendorId: null,
               checkCode: ''
          };
          this.clientId = ""
          this.checkId = ''
          this.vendorId = null
          this.clientList = []
          this.checkList = []
          this.defObjList = []
          this.defObjCheck = []
          this.defObjVendor = []
          this.vendorOptions = []
          this.init();
          this.userid = UserInfo.getUserid()
          this.clientid = getValue('clientId')
     }

     componentDidMount = () => {
          this.searchClientName()
          this.searchCheckList()
          // this.loadOptions()
     }

     init = () => {
          if (this.state.clientName !== '' && this.state.clientName !== null && this.state.clientName !== undefined) {
               this.defObjList = []
               this.defObjList.push({ value: this.state.clientId, label: this.state.clientName });
          }
          if (this.state.checkName !== '' && this.state.checkName !== null && this.state.checkName !== undefined) {
               this.defObjCheck = []
               this.defObjCheck.push({ value: this.state.checkId, label: this.state.checkName });
          }
          if (this.state.vendorLabel !== '' && this.state.vendorLabel !== null && this.state.vendorLabel !== undefined) {
               this.defObjVendor = []
               this.defObjVendor.push({ value: this.state.vendorId, label: this.state.vendorLabel });
          }
     }

     searchClientName = () => {
          this.clientDetails = new RestDataSource(
               `${restbaseurl}/user/clientList`
          );
          this.setState({ loading: true }, () => {
               this.clientDetails.GetData(
                    data => {
                         this.setState({ loading: false });
                         if (data.status === true) {
                              this.setState({
                                   details: data.data,
                              });
                              let clientList = data.data.clientList
                              this.clientList = clientList.map(data => { return { label: data.clientName, id: data.clientId } });
                         }
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    { userId: this.userid, roleId: getValue('roleId') },
                    "POST"
               );
          });
     }

     loadOptions = (id) => {
          this.vendorOptions = []
          this.defObjVendor = []
          this.loadList = new RestDataSource(
               `${restbaseurl}/applicantCase/vendorList`
          );
          this.setState({ loading: true }, () => {
               this.loadList.GetData(
                    data => {
                         this.setState({ loading: false, showDetails: false });
                         if (data.status === true) {
                              this.vendorOptions = data.data.map(vendorObj => {
                                   return { label: vendorObj.vendorName, value: vendorObj.vendorId };
                              });
                         }
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    { verificationCode: id },
                    "POST"
               );
          });
     }

     searchCheckList = () => {
          this.checkDetails = new RestDataSource(
               `${restbaseurl}/user/obtainVendorVerificationList`
          );
          this.setState({ loading: true }, () => {
               this.checkDetails.GetData(
                    respData => {
                         this.setState({ loading: false });
                         let verificationTypeList = respData.verificationTypeList
                         this.checkList = verificationTypeList.map(checkType => { return { label: checkType.name, value: checkType.id, id: checkType.code, verificationCode: checkType.verificationCode } });
                         this.setState({
                              dummy: !this.state.dummy
                         })
                    },
                    "POST"
               );
          });
     }


     onClientChange = (event) => {
          this.setState({
               clientName: event.label,
               clientId: event.id,
               showError: false
          })
          this.clientId = event.id
     }

     onCheckChange = (event) => {
          this.setState({
               checkName: event.label,
               selectedCheckLabel: event.label,
               checkId: event.value,
               showError: false,
               checkCode: event.verificationCode
          })
          this.loadOptions(event.verificationCode)
          this.checkId = event.value
     }

     fetchDetails = () => {

     }

     onChangeCandidate = (event) => {
          this.setState({
               vendorLabel: event.label,
               vendorId: event.value,
               showError: false
          })
          this.vendorId = event.value
     }

     fetchCaseDetails = () => {
          if (this.checkId === '' || this.vendorId === null || this.clientId === '') {
               this.setState({
                    showError: true
               })
          }
          else {
               this.checkDetails = new RestDataSource(
                    `${restbaseurl}/user/showUnassignedVerificationDetail`
               );
               this.setState({ loading: true }, () => {
                    this.checkDetails.GetData(
                         data => {
                              this.setState({
                                   loading: false,
                                   showDetails: true,
                                   unAssignedList: data.data.detail,
                                   showNoData: false,
                                   selectedCheck: this.state.selectedCheckLabel,
                                   totalCount: data.data.detail.length
                              });
                         },
                         { clientId: this.clientId, checkId: this.checkId, vendorId: this.vendorId, showUnAssignedVendor: false },
                         "POST"
                    );
               });
          }
     }

     downloadVendorXLS = () => {
          let url = `${restbaseurl}/user/downloadVendorXls`;
          this.setState({ loading: true });
          const config = {
               responseType: 'blob',
               withCredentials: true
          }
          Axios.post(url,
               {
                    clientId: this.clientId,
                    checkId: this.checkId,
                    vendorId: this.vendorId,
                    showUnAssignedVendor: false
               },
               config
          ).then((response) => {
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.setAttribute('download', 'VendorDetails.xlsx'); //or any other extension
               document.body.appendChild(link);
               link.click();
               this.setState({
                    loading: false
               })
          });
     }


     render = () => {
          this.init()
          let showDownloadXls = false;
          if (this.clientId !== '' && this.checkId !== '') {
               showDownloadXls = true
          }
          let { checkCode, showDetails } = this.state;
          let content = '';

          if (showDetails === true) {
               if (checkCode === 'residence' || checkCode === 'cibil') {
                    content = <VendorResidenceAssignment checkCode={checkCode} unAssignedList={this.state.unAssignedList} cbRender={this.fetchCaseDetails} totalCount={this.state.totalCount} chooseVendor={false} completeState={this.state} />
               }
               if (checkCode === 'drug') {
                    content = <VendorDrugAssignment checkCode={checkCode} unAssignedList={this.state.unAssignedList} cbRender={this.fetchCaseDetails} totalCount={this.state.totalCount} isVendorPresent={true} completeState={this.state} />
               }
          }

          return (
               this.state.loading ? <LoadingSpinner /> : <React.Fragment>
                    < div className="clr20" ></div >
                    <div className="row">
                         <div className="col-sm-12 col-md-3">
                              <div className="form-group" style={{ zIndex: "1000" }}>
                                   <div className="sub-heading1 mb10">Choose a Client</div>
                                   <Select
                                        id="clientList"
                                        placeholder="Search clients"
                                        style={{ border: "2px solid red", zIndex: "1000" }}
                                        options={this.clientList}
                                        defaultValue={this.defObjList}
                                        onChange={this.onClientChange}
                                   ></Select>
                              </div>
                         </div>
                         <div className="col-sm-12 col-md-3">
                              <div className="form-group" style={{ zIndex: "1000" }}>
                                   <div className="sub-heading1 mb10">Select a Check</div>
                                   <Select
                                        id="checkList"
                                        placeholder="Select check"
                                        style={{ border: "2px solid red", zIndex: "1000" }}
                                        options={this.checkList}
                                        defaultValue={this.defObjCheck}
                                        onChange={this.onCheckChange}
                                   ></Select>
                              </div>
                         </div>
                         <div className="col-sm-12 col-md-3">
                              <div className="form-group" style={{ zIndex: "1000" }}>
                                   <div className="sub-heading1 mb10">Choose a Vendor</div>
                                   <Select
                                        id="vendorId"
                                        style={{ border: "2px solid red" }}
                                        options={this.vendorOptions}
                                        defaultValue={this.defObjVendor}
                                        onChange={this.onChangeCandidate}
                                   ></Select>
                              </div>
                         </div>

                         <div className="col-sm-12 col-md-3" style={{ marginTop: "30px" }}>
                              <span
                                   className="btn btn_primary "
                                   onClick={this.fetchCaseDetails}
                              >Get Details </span>
                              {showDownloadXls ? <span className='title-hover downloadxls '>
                                   <img className='downloadxls ' height="30" src={"/images/icons/icon-download.svg"} alt="download" onClick={this.downloadVendorXLS} ></img>
                                   <div className="icon-details pad10">
                                        <div className="body2 mt5">Download as XLS</div>
                                   </div>
                              </span> : null}
                         </div>
                    </div>
                    <div className="clr20"></div>
                    {content}
                    {
                         this.state.showError ?
                              <div className="showerrormsg">
                                   <span>Please fill all the details</span>  </div> : ""
                    }
               </React.Fragment >
          );
     }
}

export default withTranslation('translations')(VendorDetailsLayout);