import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
export default class LoadingModal extends Component {
     constructor(props) {
          super(props);
          this.state = {
               loading: false,
               showCancel: false
          }

     }


     hidedialog = () => {
          this.props.hidedialog()
     }


     componentDidMount = () => {
          setTimeout(() => {
               this.setState({
                    showCancel: true
               })
          }, this.props.time ? this.props.time : 0);
     }

     render = () => {
          const { progress } = this.props;
          let progressBarWidth
          if (!!progress) {
               progressBarWidth = 100 * progress;
          }

          return (
               <React.Fragment>
                    <div className="newname">
                         <Modal
                              backdrop={this.props.backdropType}
                              onRequestClose={this.props.onHide}
                              shouldCloseOnOverlayClick={false}
                              centered className={"modal fade add-document-modal " + this.props.className} id="add-document" show={this.props.show} onHide={this.props.onHide}
                              style={{ width: "50%", left: "25%" }}
                         >
                              <Modal.Header className="modal-header" style={{ padding: "10px" }}>
                              </Modal.Header>
                              <Modal.Body className="modal-body" style={{ padding: "10px" }} >
                                   <div className="upload-content1">
                                        <h5 className="text-center">{this.props.content}</h5>
                                        <div className="clr20"></div>
                                        <div className="clr20"></div>
                                        {!!progress ?
                                             <div className="progress-bar-wrapper">
                                                  <div className="progress-bar" style={{ width: `${progressBarWidth}%` }}></div>
                                             </div>
                                             : <LoadingSpinner />}
                                        <div className="clr20"></div>
                                   </div>
                              </Modal.Body>
                              {
                                   (this.props.showFooter === true && this.state.showCancel === true) ?
                                        <Modal.Footer className="text-center" style={{ display: "block" }}>
                                             <span className="btn btn_secondary" onClick={this.props.onHide} >{this.props.failureButtonText}</span>
                                        </Modal.Footer>
                                        :
                                        <div></div>
                              }


                         </Modal >
                    </div>


               </React.Fragment >
          )

     }
}
