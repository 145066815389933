import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';

class MoodChart extends Component {
     constructor(props)  {
          super(props);
          let defaultMoodJson = {
               "action": 0,
               "anxiety": 0,
               "calm": 0,
               "depression": 0
          };
          let moodJson = (this.props.userData.results.mood? (this.props.userData.results.mood.primary? this.props.userData.results.mood.primary : defaultMoodJson) : defaultMoodJson);
          console.log(moodJson);
          const data = {
               labels: [
                    'Action',
                    'Anxiety',
                    'Calm',
                    'Depression'
               ],
               datasets: [{
                    data: [Math.round(moodJson.action), Math.round(moodJson.anxiety), Math.round(moodJson.calm),Math.round( moodJson.depression)],
                    backgroundColor: [
                         '#FF6384',
                         '#36A2EB',
                         '#FFCE56',
                         '#4DB0C0'
                    ],
                    hoverBackgroundColor: [
                         '#FF6384',
                         '#36A2EB',
                         '#FFCE56',
                         '#4DB0C0'
                    ]
               }]
          };
          this.state = {
               data: data
          }
     }

     render = () => {
          return(
               <div className="col-6 col-xs-12">
                    <div className="card-content">
                         <p className="big-text">Mood</p>
                         <Doughnut  data={this.state.data} width={300} height={300} options={{
                                   responsive: false,
                                   maintainAspectRatio: false,
                                   cutoutPercentage:80,
                                   legend: {
                                        display: false,
                                        position : 'right',
                                        fullWidth:false
                                    }
                              }}/>
                    </div>
               </div>
          );
     }

}

export default withTranslation('translations')(MoodChart);