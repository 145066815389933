import React, { Component } from 'react'
import { Formik } from 'formik';
import { restbaseurl } from '../globals/constants';
import { RestDataSource } from '../utils/RestDataSource';
import { withTranslation } from 'react-i18next';
import UserInfo from '../models/UserInfo';
import { getValue } from '../globals/utils';


class Authenticator extends Component {
     constructor(props) {
          super(props)

          this.state = {
               mfaCode: ""
          };
          this.userid = UserInfo.getUserid();
          this.username = UserInfo.getUsername();
     }

     changeHandler = (e) => {
          this.setState({ mfaCode: e.target.value })
     }

     checkMFAcode = (values) => {
          if (this.state.mfaCode.length > 0) {
               this.authenticateSource = new RestDataSource(`${restbaseurl}/login/checkMFACode`)
               this.setState({ loading: true });
               this.props.showAlert(null);
               this.authenticateSource.GetData(
                    detailsStatus => {
                         this.setState({ loading: false })

                         if (detailsStatus.message === 'success') {
                              this.props.loginSuccess();
                         }
                         else {
                              this.props.showAlert(detailsStatus.message);
                         }
                    },
                    { 'userId': this.userid, "username": this.username, "mfaCode": this.state.mfaCode },
                    "POST"
               );
          }


     }

     render() {
          const { t } = this.props
          return (
               <React.Fragment>

                    <div className="sub-heading1 text-center">Hi {getValue('loginname')}, Welcome back</div>
                    <div className="clr20"></div>
                    <label className="body2">{t("AuthenticateText")}</label>
                    <Formik  onSubmit={this.checkMFAcode} initialValues={this.initialValues}>
                         {
                              ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                   <form onSubmit={handleSubmit} noValidate >
                                        <label>MFA code</label>
                                        <input type="number" className="form-control" value={this.state.mfaCode} onChange={this.changeHandler}   autoFocus={true} />
                                        <input className="btn btn_primary block mt10" type="submit"
                                             value="Continue" ></input>
                                   </form>
                              )
                         }
                    </Formik>
                    <div className="forgetlink body2 left mt10 pointer" onClick={() => { this.props.changeStepValue("login") }}>{t('Back to Login')}</div>
                    <div className="forgetlink body2 right mt10 pointer" onClick={() => { this.props.changeStepValue("tryAnotherWays") }}>{t('Try Other Ways')}</div>

                    <div className="clr20"></div>

               </React.Fragment>
          )
     }
}
export default withTranslation("translations")(Authenticator);

