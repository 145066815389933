import React, { Component } from 'react';

class UserInfo extends Component {

     render = () => {
          let label = this.props.data
          let value = this.props.value[this.props.data]
          return (
               <React.Fragment>
                    <div className="col-12 col-sm-6 mb15">
                         <div className="smallfont">{label}</div>
                         <div className="clr5"></div>
                         {
                              label.includes("Associated Cases (Support)")
                                   ?
                                   <span className="smallfont body2 pointer" onClick={() => this.props.showSupportCaseModal(value)}>{value ? value : '-'}</span>
                                   :
                                   <div className="smallfont body2">{value ? value : '-'}</div>
                         }
                    </div>
               </React.Fragment>
          );
     }

}
export default UserInfo;
