import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';

class SentimentChart extends Component {

     constructor(props)  {
          super(props);
          let defaultSentimentJson = {
               "positive_sentiment": 0,
               "negative_sentiment": 0,
               "neutral_sentiment": 0
          };
          let sentimentJson = (this.props.userData.results.personality_analysis? (this.props.userData.results.personality_analysis.sentiment? this.props.userData.results.personality_analysis.sentiment : defaultSentimentJson) : defaultSentimentJson)
          console.log(sentimentJson);
          const data = {
               labels: [
                    'Postive',
                    'Negative',
                    'Neutral'
               ],
               datasets: [{
                    data: [Math.round(sentimentJson.positive_sentiment), Math.round(sentimentJson.negative_sentiment),Math.round( sentimentJson.neutral_sentiment)],
                    backgroundColor: [
                         '#FF6384',
                         '#36A2EB',
                         '#FFCE56'
                    ],
                    hoverBackgroundColor: [
                         '#FF6384',
                         '#36A2EB',
                         '#FFCE56'
                    ]
               }]
          };
          this.state = {
               sentimentJson: sentimentJson,
               data: data
          };
     }

     render = () => {
          return(
               <div className="col-6 col-xs-12">
                    <div className="card-content">
                         <p className="big-text">Sentiment</p>
                         <Doughnut  data={this.state.data} width={300} height={300} options={{
                                   responsive: false,
                                   maintainAspectRatio: false,
                                   cutoutPercentage:80,
                                   legend:{
                                        display:false
                                   }
                              }}/>
                    </div>
               </div>
          );
     }
}

export default withTranslation('translations')(SentimentChart);