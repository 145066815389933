import React, {Component} from 'react';

export class PageNotFound extends Component {

     render = () => {
          return (

               <h1> Page Not Found !!!</h1>

          );
     }
}